import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../common/header/Header'
import CommonImageTransport from '../../../common/images/CommonImages'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { GetCourseList } from '../../../../api/Global';
import moment from 'moment';
import Loader from '../../../../loader/Loader';
import ReactHtmlParser from "react-html-parser";
import { Rating } from 'react-simple-star-rating';

const CoursesDetails = () => {
    const contentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const { Course_id } = useParams();
    const navigate = useNavigate()
    const [NavLinkActive, setNavLinkActive] = useState(0);
    const [courseDetails, setCourseDetails] = useState({});
    console.log("courseDetails", courseDetails)
    const [CourseIs_Booking, setCourseIs_Booking] = useState({})
    const role_id = localStorage.getItem("role_id");

    const HandleActive = (index) => {
        setNavLinkActive(index)
    }


    // Refs for each section
    const overviewRef = useRef(null);
    const curriculumRef = useRef(null);
    const instructorRef = useRef(null);
    const reviewsRef = useRef(null);

    // Scroll into view when tab is clicked
    const scrollToSection = (ref, index) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setNavLinkActive(index); // Set active tab when clicked
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = [
                { ref: overviewRef, index: 0 },
                { ref: curriculumRef, index: 1 },
                { ref: instructorRef, index: 2 },
                { ref: reviewsRef, index: 3 },
            ];

            sections.forEach((section) => {
                const rect = section?.ref?.current?.getBoundingClientRect();
                if (rect?.top >= 0 && rect?.top < window.innerHeight / 2) {
                    setNavLinkActive(section.index);
                }
            });
        };

        // Attach scroll listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // Effect to switch tabs when scrolled to the bottom of the content
    useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 10) {
                    setNavLinkActive((prev) => (prev + 1) % 4);
                }
            }
        };

        const contentElement = contentRef.current;

        // Add scroll event listener
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup function to remove the event listener
        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [NavLinkActive]);



    // <-------- book Now ----------->
    const BookNow = () => {
        if (role_id != 2) {
            alert("User not sign in")
            toast.error("User not sign in ")
            setTimeout(() => {
                navigate("/Signin")
            }, 1000)
        }
    }

    // <------- Handle Checked section's ----------->
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const data = {
                id: Course_id
            }
            const response = await GetCourseList(data);
            setCourseDetails(response?.data?.data)
            setIsLoading(false)
            setCourseIs_Booking(response?.data?.data[0])
        };
        fetchData();
    }, []);
    console.log("courseDetails", courseDetails)
    return (
        <div>
            {
                isLoading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Header />
                        </div>
                        <div className="header_space"></div>
                        <div className="inner-banner inner-banner-bg py-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 my-auto">
                                        <div className="inner-title">
                                            <h3>{courseDetails[0]?.title}</h3>
                                            {/* <p className="text-white">{courseDetails[0]?.short_desc}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="courses-details-area pt-20 pb-70">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="scourse_meta mt-2">
                                            <div className="smeta">
                                                <img src={courseDetails[0]?.instructor_img} height="70" width="50"
                                                    className="rounded-pill" alt="author" />
                                                <div className="smeta_text">
                                                    <span>Instructor:</span>
                                                    <p><Link to="#">{courseDetails[0]?.instructor_name}</Link></p>
                                                </div>
                                            </div>

                                            <div className="smeta">
                                                <span>Category:</span>
                                                <p>{courseDetails[0]?.category}</p>
                                            </div>

                                            <div className="smeta">
                                                <span>Last Update:</span>
                                                <p>{moment(courseDetails[0]?.updated_at).format("MM/DD/YYYY")}</p>
                                            </div>

                                        </div>
                                        <div className="courses-details-contact">
                                            <div className="tab courses-details-tab">
                                                <div className='global-nav fixed-menu'>
                                                    <ul className="tabs">
                                                        <li className={`${NavLinkActive === 0 ? "active" : ""}`}
                                                            onClick={() => scrollToSection(overviewRef, 0)}>
                                                            Overview
                                                        </li>
                                                        <li className={`${NavLinkActive === 1 ? "active" : ""}`}
                                                            onClick={() => scrollToSection(curriculumRef, 1)}>
                                                            Curriculum
                                                        </li>
                                                        <li className={`${NavLinkActive === 2 ? "active" : ""}`}
                                                            onClick={() => scrollToSection(instructorRef, 2)}>
                                                            Instructor
                                                        </li>
                                                        <li className={`${NavLinkActive === 3 ? "active" : ""}`}
                                                            onClick={() => scrollToSection(reviewsRef, 3)}>
                                                            Reviews
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="tab_content current active">
                                                    <div id="overview" ref={overviewRef} >

                                                        <div className="tabs_item current">
                                                            <div className="courses-details-tab-content">
                                                                <div className="courses-details-into">
                                                                    <h3>Description</h3>
                                                                    <p>{ReactHtmlParser(courseDetails[0]?.course_description)}</p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div id="curriculum" ref={curriculumRef} >

                                                        <div className="tabs_item">
                                                            <div className="courses-details-tab-content">
                                                                <div className="courses-details-accordion">
                                                                    <ul className="accordion">
                                                                        <li className="accordion-item">
                                                                            <a className="accordion-title active" href="javascript:void(0)">
                                                                                <i className="ri-add-fill"></i>
                                                                                Introduction
                                                                            </a>
                                                                            <div className="accordion-content show">
                                                                                <div className="accordion-content-list">
                                                                                    <div className="accordion-content-left">
                                                                                        <i className="ri-file-text-line"></i>
                                                                                        Introduction to the course
                                                                                    </div>

                                                                                </div>
                                                                                <div className="accordion-content-list">
                                                                                    <div className="accordion-content-left">
                                                                                        <i className="ri-profile-line"></i>
                                                                                        Number of classes
                                                                                    </div>
                                                                                    <div className="accordion-content-right">

                                                                                        <div className="tag"> {courseDetails[0]?.total_classes} Class</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-content-list">
                                                                                    <div className="accordion-content-left">
                                                                                        <i className="ri-play-circle-line"></i>
                                                                                        Total Videos
                                                                                    </div>
                                                                                    <div className="accordion-content-right">

                                                                                        <div className="tag"> {courseDetails[0]?.total_vedios} Video</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-content-list">
                                                                                    <div className="accordion-content-left">
                                                                                        <i className="ri-file-text-line"></i>
                                                                                        Number of Notes
                                                                                    </div>
                                                                                    <div className="accordion-content-right">
                                                                                        <div className="tag"> {courseDetails[0]?.total_classes} Notes</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-content-list">
                                                                                    <div className="accordion-content-left">
                                                                                        <i className="ri-time-line"></i>
                                                                                        Duration
                                                                                    </div>
                                                                                    <div className="accordion-content-right">
                                                                                        <div className="tag"> {courseDetails[0]?.duration} Days</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div id="instructor" ref={instructorRef} >

                                                        <div className="tabs_item">
                                                            <div className="courses-details-tab-content">
                                                                <div className="courses-details-instructor">
                                                                    <h3>About the instructor</h3>
                                                                    <div className="details-instructor">
                                                                        <img src={courseDetails[0]?.instructor_img}
                                                                            alt="instructor" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                                                        <h3>{courseDetails[0]?.instructor_name}</h3>
                                                                        <span>{courseDetails[0]?.instructor_designation}</span>
                                                                    </div>
                                                                    <p>{courseDetails[0]?.instructor_description}</p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div id="reviews" ref={reviewsRef}>

                                                        <>
                                                            <div className="tabs_item">
                                                                <div className="courses-details-tab-content">
                                                                    <div className="courses-details-review-form">
                                                                        <div className="review-comments">
                                                                            {
                                                                                courseDetails[0]?.coursereview?.map((coursereviewResult) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="review-item">
                                                                                                <div className="content">
                                                                                                    {
                                                                                                        coursereviewResult?.student_profileImg != null ? (
                                                                                                            <>
                                                                                                                <img src={coursereviewResult?.student_profileImg}
                                                                                                                    alt="Images" />
                                                                                                            </>

                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <img src={CommonImageTransport?.userLogo}
                                                                                                                    alt="Images" />
                                                                                                            </>
                                                                                                        )
                                                                                                    }

                                                                                                    <div className="content-dtls">
                                                                                                        <h4>{coursereviewResult?.student_fname} {coursereviewResult?.student_lname}</h4>
                                                                                                        {/* <span>15 Jan, 2022 AT 06:30 PM</span> */}
                                                                                                        <span>{moment(coursereviewResult?.created_at).format("DD MMM, YYYY")}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="rating">
                                                                                                    <Rating readonly={true} initialValue={coursereviewResult?.rating} />
                                                                                                </div>
                                                                                                <h3>{coursereviewResult?.feedback_title}</h3>
                                                                                                <p>{coursereviewResult?.review}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>


                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <div className="courses-details-sidebar m-0">
                                            <img src={courseDetails[0]?.course_img} className="w-100" height="200" alt="Courses" />
                                            <div className="content">
                                                <div className="course-sidebar">
                                                    <h3>Course Features</h3>
                                                    <ul className="scourse_list">
                                                        <li>
                                                            <span className="cside-label">
                                                                <i className="ri-time-fill"></i> Duration
                                                            </span>

                                                            <span className="cside-value">{courseDetails[0]?.duration}</span>
                                                        </li>

                                                        <li>
                                                            <span className="cside-label">
                                                                <i className="ri-profile-line"></i> Class
                                                            </span>

                                                            <span className="cside-value">{courseDetails[0]?.total_classes}</span>
                                                        </li>

                                                        {/* <li>
                                                <span className="cside-label">
                                                    <i className="ri-shield-user-fill"></i> Student’s
                                                </span>

                                                <span className="cside-value">150</span>
                                            </li> */}

                                                        <li>
                                                            <span className="cside-label">
                                                                <i className="ri-video-fill"></i> Video
                                                            </span>

                                                            <span className="cside-value">{courseDetails[0]?.total_vedios}</span>
                                                        </li>
                                                        <li>
                                                            <span className="cside-label">
                                                                <i className="ri-bar-chart-grouped-fill"></i> Skill Level
                                                            </span>
                                                            <span className="cside-value">{courseDetails[0]?.skill_level}
                                                            </span>
                                                        </li>
                                                        <li className="m-0">
                                                            <span className="cside-label">
                                                                <i className="ri-global-line"></i> Language
                                                            </span>
                                                            <span className="cside-value">{courseDetails[0]?.course_language}</span>
                                                        </li>
                                                    </ul>
                                                    {/* <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name='isChecked' onClick={() => handleChecked()} id="flexCheckDefault" />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                I agree that I will not cheat, and I will complete it by myself
                                            </label>
                                        </div> */}



                                                    <><div className="text-center" onClick={BookNow}>
                                                        <Link to="#"
                                                            className="default-btn">Book Now</Link>
                                                    </div>
                                                    </>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default CoursesDetails