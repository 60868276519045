import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CommonImageTransport from '../../../../common/images/CommonImages';
import { Margin } from '@mui/icons-material';

const Testimonial = () => {
      const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            responsive: [
                  {
                        breakpoint: 1024,
                        settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true
                        }
                  },
                  {
                        breakpoint: 768,
                        settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              initialSlide: 1
                        }
                  }
            ]
      };

      return (
            <div className="testimonials-area bg-light py-5">
                  <div className="container">
                        <div className="section-title text-center">
                              <span>TESTIMONIAL</span>
                              <h2>What people say about us</h2>
                        </div>

                        <Slider className="testimonial_mar" {...settings} >
                              <div className="testimonials-card-two ">
                                    <div className="rating">
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                    </div>
                                    <p>“Morbi porttitor ligula id varius consectetur. Integer ipsum justo, congue sit amet massa
                                          vel,
                                          porttitor semper magna. Orci varius natoque penatibus et magnis dis parturient montes,
                                          nascetur
                                          ridiculus.”</p>
                                    <div className="content">
                                          <img src={CommonImageTransport.testimonials1} alt="testimonials" />
                                          <h3>Nikolas brooten</h3>
                                          <span>Student</span>
                                    </div>
                                    <div className="quote"> <i className="flaticon-quote"></i></div>
                              </div>

                              <div className="testimonials-card-two">
                                    <div className="rating">
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                    </div>
                                    <p>“Morbi porttitor ligula id varius consectetur. Integer ipsum justo, congue sit amet massa
                                          vel,
                                          porttitor semper magna. Orci varius natoque penatibus et magnis dis parturient montes,
                                          nascetur
                                          ridiculus.”</p>
                                    <div className="content">
                                          <img src={CommonImageTransport.testimonials2} alt="testimonials" />
                                          <h3>Terry ambady</h3>
                                          <span>Content strategist</span>
                                    </div>
                                    <div className="quote"> <i className="flaticon-quote"></i></div>
                              </div>

                              <div className="testimonials-card-two">
                                    <div className="rating">
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                    </div>
                                    <p>“Morbi porttitor ligula id varius consectetur. Integer ipsum justo, congue sit amet massa
                                          vel,
                                          porttitor semper magna. Orci varius natoque penatibus et magnis dis parturient montes,
                                          nascetur
                                          ridiculus.”</p>
                                    <div className="content">
                                          <img src={CommonImageTransport.testimonials3} alt="testimonials" />
                                          <h3>Cory zamora</h3>
                                          <span>Photographer</span>
                                    </div>
                                    <div className="quote"> <i className="flaticon-quote"></i></div>
                              </div>
                              <div className="testimonials-card-two">
                                    <div className="rating">
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                          <i className="ri-star-fill"></i>
                                    </div>
                                    <p>“Morbi porttitor ligula id varius consectetur. Integer ipsum justo, congue sit amet massa
                                          vel,
                                          porttitor semper magna. Orci varius natoque penatibus et magnis dis parturient montes,
                                          nascetur
                                          ridiculus.”</p>
                                    <div className="content">
                                          <img src={CommonImageTransport.testimonials3} alt="testimonials" />
                                          <h3>Jonquil von</h3>
                                          <span>Photographer</span>
                                    </div>
                                    <div className="quote"> <i className="flaticon-quote"></i></div>
                              </div>



                        </Slider >

                  </div>
            </div>
      )
}

export default Testimonial