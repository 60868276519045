import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonImageTransport from "../../../common/images/CommonImages";
import Loader from "../../../../loader/Loader";
import Typewriter from "typewriter-effect";
import "react-tagsinput/react-tagsinput.css";
import "../../../../assets/css/style.css";
import Recruiter_Header from "../recruiter_header/Recruiter_Header";
import Modal from "react-bootstrap/Modal";
import {
  GetStudentInterviewList,
  GetInterviewWithRounds,
} from "../../../../api/Recuriter";
import Recuriter_Technical_Interviewing from "./Recuriter_Technical_Interviewing";
import Recuriter_Behaviral_Interviewing from "./Recuriter_Behaviral_Interviewing";
import Recuriter_Decision_Interviewing from "./Recuriter_Decision_Interviewing";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Recruiter_Footer from "../recruiter_footer/Recruiter_Footer";
import Recuriter_Student_Resume_Modal from "../recuriter_student_resume_modal/Recuriter_Student_Resume_Modal";
import { GetStudentResume } from "../../../../api/Student";
import moment from "moment-timezone";
const Recuriter_Interviewing = () => {
  const navigate = useNavigate();
  // <--------- This is UseState for Loader Pages-------->
  const [isLoading, setIsLoading] = useState(false);
  const [ShowStudentResumeModal, setShowStudentResumeModal] = useState(false);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [GetInterviwerList, setGetInterviwerList] = useState([]);
  const [StudentProfile, setStudentProfile] = useState([]);
  const [ActiveTab, setActiveTab] = useState(0);
  console.log("setActiveTab", ActiveTab)
  const [StudnetId, setStudentId] = useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("User_Token");
  const [interviewRoundsData, setInterviewRoundsData] = useState([]);

  const handleGetInterviewWithRounds = (data) => {
    setInterviewRoundsData(data);
  };

  console.log("interviewRoundsData", interviewRoundsData)
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredList = GetInterviwerList?.filter(
    (item) =>
      item?.studentdetails?.fname?.toLowerCase().includes(searchTerm) ||
      item?.studentdetails?.lname?.toLowerCase().includes(searchTerm) ||
      item?.jobdetails?.title?.toLowerCase().includes(searchTerm) ||
      item?.studentdetails?.email?.toLowerCase().includes(searchTerm) ||
      item?.studentdetails?.location?.toLowerCase().includes(searchTerm) ||
      item?.studentprofile?.p_title?.toLowerCase().includes(searchTerm) ||
      item?.jobdetails?.unique_id?.toLowerCase().includes(searchTerm) ||
      item?.jobdetails?.organization?.toLowerCase().includes(searchTerm)
  );
  console.log("filteredList", GetInterviwerList[0]?.studentdetails)

  const contentRef = useRef(null);
  // <------------------ Hnadle Get Interviwer List -------------->
  const HandleGetInterviwerList = async () => {
    setIsLoading(true);
    try {
      const response = await GetStudentInterviewList(headers);
      setGetInterviwerList(response?.data?.data);
      if (response?.data?.statusCode == "200") {
        setIsLoading(false);
      }
      localStorage.setItem(
        "GetInterviewWithRounds_id",
        response?.data?.data[ActiveTab]?.id
      );
    } catch (error) {
      setIsLoading(true);
      console.log(error);
    }
  };

  const HandleActiveTab = (tabs) => {
    setActiveTab(tabs);
    localStorage.setItem("GetInterviewWithRounds_id", tabs);
  };

  const Recuriter_Interviewing = async (e) => {
    e.preventDefault();
    const role_id = localStorage.getItem("role_id");
    if (role_id === "4") {
      navigate("/recruiter_pages/RecruiterInterviewing");
    } else {
      setTimeout(() => {
        navigate("/Signin");
      }, 2000);
    }
  };

  useEffect(() => {
    HandleGetInterviwerList();
  }, []);

  // <---------- Show the bydefault First Jobs  --------->
  useEffect(() => {
    if (GetInterviwerList?.length > 0) {
      // Filter the list to exclude items with fianl_decision === 3
      const filteredList = GetInterviwerList.filter(item => item.fianl_decision !== 3);

      // If there are items left after filtering, set the active tab to the first item's id
      if (filteredList.length > 0) {
        HandleActiveTab(filteredList[0]?.id);
      }
    }
  }, [GetInterviwerList]);

  console.log("GetInterviwerList_useEffect", GetInterviwerList);


  // Filter the interview rounds to only include Technical rounds
  const technicalInterviewsRoundsData = interviewRoundsData?.flatMap(
    (interviewWithRoundRes) =>
      interviewWithRoundRes?.interviewdetails?.filter(
        (detail) => detail?.roundtype === "Technical"
      ) || []
  );
  // Filter the interview rounds to only include Behavioral rounds
  const BehavioralInterviewsRoundsData = interviewRoundsData?.flatMap(
    (interviewWithRoundRes) =>
      interviewWithRoundRes?.interviewdetails?.filter(
        (detail) => detail?.roundtype === "Behavioral"
      ) || []
  );
  // Filter the interview rounds to only include Behavioral rounds
  const DecisionInterviewsRoundsData = interviewRoundsData?.flatMap(
    (interviewWithRoundRes) =>
      interviewWithRoundRes?.interviewdetails?.filter(
        (detail) => detail?.roundtype === "Decision"
      ) || []
  );

  // console.log("setCheckedRequestMoreProfileList", CheckedRequestMoreProfileList)
  const handleShowStudentResume = () => {
    setShowStudentResumeModal(true);
  };

  const handleCloseStudentResume = () => {
    setShowStudentResumeModal(false);
  }

  const GetStudentDetailsById = (student_userid) => {
    setStudentId(student_userid)
    GetStudentProfile(student_userid)
    setShowStudentResumeModal(true)
  }

  //    <---------- Get the Student Profile here ------------>
  const GetStudentProfile = async (StudnetId) => {
    const StudentResumeData = {
      id: StudnetId
    };
    console.log("StudentResumeData", StudentResumeData)
    const response = await GetStudentResume(StudentResumeData, headers)
    setStudentProfile(response?.data?.data)
  }
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bg_nav-slid bg-white">
            <Recruiter_Header />
          </div>

          <div className="recruiter_list pb-5 pt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        aria-current="page"
                        to="/recruiter_pages/NewProfile"
                      >
                        New Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        onClick={Recuriter_Interviewing}
                        to="#"
                      >
                        Interviewing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/RecruiterList"
                      >
                        Hired
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/Recuriter_Rejected"
                      >
                        Rejected
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/AllProfile"
                      >
                        All
                      </Link>
                    </li>
                  </ul>
                  {GetInterviwerList?.length > 0 ? (
                    <>
                      <form>
                        <div className="searchlocation">
                          <div className="iconfield">
                            <div className="icon_search">
                              <img
                                src={CommonImageTransport?.searchicon}
                                alt=""
                              />
                            </div>

                            {!showSearchFields ? (
                              <div onClick={() => setShowSearchFields(true)}>
                                <Typewriter
                                  options={{
                                    loop: true,
                                  }}
                                  onInit={(typewriter) => {
                                    typewriter
                                      .typeString("Search by title")
                                      .callFunction(() => { })
                                      .pauseFor(2500)
                                      .deleteAll()
                                      .callFunction(() => { })
                                      .typeString("Search by location")
                                      .pauseFor(2500)
                                      .start();
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="text"
                                  placeholder=""
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                  style={{ border: "none", outline: "none" }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="iconfield">
                            <div className="icon_search">
                              <img
                                src={CommonImageTransport?.locationicon}
                                alt=""
                              />
                            </div>
                            <div className="input-field">
                              <input
                                type="text"
                                placeholder="USA, Florida"
                                onChange={handleSearchChange}
                                className="form-control"
                                required=""
                              />
                            </div>
                          </div>
                          <div className="input-field ad121">
                            <button
                              type="button"
                              onClick={handleSearchChange}
                              className="submit_btn"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="left_recruiterlist">
                        <div className="sticky-header">
                          <div
                            className="nav flex-column recruiter_all_lists nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {filteredList?.map(
                              (GetInterviwerListResult, index) => {

                                return (
                                  <>
                                    {(GetInterviwerListResult?.fianl_decision != 3 && GetInterviwerListResult?.fianl_decision != 0) && <div
                                      key={index}
                                      className={`nav-link ${ActiveTab ===
                                          GetInterviwerListResult?.id
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() => {
                                        HandleActiveTab(
                                          GetInterviwerListResult?.id
                                        );
                                      }}
                                      id="v-pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="v-pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true"
                                    >
                                      <div className="card_rec position-relative">
                                        <div className="mentersvies0121">
                                          <div className="position-relative">
                                            {GetInterviwerListResult
                                              ?.studentdetails?.profileimg !=
                                              null ? (
                                              <>
                                                <img
                                                  src={
                                                    GetInterviwerListResult
                                                      ?.studentdetails
                                                      ?.profileimg
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={
                                                    CommonImageTransport?.userLogo
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            )}

                                            <span className="live_user bginter">
                                              Interviewing
                                            </span>
                                          </div>
                                          <div className="instructors-details-contents">
                                            <div className="d-flex justify-content-between gap-2">
                                              <div className="comnam">
                                                <h3>
                                                  {
                                                    GetInterviwerListResult
                                                      ?.studentdetails?.fname
                                                  }{" "}
                                                  {
                                                    GetInterviwerListResult
                                                      ?.studentdetails?.lname
                                                  }
                                                </h3>
                                                <h6 className="sub-title">
                                                  {
                                                    GetInterviwerListResult
                                                      ?.studentdetails?.email
                                                  }
                                                </h6>
                                                <h6 className="sub-title">
                                                  {
                                                    GetInterviwerListResult
                                                      ?.studentprofile?.p_title
                                                  }
                                                </h6>
                                              </div>
                                            </div>
                                            <p className="perographsv">
                                              {
                                                GetInterviwerListResult
                                                  ?.jobdetails?.organization
                                              }
                                            </p>
                                            <h6 className="sub-title">
                                              {
                                                GetInterviwerListResult
                                                  ?.jobdetails?.title
                                              }
                                            </h6>
                                            <p className="perographsv">
                                              <strong>Job Id:</strong>
                                              {
                                                GetInterviwerListResult
                                                  ?.jobdetails?.unique_id
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <div className="skillstopic">
                                            <div>
                                              {
                                                GetInterviwerListResult
                                                  ?.jobdetails?.skills
                                              }
                                            </div>
                                          </div>

                                          <div>
                                            {/* Trigger Button */}
                                            <div
                                              className="btn btn-primary"
                                              onClick={() => GetStudentDetailsById(GetInterviwerListResult?.studentdetails?.id)}
                                            >
                                              View Resume
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                        {/* <----------- Get the Interviwer Details sections here ------------->  */}

                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className={`tab-pane fade ${ActiveTab ? "show active" : ""
                              }`}
                            id={`v-pills-${ActiveTab}`}
                            role="tabpanel"
                            aria-labelledby={`v-pills-${ActiveTab}-tab`}
                          >

                            <div className="slider_descd">
                              <h3>Hiring Process View</h3>
                              <div className="allprocess">
                                <ol
                                  className="progress hireprocessview"
                                  data-steps="3"
                                >
                                  {technicalInterviewsRoundsData?.length > 0 &&
                                    technicalInterviewsRoundsData[
                                      technicalInterviewsRoundsData.length - 1
                                    ]?.status == "Completed" ? (
                                    <li className="active">
                                      <span className="step">
                                        <span>1</span>
                                      </span>
                                      <div className="headinghire">
                                        <h5>Technical</h5>
                                      </div>
                                    </li>
                                  ) : (
                                    <>
                                      <li className="done">
                                        <span className="step1">
                                          <span>1</span>
                                        </span>
                                        <div className="headinghire">
                                          <h5>Technical</h5>
                                        </div>
                                      </li>
                                    </>
                                  )}

                                  {BehavioralInterviewsRoundsData?.length > 0 &&
                                    BehavioralInterviewsRoundsData[
                                      BehavioralInterviewsRoundsData.length - 1
                                    ]?.status == "Completed" ? (
                                    <>
                                      <li className="active">
                                        <span className="step">
                                          <span>2</span>
                                        </span>
                                        <div className="headinghire">
                                          <h5>Behavioural</h5>
                                        </div>
                                      </li>
                                    </>
                                  ) : (
                                    <li className="done">
                                      <span className="step1">
                                        <span>2</span>
                                      </span>
                                      <div className="headinghire">
                                        <h5>Behavioural</h5>
                                      </div>
                                    </li>
                                  )}

                                  {DecisionInterviewsRoundsData?.length > 0 &&
                                    DecisionInterviewsRoundsData[
                                      DecisionInterviewsRoundsData.length - 1
                                    ]?.rating != null ? (
                                    <li className="active">
                                      <span className="step">
                                        <span>3</span>
                                      </span>
                                      <div className="headinghire">
                                        <h5>Decision</h5>
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="done">
                                      <span className="step1">
                                        <span>3</span>
                                      </span>
                                      <div className="headinghire">
                                        <h5>Decision</h5>
                                      </div>
                                    </li>
                                  )}
                                </ol>

                                <div className="hiring_process">
                                  {/*<----------  Technical Interview Sections ------------> */}
                                  <Recuriter_Technical_Interviewing
                                    headers={headers}
                                    onGetInterviewWithRounds={
                                      handleGetInterviewWithRounds
                                    }
                                    GetInterviewWithRounds={
                                      GetInterviewWithRounds
                                    }
                                  />
                                  {/*<----------  Technical Interview Sections ------------> */}
                                  <Recuriter_Behaviral_Interviewing
                                    headers={headers}
                                    GetInterviewWithRounds={
                                      GetInterviewWithRounds
                                    }
                                  />
                                  {/* <--------- Decison Sections ---------> */}
                                  <Recuriter_Decision_Interviewing
                                    headers={headers}
                                    GetInterviewWithRounds={
                                      GetInterviewWithRounds
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-center mt-5">
                      <h4>Not any interviewing profile available here</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <---------- Student Resume section's ------------> */}
      <Modal size='xl' show={ShowStudentResumeModal} onHide={() => setShowStudentResumeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Student Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="resumefile">
            <page size="A4">
              <div class="d-flex">
                <div class="leftPanel">
                  {
                    StudentProfile?.student_info?.profileimg != null ? (
                      <>
                        <img src={StudentProfile?.student_info?.profileimg} />
                      </>
                    ) : (
                      <>
                        <img src={CommonImageTransport?.userLogo} />
                      </>
                    )
                  }
                  <div class="details">
                    <div class="item bottomLineSeparator">
                      <div class="smallText">
                        <p>
                          <i class="fa fa-phone contactIcon" aria-hidden="true"></i>
                          <p>{StudentProfile?.student_info?.fname} {StudentProfile?.student_info?.lname}</p>
                        </p>
                      </div>

                      <div class="smallText">
                        <p>
                          <i class="fa fa-phone contactIcon" aria-hidden="true"></i> {StudentProfile?.student_info?.mobile}
                        </p>
                        <p>
                          <i class="fa fa-envelope contactIcon" aria-hidden="true"></i>
                          <a href="mailto:lorem@ipsum.com@gmail.com">{StudentProfile?.student_info?.email}</a>
                        </p>
                        <p>
                          <i class="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                          {StudentProfile?.student_info?.city},   {StudentProfile?.student_info?.state},   {StudentProfile?.student_info?.country}
                        </p>
                      </div>
                    </div>
                    <div class="item bottomLineSeparator">
                      <h2>
                        SKILLS
                      </h2>
                      <div class="smallText">
                        {
                          StudentProfile?.student_skills?.map((StudentSkillsResult) => {
                            return (
                              <>
                                <div class="skill">
                                  <div>
                                    <span>{StudentSkillsResult?.skill}</span>
                                  </div>
                                  <div class="yearsOfExperience">
                                    <span class="alignright">{StudentSkillsResult?.expertise}</span>
                                  </div>
                                </div>
                              </>
                            )
                          })
                        }

                      </div>
                    </div>
                    <div class="item">
                      <h2>EDUCATION</h2>
                      <div class="smallText">
                        {
                          StudentProfile?.student_educations?.map((StudentEdutionResult) => {
                            return (
                              <>
                                <div class="smallText">
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreetype}
                                  </p>
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreename}
                                  </p>
                                  <p>
                                    {StudentEdutionResult?.university}
                                  </p>
                                  <p>
                                    {moment.utc(StudentEdutionResult?.startdate).format("MM/YYYY")}  -  {moment.utc(StudentEdutionResult?.enddate).format("MM/YYYY")}
                                  </p>
                                </div>
                              </>
                            )
                          })

                        }
                      </div>


                    </div>
                  </div>

                </div>
                <div class="rightPanel pt-0">
                  <div class="workExperience">
                    <h4>
                      Work Experience
                    </h4>
                    <ul>
                      {
                        StudentProfile?.st_experience?.map((StudentProfessnalExp, index) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    <h3>{StudentProfessnalExp?.p_title} <span>
                                      ({StudentProfessnalExp?.emp_type})
                                    </span></h3>
                                    <h6>
                                      {StudentProfessnalExp?.company}{" "}

                                    </h6>
                                  </span>

                                  {StudentProfessnalExp?.start_date && (
                                    <small className="fw-normal small">
                                      {moment.utc(
                                        StudentProfessnalExp?.start_date
                                      ).format(
                                        "MM/DD/YYYY"
                                      )}{" "}
                                      -{" "}
                                      <>
                                        {StudentProfessnalExp?.end_date !=
                                          null && (
                                            <>
                                              {moment.utc(
                                                StudentProfessnalExp?.end_date
                                              ).format(
                                                "MM/DD/YYYY"
                                              )}
                                            </>
                                          )}
                                      </>
                                    </small>
                                  )}
                                </div>
                                <p>{StudentProfessnalExp?.job_desc}{" "}</p>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <div className="agree-label d-flex gap-2">
                                      {StudentProfessnalExp?.currently_working ==
                                        1 && (
                                          <>
                                            <input
                                              type="checkbox"
                                              name="currently_working"
                                              checked={
                                                StudentProfessnalExp?.currently_working ===
                                                1
                                              }
                                              id={`chb${index}`}
                                              className="termcheck"
                                              readOnly
                                            />
                                            <label
                                              htmlFor={`chb${index}`}
                                              className="small text-muted"
                                            >
                                              I am currently working
                                              in this role
                                            </label>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        })
                      }

                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>
                      Projects
                    </h4>
                    <ul>
                      {
                        StudentProfile?.university_projects?.map((StudentWorkExpResult) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    {StudentWorkExpResult?.title}
                                  </span>
                                </div>
                                <div>
                                  {
                                    StudentWorkExpResult?.websitelink && (
                                      <a
                                        href={StudentWorkExpResult.websitelink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {StudentWorkExpResult.websitelink}
                                      </a>
                                    )
                                  }
                                </div>

                                <div class="smallText">
                                  <p>{StudentWorkExpResult?.yourrole}</p>
                                </div>


                              </li>
                            </>
                          )
                        })
                      }
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>
                      Certificate
                    </h4>
                    <ul>
                      {
                        StudentProfile?.student_certification?.length > 0 && (
                          <>
                            {
                              StudentProfile?.student_certification?.map((StudentCertificateResult) => {
                                return (
                                  <>
                                    <li>
                                      <div class="jobPosition">
                                        <span class="bolded">
                                          {StudentCertificateResult?.certificate}
                                        </span>
                                        <span>
                                          <small className="fw-normal small">
                                            {moment.utc(StudentCertificateResult?.certification_date).format("MM/DD/YYYY")}
                                          </small>

                                        </span>
                                      </div>
                                      <div class="projectName">
                                        <p>
                                          {StudentCertificateResult?.organication}
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                )
                              })
                            }
                          </>
                        )
                      }
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>
                      Training Program
                    </h4>
                    <ul>
                      {
                        StudentProfile?.complete_course?.length > 0 && (
                          <>
                            {
                              StudentProfile?.complete_course?.map((StudentCompleteCourseResult) => {
                                return (
                                  <>
                                    <li>
                                      <div class="jobPosition">
                                        <span class="bolded">
                                          {StudentCompleteCourseResult?.title}
                                        </span>
                                      </div>
                                      <div class="projectName">
                                        <p>
                                          {StudentCompleteCourseResult?.duration}
                                        </p>
                                      </div>
                                      <div class="projectName">
                                        <p>
                                          {StudentCompleteCourseResult?.description}
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                )
                              })
                            }
                          </>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </page>

          </div>
        </Modal.Body>
      </Modal>
      <Recruiter_Footer />
    </div>
  );
};

export default Recuriter_Interviewing;
