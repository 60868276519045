import React, { useEffect,useState } from 'react'
import Header from '../../../common/header/Header'
import Footer from '../../../common/footer/Footer'
import BackToTop from '../../../common/back_to_top/Back_To_Top';
import backgroundImage from '../../../../assets/images/hiring-process.png'
import CommonImageTransport from '../../../common/images/CommonImages';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../../../loader/Loader';

const RecruiterHiring = () => {
  // <--------- This is UseState for Loader Pages-------->
  // const [isLoading, setIsLoading] = useState(true);
    
  // <--------- This UseEffect for Loader Pages-------->
  // useEffect(() => {
  //     const timeout = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 2200);
  //     return () => clearTimeout(timeout);
  //   }, []);
    
useEffect(() => {
    document.title = 'Welcome to FreshGrad';
}, []);

// <---------- This UseEffect for the Animation Top to Bottom ---------------->
useEffect(() => {
  const timeout = setTimeout(() => {
    document.title = 'Training Program';
    document.querySelector('.banner-img').classList.add('animate-from-bottom');
    document.querySelector('.banner-content').classList.add('animate-from-top');
    // setIsLoading(false); 
  }, 1000);
  return () => clearTimeout(timeout);
}, []);

  /*----------------------Slider for Brand Item----------------------------*/
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
    arrows: false, 
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              
          }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
          }
      }
  ]
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);
  return (
   <div>
    
        <>
        <Header />
  <div className="" />
  <div className="recruiterbgme ptb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="banner-content">
            <span data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">Welcome to Hiring manager</span>
            <h1 data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
              Next Generation hiring solution. </h1>
            <p data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
              Powerful, agile, all-in-one hiring tool for your recruiting needs, built with productivity at heart and loaded with features to help your hair more effectively.</p>
            <div className="banner-form-area" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
              {/* <button className="default-btn rounded-pill" type="submit">
                Free Trial
              </button> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="banner-img" data-speed="0.05" data-revert="true">
            <img src={CommonImageTransport.HiringManager} data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true" alt="Man" />
            <div className="right-content job__qui" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
              <div className="content">
                <img src={CommonImageTransport.JobMe} alt="JobMe" />
              </div>
            </div>
            <div className="left-content applicantsuser" data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
              <h5>Applicants</h5>
              <div className="content">
                <img className={50} height={50} src={CommonImageTransport.testimonials1} alt="User" />
                <div>
                  <h3>San Francisco</h3>
                  <p>2H Ago</p>
                </div>
              </div>
              <div className="content">
                <img className={50} height={50} src={CommonImageTransport.testimonials2} alt="User" />
                <div>
                  <h3>San francisco</h3>
                  <p>4H Ago</p>
                </div>
              </div>
              <div className="content">
                <img className={50} height={50} src={CommonImageTransport.testimonials3} alt="User" />
                <div>
                  <h3>San francisco</h3>
                  <p>2day Ago</p>
                </div>
              </div>
            </div>
            <div className="banner-img-shape">
              <div className="shape1" data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                <img src={CommonImageTransport.shape3} alt="Shape" />
              </div>
              <div className="shape2" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                <img src={CommonImageTransport.shape2} alt="Shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="banner-shape">
      <div className="banner-shape1">
        <img src={CommonImageTransport.shape1} alt="Shape" />
      </div>
    </div>
  </div>
  <div className="bg-white py-5">
    <div className="container">
      <div className="title mb-35">
        <h3> We worked with <span>250+ </span> leading universities and companies</h3>
      </div>
      <div className="brand-slider owl-carousel owl-theme">
      <Slider {...settings} >
        <div className="brand-item">
          <img src={CommonImageTransport.client1} className="brand-item-logo1" alt="Images" />
        </div>
        <div className="brand-item">
          <img src={CommonImageTransport.client2} className="brand-item-logo1" alt="Images" />
        </div>
        <div className="brand-item">
          <img src={CommonImageTransport.client3} className="brand-item-logo1" alt="Images" />
        </div>
        <div className="brand-item">
          <img src={CommonImageTransport.client4} className="brand-item-logo1" alt="Images" />
        </div>
        <div className="brand-item">
          <img src={CommonImageTransport.client5} className="brand-item-logo1" alt="Images" />
        </div>
        <div className="brand-item">
          <img src={CommonImageTransport.client6} className="brand-item-logo1" alt="Images" />
        </div>
        </Slider>
      </div>
    </div>
  </div>
  <div className="hirignProcess">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="hiring__processection p-3 p-md-4 p-lg-5" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="section-title text-center">
              <h2 className="text-white">End to end hiring process</h2>
              <p className="text-white">
                Recruiten is set up to allow everyone on your team to contribute to the hiring effort. You can easily divvy up the work and get everyone involved
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-md-8">
                <div className="hireone">
                  <img src={CommonImageTransport.hire1} className="img-fluid" alt="hire1" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="hireone">
                  <img src={CommonImageTransport.hire2} className="img-fluid" alt="hire2" />
                </div>
                <div className="hireone">
                  <img src={CommonImageTransport.hire3} className="img-fluid" alt="hire3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="enrolled-areas py-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="enrolled-img" data-speed="0.05" data-revert="true">
            <img src={CommonImageTransport.Innovatively} alt="Enrolled"/>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="enrolled-content">
            <div className="section-title pt-3 ps-0 px-lg-5">
              <h2>Innovatively built for recruiters and applicants</h2>
              <p>
                We create engaging experience with our radicitted tool, post on application and let your condide-checkout these way towards on new
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BackToTop />
  <Footer />
        </>
     
 
</div>

  )
}

export default RecruiterHiring
