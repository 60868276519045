import React from 'react'
import "../../../../../assets/css/style.css"
import CommonImageTransport from '../../../../common/images/CommonImages'
const AboutFreshGrad = () => {
      return (
            <div className="enrolled-area-two py-5">
                  <div className="container">
                        <div className="row align-items-center">
                              <div className="col-lg-6">
                                    <div className="enrolled-img-three mb-30 pr-20">
                                          <img src={CommonImageTransport.enrolled} alt="Enrolled" />
                                          <div className="enrolled-img-content">
                                                <i className="flaticon-discount"></i>
                                                <div className="content">
                                                      <h3>Get 40% off</h3>
                                                      <p>Every course</p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-lg-6">
                                    <div className="enrolled-content mb-30">
                                          <div className="section-title">
                                                <span>About Our FreshGard</span>
                                                <h2>A Few Words About the FreshGard.</h2>
                                                <p>
                                                      Education is a vital aspect of human development, providing individuals with
                                                      knowledge,
                                                      skills, and opportunities to acquire new information.encompasses formal learning
                                                      institutions
                                                </p>
                                          </div>
                                          <div className="row">
                                                <div className="col-lg-6 col-6">
                                                      <ul className="enrolled-list">
                                                            <li><i className="flaticon-check"></i> Full lifetime access</li>
                                                            <li><i className="flaticon-check"></i> Certificate of completion</li>
                                                      </ul>
                                                </div>
                                                <div className="col-lg-6 col-6">
                                                      <ul className="enrolled-list">
                                                            <li><i className="flaticon-check"></i> 20+ downloadable resources</li>
                                                            <li><i className="flaticon-check"></i> Free trial 7 days</li>
                                                      </ul>
                                                </div>
                                          </div>
                                          {/* <a href="/" className="default-btn border-radius-50">Read More..</a> */}
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default AboutFreshGrad