import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../images/CommonImages';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProfileSections } from '../../../api/Auth';
import { GetSetings } from '../../../api/Public';

const Header = () => {
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState(null);
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("Is_LoggedIn"));
  const [GetUserProfile, SetGetUserProfile] = useState([])
  const [SettingList, setSettingList] = useState({});
  const user_id = localStorage.getItem("User_id")
  const token = localStorage.getItem("User_Token");
  // <--------- Get Role id ---------------->
  useEffect(() => {
    const role_id = localStorage.getItem('role_id');
    setRoleId(role_id);
  }, []);

  const headers = {
    "Authorization": `Bearer ${token}`
  }
  const ProfileSections1 = async () => {
    const response = await ProfileSections({ user_id, headers })
    SetGetUserProfile(response?.data?.data?.profile)
  }
  // <------- Render the User Profile Sections ----------->
  useEffect(() => {
    ProfileSections1();
  }, [])
  const HandleRecruiter = () => {
    localStorage.removeItem("HandleMentorship");
    localStorage.setItem("HandleRecruiter", "Recruiter");
  };

  const HandleMentorship = () => {
    localStorage.removeItem("HandleRecruiter");
    localStorage.setItem("HandleMentorship", "Mentorship");
  };
  const RemoveLocalStorage = () => {
    localStorage.removeItem("HandleMentorship");
    localStorage.removeItem("HandleRecruiter");
  }

   // <------------- Logout the User ---------------->
   const LogoutUser = () => {
    const User_Token = localStorage.getItem("User_Token");
    if (User_Token) {
        const confirmation = window.confirm("Are you sure you want to logout?");
        if (confirmation) {
            localStorage.removeItem("User_Token");
            localStorage.removeItem("Is_LoggedIn");
            localStorage.removeItem("user_Login_Data");
            localStorage.removeItem("user_Email");
            localStorage.removeItem("User_id");
            localStorage.removeItem("rememberedUser");
            localStorage.removeItem("role_id");

            navigate('/Signin');
        } else {
            console.log("User canceled the logout");
        }
    }
};

//<-------- Global setting's ------------>
const HandleGetSettingList = async () => {
  const response = await GetSetings();
  setSettingList(response?.data?.data);
};


useEffect(() => {
  HandleGetSettingList();
}, []);

  return (
    <>
      <Navbar expand="lg" className="navbar-area ledu-area otherpages-menu">
        <div className='container-fluid'>
          <Navbar.Brand as={Link} to="/">
          {
              SettingList?.headerlogo != null ? (
                <>
                  <img src={SettingList?.headerlogo} height={50} className="logo-one rounded-pill" alt="logo" />
                </>

              ) : (
                <>
                  <img src={CommonImageTransport?.logoFull} height={50} className="logo-one rounded-pill" alt="logo" />

                </>
              )
            }
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100">
              <ul className="navbar-nav w-100 ms-md-5">
                <li className="nav-item">
                  <Link to="/TrainingPrograms"
                    onClick={RemoveLocalStorage} className={location.pathname === '/TrainingPrograms' ? 'active nav-link' : 'nav-link'}>
                    Training Programs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Recruiter" onClick={HandleRecruiter}
                    className={location.pathname === '/Recruiter' ? 'nav-link active' : 'nav-link'}>
                    Recruiter
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Mentorship" onClick={HandleMentorship}
                    className={location.pathname === '/Mentorship' ? 'active nav-link' : 'nav-link'}>
                    Mentorship
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Jobs" onClick={HandleMentorship} className={location.pathname === "/Jobs" ? "active nav-link" : "nav-link"} >Jobs</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Contact" onClick={RemoveLocalStorage} className={location.pathname === '/Contact' ? 'active nav-link' : 'nav-link'}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </Nav>

          </Navbar.Collapse>
          <Nav>
            {isLoggedIn ? (
              <Dropdown className="others-options">
                <Dropdown.Toggle variant="text-white">
                  {
                    GetUserProfile?.profileimg != null ?
                      <>
                        <img src={GetUserProfile?.profileimg} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </> :
                      <>
                        <img src={CommonImageTransport.userLogo} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </>
                  }

                  {`${GetUserProfile?.fname}  ${GetUserProfile?.lname}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link to="/Profile" className='dropdown-item'><i className="flaticon-user" />Profile</Link>
                  
                    <li className='dropdown-item' onClick={LogoutUser}>
                    <i className="ri-logout-box-fill"/>Logout</li>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="others-options login__signup gap-2 d-flex align-items-center">
                <div className="optional-item">
                  <Link to="/Signin" className={location.pathname === '/Signin' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign In</Link>
                </div>
                <div className="optional-item">
                  <Link to="/Student_SignUp" className={location.pathname === '/Student_SignUp' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign up</Link>
                </div>
              </div>
            )}
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="px-1" />
        </div>
       

      </Navbar>
      <div class="header_space"></div>
    </>

  )
}
export default Header
