import React from "react";
import CommonImageTransport from "../../../../common/images/CommonImages";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';

const TrainerBlock = () => {
  const navigate = useNavigate();
  const AddAllMentors = async (e) => {
    e.preventDefault();
    const role_id = localStorage.getItem("role_id");
    if (role_id === "2") {
          navigate("/Student/Student_Mentor_List");
    } else if (role_id === "3" || role_id === "4") {
          toast.error("You Can't access !!!");
          setTimeout(() => {
                navigate("/");
          }, 2000);
    }
    else {
          toast.error("User Not Sign in here !!!");
          setTimeout(() => {
                navigate("/Signin");
          }, 2000);
    }
}
  return (
    <div className="featured-area bg-light py-5">
      
        <div class="container">
        <ToastContainer style={{ marginTop: "120px" }} />
          <div class="row align-items-center mb-45">
            <div class="col-lg-8 col-md-9">
              <div class="section-title mt-rs-20">
                <h2>Unlock Your Career</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-4  col-md-3 text-end">
              <img src={CommonImageTransport.users} alt="" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-7 col-lg-9">
              <div class="row career-se">
                <div class="col-lg-8 col-12 mb-3">
                  <div class="banner-item position-relative">
                    <span>Free</span>
                    <h3>
                      Germany Foundation
                      <br />
                      Document
                    </h3>
                    <Link to="/course_list" class="e-btn">
                      View Courses
                    </Link>
                    <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                      <img src={CommonImageTransport?.careerbanner} alt="img" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 mb-3">
                  <div class="banner-item trainer-ban position-relative">
                    <span>Trainer</span>
                    <h3>
                      Trainer skills
                      <br /> daily
                    </h3>
                    <Link to="#" onClick={AddAllMentors} class="e-btn">
                      View
                    </Link>
                    <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                      <img
                        src={CommonImageTransport?.careerbanner2}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 mb-3">
                  <div class="banner-item careerbg trainer-ban position-relative">
                    <div class="banner-content"></div>
                    <span>Careers</span>
                    <h3>
                      Jobs <br />
                      Explore
                    </h3>
                    <Link to="/Jobs" class="e-btn">
                      View
                    </Link>
                    <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                      <img
                        src={CommonImageTransport?.careerbanner3}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-12 mb-3">
                  <div class="banner-item menterbg position-relative">
                    <span>Top Mentors</span>
                    <h3>
                      Mentorship <br />
                      Guidance
                    </h3>
                    <Link to="/Mentorship" class="e-btn">
                      View
                    </Link>
                    <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                      <img
                        src={CommonImageTransport?.careerbanner4}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-3">
              <div class="banner-item companybg trainer-ban position-relative">
                <div class="content-containers">
                  <div class="company">
                    <span>Company</span>
                  </div>
                  <div class="title">
                    <h3>
                      Compete Battle <br />
                      For Excellence
                    </h3>
                  </div>
                  {/* <div class="button-container">
                    <a href="#" class="e-btn">
                      View
                    </a>
                  </div> */}
                </div>

                <div class="banner-thumb d-none d-sm-block d-md-blcok d-lg-block">
                  <img src={CommonImageTransport?.careerbanner5} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
    
    </div>
  );
};

export default TrainerBlock;
