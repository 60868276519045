import React, { useEffect, useState } from 'react';
import Footer from '../../../common/footer/Footer';
import BackToTop from '../../../common/back_to_top/Back_To_Top';
import Header from '../../../common/header/Header';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../../../loader/Loader';
import CommonImageTransport from '../../../common/images/CommonImages';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { GetMentorList } from '../../../../api/Mentor';
import TopMentors from '../home/home_components/Top_Mentors';

const TrainerMentor = () => {
  const navigate = useNavigate();
  // <--------- This is UseState for Loader Pages-------->
  // const [isLoading, setIsLoading] = useState(true);
  const [MentorList, setMentorList] = useState([]);

  const HandleGetMentorList = async () => {
    const response = await GetMentorList();
    setMentorList(response?.data?.data?.mentor_list
    )
  }
  console.log("HandleGetMentorList", MentorList)
  const AllMentorList = (e) => {
    e.preventDefault();
    const role_id = localStorage.getItem("role_id");
    if (role_id === "2") {
      navigate("/Student/Student_Mentor_List");
    } 
    else if(role_id === "3" || role_id === "4"){
      navigate("/Signin");
      setTimeout(() => {
        toast.error("You are not Student You cannot Access This !!!");
      }, 2000)
    }
    else {
      navigate("/Signin");
      setTimeout(() => {
        toast.error("You are not Sign Here !!!");
      }, 2000)
    }
  }
  useEffect(() => {
    HandleGetMentorList();
  }, [])
  // <--------- This UseEffect for Loader Pages-------->
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2200);
  //   return () => clearTimeout(timeout);
  // }, []);

  // <---------- This UseEffect for the Animation Top to Bottom ---------------->
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.title = 'Training Program';
      document.querySelector('.banner-img').classList.add('animate-from-bottom');
      document.querySelector('.banner-content').classList.add('animate-from-top');
      // setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  /*----------------------Slider for Brand Item----------------------------*/
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
            <>
              <div className="bg_nav-slid">
                <Header />
              </div>
              <div className="banner-area bg-white py-5">
                <div className="container">
                  <div className="row align-items-center">
                    <ToastContainer style={{ marginTop: "120px" }} />
                    <div className="col-lg-6">
                      <div className="banner-content">
                        <span data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">FOR
                          A BETTER FUTURE</span>
                        <h1 data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                          Take your team collaboration to the next level</h1>
                        <p data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                          Book a session with freshgrad mentors across domain &amp; work together to build your career!</p>
                        <div className="banner-form-area" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                          <button className="default-btn rounded-pill" onClick={AllMentorList} type="submit">
                            Find A Mentor
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="banner-img" data-speed="0.05" data-revert="true">
                        <img src={CommonImageTransport?.trainerBanner} data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true" alt="Man" />
                       
                        <div className="right-content" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                          <i className="flaticon-checked" />
                          <div className="content">
                            <h3>$4578521.95</h3>
                            <p>This month total earn</p>
                          </div>
                        </div>
                        <div className="left-content" data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                          <div className="content">
                            <img src={CommonImageTransport?.userImage} alt="User" />
                            <h3>User experience className</h3>
                            <p>Today at 12.00 PM</p>
                          </div>
                         
                        </div>
                        <div className="banner-img-shape">
                          <div className="shape1" data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                            <img src={CommonImageTransport?.shape3} alt="Shape" />
                          </div>
                          <div className="shape2" data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true">
                            <img src={CommonImageTransport?.shape2} alt="Shape" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-shape">
                  <div className="banner-shape1">
                    <img src={CommonImageTransport?.shape1} alt="Shape" />
                  </div>
                </div>
              </div>
              <div className="play-areas bg-light py-4">
                <div className="container">
                  <div className="title mb-35">
                    <h2> We worked with <span>250+ </span> leading universities and companies</h2>
                  </div>
                  <div className="brand-slider">
                    <Slider {...settings} >
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client1} className="brand-item-logo1" alt="Images" />
                      </div>
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client2} className="brand-item-logo1" alt="Images" />
                      </div>
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client3} className="brand-item-logo1" alt="Images" />
                      </div>
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client4} className="brand-item-logo1" alt="Images" />
                      </div>
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client5} className="brand-item-logo1" alt="Images" />
                      </div>
                      <div className="brand-item">
                        <img src={CommonImageTransport?.client6} className="brand-item-logo1" alt="Images" />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
             <TopMentors/>
              <div className="enrolled-areas">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="enrolled-content">
                        <div className="section-title">
                          <h2>Become a Mentor &amp; Guide FreshGrad Talent!</h2>
                          <p>
                            Join the community of 2000+ mentors &amp; empower future leaders.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="enrolled-img" data-speed="0.05" data-revert="true">
                        <img src={CommonImageTransport?.mentorBanner} alt="Enrolled" />
                        <div className="bg-shape">
                          <img src={CommonImageTransport?.enrolledShape} alt="Shape" />
                        </div>
                        <div className="left-content">
                          <div className="enrolled-img-content">
                            <i className="flaticon-reading-book active" />
                            <div className="content">
                              <h3>110k+ students</h3>
                              <p>Learn daily</p>
                            </div>
                          </div>
                        </div>
                        <div className="enrolled-img-shape">
                          <div className="shape1">
                            <img src={CommonImageTransport?.enrolledShape2} alt="Shape" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BackToTop />
              <Footer />
            </>
      </div>


    </>
  )
}

export default TrainerMentor

