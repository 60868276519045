import React, { useState, useEffect } from 'react';
import Student_Header from '../student_header/Student_Header';
import Typewriter from 'typewriter-effect';
import CommonImageTransport from '../../../common/images/CommonImages';
import 'react-datetime/css/react-datetime.css';
import { GetStudentmentorbookinglist } from '../../../../api/Mentor';
import { Link } from 'react-router-dom';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { GetStudentMentorFeedback, PostStudentMentorFeedback } from '../../../../api/Student';
import { Rating } from 'react-simple-star-rating';
import Loader from '../../../../loader/Loader';
import Student_Footer from '../student_footer/Student_Footer';

const Student_Mentor_Complate_session = () => {
    const [is_Loading, setIs_Loading] = useState(false)
    const [rating, setRating] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [MentorCompleteList, setMentorCompleteList] = useState([])
    console.log("MentorCompleteList", MentorCompleteList)
    const [FeedbackList, setFeedbackList] = useState([])
    const [comment, setComment] = useState('')
    const [ActiveTabs, setActiveTabs] = useState(null);
    console.log("asdmbasfds", ActiveTabs)
    const [MentorId, setMentorId] = useState('');
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredMentorList, setFilteredMentorList] = useState([]);
    console.log("filteredMentorList", filteredMentorList)

    // <--------- User Authorization token's ---------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    // <--------- Active & UnActive ---------->
    const HandleActiveTabs = (tabs) => {
        setActiveTabs(tabs)
    }
    console.log("HandleActiveTabs", ActiveTabs)
    // <----------- Get Student Mentor Complete Session list------------>
    const GetMentorCompleteSession = async () => {
        setIs_Loading(true)
        try {
            const HandleStudentmentorbookinglistData = {
                status: 3
            }
            const response = await GetStudentmentorbookinglist(HandleStudentmentorbookinglistData, headers);
            setMentorCompleteList(response?.data?.data?.booking);
            HandleGetFeedbackform(response?.data?.data?.booking[0]?.mentor_userid);
            setIs_Loading(false)
        } catch (error) {
            setIs_Loading(true)
        }
    }

    // <--------- Get the Active Mentor Id here ----------->
    const HandleMentorId = (id) => {
        setMentorId(id)
    }
    console.log("HandleMentorId", MentorId)

    // <------- Feedback from -------------->
    const HandleFeedBackForm = async () => {
        const feedbackData = {
            mentor_userid: MentorId,
            session_id: ActiveTabs,
            rating: rating,
            comment: comment
        }
        const response = await PostStudentMentorFeedback(feedbackData, headers);
        if (response?.data?.msg === "You have already save feeback for this mentor.") {
            alert("You have already save feeback for this mentor.")
        }
        handleClose();
        HandleGetFeedbackform();
        setTimeout(() => {
            GetMentorCompleteSession();
        }, 1000)
    }

    const HandleGetFeedbackform = async (id) => {
        if (!ActiveTabs) return;  // Early return if ActiveTabs is null
        const Feedbackdata = {
            mentor_id: id,
            session_id: ActiveTabs,
        };
        console.log("HandleGetFeedbackform", Feedbackdata);
        const response = await GetStudentMentorFeedback(Feedbackdata, headers);
        setFeedbackList(response?.data?.data?.mentorFeedback);
    };
    console.log("FeedbackList",FeedbackList)

    // Trigger function when ActiveTabs is updated
    useEffect(() => {
        if (ActiveTabs !== null && MentorCompleteList?.length > 0) {
            HandleGetFeedbackform(MentorCompleteList[0]?.mentor_userid);
        }
    }, [ActiveTabs]);

    // <--------- Search the data ---------->
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value)
    }

    // ----------- Get the Search filter data ------------>
    useEffect(() => {
        const filtered = MentorCompleteList?.filter((MentorListResult) =>
            MentorListResult?.fname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.lname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.country?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.profession?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            MentorListResult?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredMentorList(filtered);
    }, [MentorCompleteList, searchQuery]);

    // <---------------- Get the Mentor Complete Session's List ----------->
    useEffect(() => {
        GetMentorCompleteSession();
    }, [])



    // <-------- Bydefault First Mentor Active ------------>
    useEffect(() => {
        if (filteredMentorList?.length > 0 && (!ActiveTabs || !filteredMentorList?.some(job => job.id === ActiveTabs))) {
            const firstActiveTab = filteredMentorList[0];
            if (firstActiveTab) {
                HandleActiveTabs(firstActiveTab?.id);
            }
        }
    }, [filteredMentorList, ActiveTabs]);

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div>
                            <div className="bg_nav-slid bg-white">
                                <Student_Header />
                            </div>

                            <div className="header_space"></div>
                            <div className="recruiter_list pb-5">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            <ul className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2" id="pills-tab"
                                                role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <Link className='nav-link ' to="/Student/Student_Mentor_List"
                                                        id="pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                                        aria-selected="true">All Mentors</Link>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <Link className="nav-link" to="/Student/Student_Upcoming_Mentor_list" id="pills-disabled-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-Upcoming" type="button" role="tab" aria-controls="pills-Upcoming"
                                                        aria-selected="false">Upcoming Session</Link>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <Link className="nav-link active" to="/Student/Student_Mentor_Complate_session"
                                                        id="pills-profile-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                        aria-selected="false">Complete Session</Link>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    {
                                        MentorCompleteList && MentorCompleteList?.length > 0 ? (
                                            <>
                                                <div className="col-12">
                                                    <form>
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>
                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)}>
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text" onChange={handleSearchInputChange}
                                                                            placeholder=""
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="locationicon" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida"
                                                                        className="form-control" onChange={handleSearchInputChange}
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <button type="button"
                                                                    className="submit_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="tab-content" id="pills-tabContent">
                                                    {/* <---------- Complate Sessions --------------> */}
                                                    {
                                                        is_Loading ? (
                                                            <>
                                                                <Loader />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
                                                                    tabindex="0">
                                                                    <div className="left_recruiterlists row">
                                                                        <div className="col-md-4 col-lg-4">
                                                                            <div className="sticky-header">
                                                                                <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab"
                                                                                    role="tablist" aria-orientation="vertical">
                                                                                    {
                                                                                        filteredMentorList?.map((MentorComplateListResult, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div key={index} className={`nav-link ${ActiveTabs === MentorComplateListResult?.id ?
                                                                                                        "active" : ""}`}
                                                                                                        onClick={() => {
                                                                                                            HandleActiveTabs(MentorComplateListResult?.id)
                                                                                                            HandleGetFeedbackform(MentorComplateListResult?.mentor_userid
                                                                                                            )
                                                                                                        }}
                                                                                                        id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                                        data-bs-target="v-pills-home" type="button"
                                                                                                        role="tab" aria-controls="v-pills-home"
                                                                                                        aria-selected="true">
                                                                                                        <div className="card_rec position-relative">
                                                                                                            <div className="mentersvies0121">
                                                                                                                <div className="position-relative">
                                                                                                                    {
                                                                                                                        MentorComplateListResult?.profileimg != null ? (
                                                                                                                            <>
                                                                                                                                <img src={MentorComplateListResult?.profileimg}
                                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                <img src={CommonImageTransport?.userLogo}
                                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }

                                                                                                                    <span className="live_user">Available</span>
                                                                                                                </div>
                                                                                                                <div className="instructors-details-contents">
                                                                                                                    <div className="">
                                                                                                                        <div className="comnam">
                                                                                                                            <h3>{MentorComplateListResult?.fname} {MentorComplateListResult?.lname} </h3>
                                                                                                                            <ul className="nav mentor4541s text-dark align-items-center">
                                                                                                                                {/* <li className="me-2"><i className="ri-mail-line"></i>
                                                                                                                                    {MentorComplateListResult?.email}
                                                                                                                                </li>
                                                                                                                                <li className="me-2"><i className="ri-phone-line"></i>
                                                                                                                                    {MentorComplateListResult?.mobile}</li> */}
                                                                                                                                <li className="me-2"><i className="ri-map-pin-fill">
                                                                                                                                </i>{MentorComplateListResult?.city},
                                                                                                                                    {MentorComplateListResult?.state},
                                                                                                                                    {MentorComplateListResult?.country}</li>
                                                                                                                            </ul>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                                                <div>
                                                                                                                    <div> {MentorComplateListResult?.profession?.replace(/[{}"]/g, '')?.split(", ").map((professionResult, index) => (
                                                                                                                        <div className='d-inline text-dark'
                                                                                                                            key={index}>{professionResult}</div>
                                                                                                                    ))}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            filteredMentorList?.find(job => job.id === ActiveTabs) && (
                                                                                <>
                                                                                    <div className="col-md-8 col-lg-8">
                                                                                        <div className="tab-content" id="v-pills-tabContent">
                                                                                            <div className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                                                id={`v-pills-${ActiveTabs}`}
                                                                                                role="tabpanel"
                                                                                                aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                                                <div className="interviewstudent m-0">
                                                                                                    <h3>Mentor Books</h3>
                                                                                                    <div className="slider_descd">
                                                                                                        <div className="bg-light p-3">
                                                                                                            <div className="firsthiresd">
                                                                                                                <div className="card">
                                                                                                                    <div className="card-body p-0">
                                                                                                                        <div className="d-md-flex justify-content-between gap-2">
                                                                                                                            <div className="profileinterview position-relative p-3 d-md-flex gap-3">
                                                                                                                                {
                                                                                                                                    filteredMentorList?.find(job => job.id === ActiveTabs)?.profileimg ? (
                                                                                                                                        <>
                                                                                                                                            <img width="40" className="rounded-pill"
                                                                                                                                                height="40"
                                                                                                                                                src={filteredMentorList?.find(job => job.id === ActiveTabs)?.profileimg}
                                                                                                                                                onerror="src='../assets/images/img_avatar.png'" alt="" />
                                                                                                                                        </>
                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            <img width="40" className="rounded-pill"
                                                                                                                                                height="40"
                                                                                                                                                src={CommonImageTransport?.userLogo}
                                                                                                                                                onerror="src='../assets/images/img_avatar.png'" alt="" />
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                }

                                                                                                                                <div>Interviewer:-
                                                                                                                                    <h6 className="m-0">{filteredMentorList?.find(job => job.id === ActiveTabs)?.fname} {filteredMentorList?.find(job => job.id === ActiveTabs)?.lname}</h6>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="profileinterview position-relative p-3 text-end gap-3">
                                                                                                                                {
                                                                                                                                    !FeedbackList?.length && (
                                                                                                                                        <Link to="#" className="default-btn rounded"
                                                                                                                                            data-bs-toggle="modal" data-bs-target="#feedback"
                                                                                                                                            onClick={() => {
                                                                                                                                                handleShow()
                                                                                                                                                HandleMentorId(filteredMentorList?.find(job => job.id === ActiveTabs)?.mentor_userid)
                                                                                                                                            }}
                                                                                                                                        >Feedback Upload</Link>
                                                                                                                                    )
                                                                                                                                }

                                                                                                                            </div>
                                                                                                                            {/* <----------- Feedback upload modal section's -------------> */}
                                                                                                                            <Modal size='lg' show={show} onHide={()=>{handleClose()
                                                                                                                                setRating(0)
                                                                                                                                setComment("")
                                                                                                                            }}
                                                                                                                                className="modal fade" id="feedback" tabindex="-1"
                                                                                                                                aria-labelledby="exampleModalLabel"
                                                                                                                                aria-hidden="true">
                                                                                                                                <Modal.Header closeButton onClick={()=>{setRating(0)
                                                                                                                                setComment("")}}>
                                                                                                                                    <Modal.Title>Feedback</Modal.Title>
                                                                                                                                </Modal.Header>
                                                                                                                                <Modal.Body>
                                                                                                                                    <form method="post">
                                                                                                                                        <div className="modal-body">
                                                                                                                                            <div className="row">

                                                                                                                                                <div className="col-12 col-md-12">
                                                                                                                                                    <div className="form-group d-flex align-items-center gap-4">
                                                                                                                                                        <label>Star Ratting</label>
                                                                                                                                                        <div className="rate">
                                                                                                                                                            {[...Array(5)].map((_, index) => {
                                                                                                                                                                const starValue = 5 - index;
                                                                                                                                                                return (
                                                                                                                                                                    <label key={index}
                                                                                                                                                                        title={`${starValue} rating`}
                                                                                                                                                                        style={{ fontSize: "1.5rem" }}
                                                                                                                                                                        className={starValue <= rating ? 'star filled' : 'star'}
                                                                                                                                                                        onClick={() => setRating(starValue)}>
                                                                                                                                                                        ★
                                                                                                                                                                    </label>
                                                                                                                                                                );
                                                                                                                                                            })}
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>


                                                                                                                                                <div className="col-12 col-md-12">
                                                                                                                                                    <div className="form-group mb-2 myteamform">
                                                                                                                                                        <lable>Feedback</lable>
                                                                                                                                                        <textarea placeholder="Message" name='comment'
                                                                                                                                                            value={comment} onChange={(e) => setComment(e.target.value)}
                                                                                                                                                            className="form-control"></textarea>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="dd-footer d-flex gap-3 p-3 border-top">
                                                                                                                                            <button type="button" className="btn btn-secondary w-100"
                                                                                                                                                data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                                                                                                                            <button type="button" onClick={HandleFeedBackForm}
                                                                                                                                                className="btn default-btn w-100">Submit</button>
                                                                                                                                        </div>
                                                                                                                                    </form>
                                                                                                                                </Modal.Body>
                                                                                                                            </Modal>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts p-3 border-top">
                                                                                                                            <h6 className="mb-0">Session Book time & date</h6>
                                                                                                                            <div className="skillstopic">
                                                                                                                                <div><span
                                                                                                                                    className="fw-bold text-primary">Day:</span>{filteredMentorList?.find(job => job.id === ActiveTabs)?.booking_day}
                                                                                                                                </div>
                                                                                                                                <div><span
                                                                                                                                    className="fw-bold text-primary">Date:</span>{moment(filteredMentorList?.find(job => job.id === ActiveTabs)?.bookig_date).format("MM/DD/YYYY")}
                                                                                                                                </div>
                                                                                                                                <div><span
                                                                                                                                    className="fw-bold text-primary">Time:</span> {filteredMentorList?.find(job => job.id === ActiveTabs)?.slot_time
                                                                                                                                        ?.replace(/[{}"\\]/g, '')
                                                                                                                                        ?.split(/,\s*/)
                                                                                                                                        ?.map((skill, index, array) => (
                                                                                                                                            <span key={index}>
                                                                                                                                                {skill.trim()}
                                                                                                                                                {index < array.length - 1 && ', '}
                                                                                                                                            </span>
                                                                                                                                        ))}
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="float-starts border-top">
                                                                                                                            <div className="card card-body p-0">
                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                    <strong>Recorded Link:</strong><a className='text-primary' target="_blank" href={filteredMentorList?.find(job => job.id === ActiveTabs)?.recorded_link}>{MentorCompleteList?.find(job => job.id === ActiveTabs)?.recorded_link}</a>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts border-top">
                                                                                                                            {
                                                                                                                                FeedbackList?.map((FeedbackListResult) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <div className="card card-body p-0">
                                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                                    <h6 className="mb-0">Star Ratting</h6>
                                                                                                                                                    <div className="reviewsreat">
                                                                                                                                                        <div className="rate">
                                                                                                                                                            <Rating readonly={true} initialValue={FeedbackListResult?.rating} />
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <h6 className="mb-0">Feedback</h6>
                                                                                                                                                    <p className="small">{FeedbackListResult?.comment}</p>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                        <div className="float-starts border-top">
                                                                                                                            <div className="card card-body p-0">
                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                    <h6 className="mb-0">Notes</h6>
                                                                                                                                    <p>{MentorCompleteList?.find(job => job.id === ActiveTabs)?.comment}</p>
                                                                                                                                    {/* <div className="border rounded mt-3 p-3"
                                                                                                                data-bs-toggle="modal"
                                                                                                                data-bs-target="#pdfnots">
                                                                                                                <img src={CommonImageTransport?.pdfpdf}
                                                                                                                    width="100" alt="" />
                                                                                                                <h6>UI/UX Design</h6>

                                                                                                            </div> */}
                                                                                                            {
                                                                                                                MentorCompleteList?.find(job => job.id === ActiveTabs)?.metting_file != null && (
                                                                                                                    <button className="btn btn-gray">PDF:<a className='text-primary' target="_blank" href={MentorCompleteList?.find(job => job.id === ActiveTabs)?.metting_file}>{MentorCompleteList?.find(job => job.id === ActiveTabs)?.metting_file}</a></button>

                                                                                                                )
                                                                                                            }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="tab-pane fade" id="v-pills-profile3" role="tabpanel"
                                                                                                aria-labelledby="v-pills-profile-tab">
                                                                                                <div className="interviewstudent m-0">
                                                                                                    <h3>Mentor Books</h3>
                                                                                                    <div className="slider_descd">
                                                                                                        <div className="bg-light p-3">
                                                                                                            <div className="firsthiresd">
                                                                                                                <div className="card">
                                                                                                                    <div className="card-body p-0">
                                                                                                                        <div
                                                                                                                            className="profileinterview position-relative p-3 d-md-flex gap-3">
                                                                                                                            <img width="40" className="rounded-pill" height="40"
                                                                                                                                src={CommonImageTransport?.profile1}
                                                                                                                                onerror="src='../assets/images/img_avatar.png'"
                                                                                                                                alt="" />
                                                                                                                            <div>Interviewer:-
                                                                                                                                <h6 className="m-0">Rahul Sharma</h6>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts p-3 border-top">
                                                                                                                            <h6 className="mb-0">Section Book time date</h6>
                                                                                                                            <div className="skillstopic">
                                                                                                                                <div><span
                                                                                                                                    className="fw-bold text-primary">Date:</span>10/05/2024
                                                                                                                                </div>
                                                                                                                                <div><span
                                                                                                                                    className="fw-bold text-primary">Time:</span>11:00am
                                                                                                                                    to 12:00am
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts border-top">
                                                                                                                            <div className="card card-body p-0">
                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                    <h6 className="mb-0">Meeting link</h6>
                                                                                                                                    <p className="text-primary">
                                                                                                                                        https://meet.google.com/odp-nvra-ycf
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts border-top">
                                                                                                                            <div className="card card-body p-0">
                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                    <h6 className="mb-0">Recorded link</h6>
                                                                                                                                    <p className="text-primary">
                                                                                                                                        https://meet.google.com/odp-nvra-ycf
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="float-starts border-top">
                                                                                                                            <div className="card card-body p-0">
                                                                                                                                <div className="header0101 p-3 py-3">
                                                                                                                                    <h6 className="mb-0">Notes</h6>
                                                                                                                                    <div className="border rounded mt-3 p-3"
                                                                                                                                        data-bs-toggle="modal"
                                                                                                                                        data-bs-target="#pdfnots">
                                                                                                                                        <img src={CommonImageTransport?.pdfpdf}
                                                                                                                                            width="100" alt="" />
                                                                                                                                        <h6>UI/UX Design</h6>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='text-center mt-5'>
                                                    <h2>Not Avaliable Any Mentor Completed Session here...</h2>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <Student_Footer />
        </div>
    )
}

export default Student_Mentor_Complate_session