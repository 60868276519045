import React, { useState, useEffect, useRef } from "react";

const TypewriterHtmlAnimation = ({ htmlContent, onAnimationComplete }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index
  const scrollRef = useRef(null);
  const typingSpeed = 100;

  useEffect(() => {
    if (!htmlContent) return;

    // Split content by tags or individual words with spaces
    const htmlChunks = htmlContent.match(/<[^>]+>|[^<\s]+|\s+/g);

    const typeNextChunk = () => {
      if (currentIndex < htmlChunks.length) {
        setDisplayText((prev) => prev + htmlChunks[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);

        // Auto-scroll functionality
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      } else {
        if (onAnimationComplete) onAnimationComplete(); // Callback when complete
        return;
      }
    };

    const interval = setInterval(typeNextChunk, typingSpeed);

    return () => clearInterval(interval); // Clean up on unmount
  }, [htmlContent, currentIndex, onAnimationComplete]);

  return (
    <div
      ref={scrollRef}
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        fontSize: "1em",
        lineHeight: "1.4", // Refined line height for controlled spacing
        wordSpacing: "0.1em", // Optional: Adjust word spacing for clarity
        whiteSpace: "pre-wrap", // Preserve spaces without extra gaps
      }}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default TypewriterHtmlAnimation;
