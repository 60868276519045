import React, { useState, useEffect } from 'react';
import CommonImageTransport from '../../../common/images/CommonImages';
import Student_Header from '../student_header/Student_Header';
import { GetStudentJobsList, StudentJobApply } from '../../../../api/Student';
import ReactHtmlParser from "react-html-parser";
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Typewriter from 'typewriter-effect';
import Loader from '../../../../loader/Loader';
import Student_Footer from '../student_footer/Student_Footer';
import { FaMoneyBill1Wave } from "react-icons/fa6";

const Student_jobs = () => {
    const navigate = useNavigate();
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [is_Loading, setIs_Loading] = useState(false)
    const [JobList, setJobList] = useState([]);
    const [ActiveTabs, setActiveTabs] = useState(JobList?.id);
    const token = localStorage.getItem("User_Token");
    const Recuriter_id = localStorage.getItem("User_id");
    const [recruiter_userid, setRecruiter_userid] = useState("")

    // <------ User's Authorization token's ----------->
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    // <--------- User Active & UnActive ----------->
    const HandleActiveTabs = (tabId) => {
        setActiveTabs(tabId);
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    

    // <---------- All Jobs -------->
    const GetJobList = async () => {
        setIs_Loading(true)
        try {
            const response = await GetStudentJobsList(headers);
            setJobList(response?.data?.data)
            setRecruiter_userid(response?.data?.data[0]?.user_id)
            setActiveTabs()
            setIs_Loading(false)
        } catch (error) {
            setIs_Loading(false)
        }
    }
    console.log("JobList", JobList)
    // <---------- get the Recuriter id ------------>
    const HandleRecuriterId = (id) => {
        setRecruiter_userid(id)
    }
    // <--------- Student Job Apply ------------------->
    const HandleStudentJobApply = async () => {
        const StudentJobApplyData = {
            job_id: ActiveTabs,
            recruiter_userid
        }
        const response = await StudentJobApply(StudentJobApplyData, headers);
        if (response?.data?.statusCode == "200") {
            toast.success("Job Applied successfully.!!!")
            setTimeout(() => {
                navigate("/Student/Student_Applied_Job")
            }, 2000)
        }

        else if (response?.data?.msg == "First complete a course, after apply on job.") {
            toast.error("First complete a course, after apply on job..!!!")
        }

    }
    // <---------- Get the Job list --------->
    useEffect(() => {
        GetJobList();
    }, [])
    // <---------- Show the bydefault First Jobs  --------->



    const filteredList = JobList?.filter(item =>
        item?.title?.toLowerCase().includes(searchTerm) ||
        item?.location?.toLowerCase().includes(searchTerm) ||
        item?.organization?.toLowerCase().includes(searchTerm) ||
        item?.unique_id?.toLowerCase().includes(searchTerm) || 
        item?.employmenttype?.toLowerCase().includes(searchTerm)  ||
        item?.expertise?.toLowerCase().includes(searchTerm) ||
        item?.currencytype?.toLowerCase().includes(searchTerm)  
        // item?.salarypackage?.toLowerCase().includes(searchTerm) 
    );
    console.log("filteredList", filteredList)

 // Automatically update ActiveTabs when the filteredList changes
useEffect(() => {
    if (filteredList?.length > 0 && (!ActiveTabs || !filteredList?.some(job => job.id === ActiveTabs))) {
        const firstActiveTab = filteredList[0]; 
        if (firstActiveTab) {
            setActiveTabs(firstActiveTab?.id); 
        }
    }
}, [filteredList, ActiveTabs]); 

console.log("Active Tab:", ActiveTabs);
    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Student_Header />
                        </div>
                        <div className="header_space"></div>
                        <div className="recruiter_list pb-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        <ul className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2">
                                            <li className="nav-item">
                                                <Link className="nav-link active" to="/Student/Student_jobs">All Jobs</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link " to="/Student/Student_Applied_Job">Applied</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <ToastContainer style={{ marginTop: "120px" }} />
                                        <form>
                                            <div className="searchlocation">
                                                <div className="iconfield">
                                                    <div className="icon_search">
                                                        <img src={CommonImageTransport?.searchicon} alt="" />
                                                    </div>
                                                    {!showSearchFields ? (
                                                        <div
                                                            onClick={() => setShowSearchFields(true)}>
                                                            <Typewriter
                                                                options={{
                                                                    loop: true,
                                                                }}
                                                                onInit={(typewriter) => {
                                                                    typewriter.typeString('Search by title')
                                                                        .callFunction(() => {
                                                                        })
                                                                        .pauseFor(2500)
                                                                        .deleteAll()
                                                                        .callFunction(() => {
                                                                        })
                                                                        .typeString('Search by location')
                                                                        .pauseFor(2500)
                                                                        .start();
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <input type="text" onChange={handleSearchChange}
                                                                placeholder=""
                                                                style={{ border: "none", outline: "none" }} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="iconfield">
                                                    <div className="icon_search">
                                                        <img src={CommonImageTransport?.locationicon} alt="" />
                                                    </div>
                                                    <div className="input-field">
                                                        <input type="text" onChange={handleSearchChange} placeholder="USA, Florida" className="form-control"
                                                            required="" />
                                                    </div>
                                                </div>
                                                <div className="input-field ad121">
                                                    <button type="buton" onClick={handleSearchChange}
                                                        className="submit_btn">Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {
                                    is_Loading ? (
                                        <>
                                            <Loader />
                                        </>

                                    ) : (
                                        <>
                                            <div className="left_recruiterlists row">
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="sticky-header">
                                                        <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                            aria-orientation="vertical">
                                                            {
                                                                filteredList?.map((JobListResult, index) => {
                                                                    if (JobListResult?.status == "0") {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <div key={index} className={`nav-link ${ActiveTabs === JobListResult?.id ?
                                                                                "active" : ""}`}
                                                                                onClick={() => {
                                                                                    HandleActiveTabs(JobListResult?.id)
                                                                                    HandleRecuriterId(JobListResult?.user_id)
                                                                                }}
                                                                                id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                data-bs-target="#v-pills-home" type="button"
                                                                                role="tab" aria-controls="v-pills-home"
                                                                                aria-selected="true">
                                                                                <div className="card_rec position-relative">
                                                                                    <div className="mentersvies0121">
                                                                                        <div>
                                                                                            {
                                                                                                JobListResult?.job_image != null ? (
                                                                                                    <>
                                                                                                        <img src={JobListResult?.job_image}
                                                                                                            className="img-fluid rounded" alt="Instructor" />
                                                                                                    </>

                                                                                                ) : (
                                                                                                    <>
                                                                                                        <img src={CommonImageTransport?.companybuilding}
                                                                                                            className="img-fluid rounded" alt="Instructor" />
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                        <div className="instructors-details-contents">
                                                                                            <div className="comnam">
                                                                                                <h3>{JobListResult?.title} </h3>
                                                                                                <h6 className="sub-title">{JobListResult?.organization}</h6>
                                                                                                <h6 className="sub-title">Job Id:{JobListResult?.unique_id}</h6>
                                                                                            </div>
                                                                                            <p className="perographsv">
                                                                                                <i className="ri-map-pin-fill"></i> {JobListResult?.location}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <div className="skillstopic">
                                                                                            <div>{JobListResult?.employmenttype}</div>
                                                                                        </div>
                                                                                        <div className="skillstopic">
                                                                                            <div>{JobListResult?.expertise}</div>
                                                                                        </div>
                                                                                        <div className="skillstopic">
                                                                                            {
                                                                                                JobListResult?.salarypackage != "0" && (
                                                                                                    <div><strong>{JobListResult?.currencytype}:</strong>{JobListResult?.salarypackage}</div>
                                                                                                )
                                                                                            }

                                                                              
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {filteredList?.find(job => job.id === ActiveTabs)?.status == "1" && (
                                                    <div className='col-md-8 col-lg-8 col-xl-8'>
                                                        {ActiveTabs && (
                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                <div
                                                                    className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                    id={`v-pills-${ActiveTabs}`}
                                                                    role="tabpanel"
                                                                    aria-labelledby={`v-pills-${ActiveTabs}-tab`}
                                                                >
                                                                    <div className="instructors-details-img m-0">
                                                                        <div className="card-instrutors shadow position-relative bg-white p-4">
                                                                            <div className="mentersvies0121 recruiter101s">
                                                                                <div>
                                                                                    {
                                                                                        filteredList?.find(job => job.id === ActiveTabs)?.job_image != null ? (
                                                                                            <>
                                                                                                <img src={filteredList?.find(job => job.id === ActiveTabs)?.job_image}
                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                <img src={CommonImageTransport?.companybuilding}
                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className="instructors-details-contents">
                                                                                    <h3>{filteredList?.find(job => job.id === ActiveTabs)?.title}</h3>
                                                                                    <p>
                                                                                        <Link to="#">
                                                                                            <i className="ri-community-line"></i> {filteredList?.find(job => job.id === ActiveTabs)?.organization}
                                                                                        </Link>
                                                                                    </p>
                                                                                    <h6 className="sub-title">Job Id:{filteredList?.find(job => job.id === ActiveTabs)?.unique_id}</h6>
                                                                                    <p><i className="ri-map-pin-fill"></i> {filteredList?.find(job => job.id === ActiveTabs)?.location}</p>
                                                                                    <div className='d-flex justify-content-around align-items-center'>
                                                                                        <p className="small m-0 fw-bold">
                                                                                            <span className="fw-normal">Application Start :</span>
                                                                                            {moment(filteredList?.find(job => job.id === ActiveTabs)?.startdate).format('MM/DD/YYYY')}
                                                                                        </p>
                                                                                        <p className="small m-0 fw-bold">
                                                                                            <span className="fw-normal">Application End :</span>
                                                                                            {moment(filteredList?.find(job => job.id === ActiveTabs)?.enddate).format('MM/DD/YYYY')}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="footerapplly border-top d-flex justify-content-between mt-3 pt-2">

                                                                                {filteredList?.find(job => job.id === ActiveTabs)?.isApplied == true ? (
                                                                                    <Link to="#"
                                                                                        className="btn btn-success rounded ">Applied ✓</Link>
                                                                                ) : (
                                                                                    <Link to="#" onClick={HandleStudentJobApply}
                                                                                        className="default-btn rounded">Apply</Link>
                                                                                )}
                                                                              {
                                                                                filteredList?.find(job => job.id === ActiveTabs)?.salarypackage != "0" && (
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                    <div className="bg-light p-2"><FaMoneyBill1Wave /></div>
                                                                                    <div>
                                                                                        <p className="small m-0 fw-bold">
                                                                                            {filteredList?.find(job => job.id === ActiveTabs)?.currencytype} : {filteredList?.find(job => job.id === ActiveTabs)?.salarypackage}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                )
                                                                              }

                                                                               
                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                    <div className="bg-light p-2"><i className="ri-eye-line"></i></div>
                                                                                    <div>
                                                                                        <p className="small m-0 fw-bold">
                                                                                            <span className="fw-normal">Job Type:</span> {filteredList?.find(job => job.id === ActiveTabs)?.employmenttype}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                    <div className="bg-light p-2"><i className="ri-user-line"></i></div>
                                                                                    <div>
                                                                                        <p className="small m-0 fw-bold">
                                                                                            <span className="fw-normal">No of Openings:</span> {filteredList?.find(job => job.id === ActiveTabs)?.postion_no}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="d-flex gap-2 align-items-center">
                                                                    <div className="bg-light p-2"><i className="ri-calendar-2-line"></i></div>
                                                                    <div>
                                                                        <p className="small m-0 fw-bold">Impressions</p>
                                                                        <span className="small text-muted">363</span>
                                                                    </div>
                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="100" className="scrollspy-example" tabIndex="0">
                                                                        <div id="scrollspyHeading1" className="instructors-details-contents mt-3">
                                                                        <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Skills</h5>
                                                                                {filteredList?.find(job => job.id === ActiveTabs)?.skills}
                                                                            </div>
                                                                            <br/>
                                                                            <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Job Description</h5>
                                                                                {ReactHtmlParser(filteredList.find(job => job.id === ActiveTabs)?.aboutjob)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </>
                )
            }
     <Student_Footer/>
        </div>
    )
}

export default Student_jobs