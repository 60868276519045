import React, { useState, useEffect } from "react";
import Student_Header from "../student_header/Student_Header";
import Typewriter from "typewriter-effect";
import CommonImageTransport from "../../../common/images/CommonImages";
import Datetime from "react-datetime";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import {
  BookMentorSlot,
  GetSloatMentorList,
  GetMentorSlot,
  GetStudentmentorbookinglist,
} from "../../../../api/Mentor";
import { Link } from "react-router-dom";
import RazorpayPaymentButton from "../../../../api/payment/Razorpay_Payment_Button";
import Loader from "../../../../loader/Loader";
import { ProfileSections } from "../../../../api/Auth";
import { TimeZoneMappings } from "../../../common/timezone/TimeZone";
import Student_Footer from "../student_footer/Student_Footer";

const Student_Mentor_List = () => {
  const [is_Loading, setIs_Loading] = useState(false);
  const [Active, setActive] = useState(0);
  const [AllMentorList, setAllMentorList] = useState([]);
  console.log("AllMentorList", AllMentorList);
  const [MentorListById, setMentorListById] = useState("");
  const [MentorSlotList, setMentorSlotList] = useState([]);
  const [MentorBookList, setMentorBookList] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [slotBooked, setSlotBooked] = useState(false);
  const [slot_time, setSlot_time] = useState([]);
  const [bookig_date, setBookig_date] = useState("");
  const [dayName, setDayName] = useState("");
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMentorList, setFilteredMentorList] = useState([]);
  const [ActiveTabs, setActiveTabs] = useState(AllMentorList?.id);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isDateInvalid, setIsDateInvalid] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [GetUserProfile, SetGetUserProfile] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const user_id = localStorage.getItem("User_id");
  const user_Login_Data = JSON.parse(localStorage.getItem("user_Login_Data"));
  const Student_Login_time_zone = user_Login_Data?.time_zone;
  console.log("user_Login_Data", Student_Login_time_zone);
  const today = moment().format("YYYY-MM-DD");
  const defaultTabIndex = 0;

  const [isCalendarOpen, setIsCalendarOpen] = useState(true);

  // <-------- User Authntication token's --------------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    // If searchQuery is cleared, reset the filteredMentorList to the original list
    if (searchQuery.trim() === "") {
      setFilteredMentorList(AllMentorList);
    } else {
      const searchTerms = searchQuery
        .trim()
        .toLowerCase()
        .split(/[\s,]+/);

      const filtered = AllMentorList?.filter((MentorListResult) => {
        const fname = MentorListResult?.fname?.toLowerCase();
        const lname = MentorListResult?.lname?.toLowerCase();
        const fullName = `${fname} ${lname}`;

        // Check if the search query matches the full name exactly
        const matchesFullName =
          searchTerms.length > 1 &&
          fullName.includes(searchQuery.toLowerCase());

        // Check if the first name or last name matches any of the search terms
        const matchesFirstName =
          searchTerms.length === 1 && fname.includes(searchTerms[0]);
        const matchesLastName =
          searchTerms.length === 1 && lname.includes(searchTerms[0]);

        // Check if any of the search terms match city, state, or country
        const matchesCityStateCountry = searchTerms.some(
          (term) =>
            MentorListResult?.city?.toLowerCase().includes(term) ||
            MentorListResult?.state?.toLowerCase().includes(term) ||
            MentorListResult?.country?.toLowerCase().includes(term)
        );

        return (
          matchesFullName ||
          matchesFirstName ||
          matchesLastName ||
          matchesCityStateCountry ||
          MentorListResult?.profession
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          MentorListResult?.user_type
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });

      setFilteredMentorList(filtered);
    }
  }, [searchQuery, AllMentorList]);

  const HandleActiveTabs = (tabs) => {
    setActiveTabs(tabs);
  };

  const handleDateChange = (date) => {
    if (!date) return;

    // Parse date and remove time information completely
    const momentDate = moment(date).startOf("day"); // Ensure we only deal with the date part

    // Validate against the current date
    if (momentDate.isValid() && momentDate.isSameOrAfter(moment().startOf("day"))) {
      const newDayName = momentDate.format("dddd"); // Get day name
      const newDateFormatted = momentDate.format("YYYY-MM-DD"); // Format date as YYYY-MM-DD

      setSelectedDates([
        ...selectedDates,
        { date: newDateFormatted, dayName: newDayName }, // Store date and day
      ]);
      setDayName(newDayName);
      setBookig_date(newDateFormatted); // Save formatted date
      setSlot_time([]);
      setSelectedSlots([]);
      setDataFetched(false);
      setIsDateInvalid(false);
      HandleGetMentorSlot(newDayName);
    } else {
      setSlot_time([]);
      setSelectedSlots([]);
      setIsDateInvalid(true);
    }
  };



  // <------------ Mentor List ----------------->
  const HandleGetMentorList = async () => {
    setIs_Loading(true);
    try {
      const response = await GetSloatMentorList(headers);
      setAllMentorList(response?.data?.data?.mentor_list);
      setMentorBookList(response?.data?.data?.mentor_list[0]?.isBooking);
      setIs_Loading(false);
    } catch (error) {
      setIs_Loading(true);
    }
  };

  // <--------  Get Mentor List By Id---------------->
  const GetMentorListById = (id) => {
    setMentorListById(id);
  };

  const getFullTimeZoneName = (abbreviation) => {
    return TimeZoneMappings[abbreviation] || abbreviation;
  };

  // <---------- get Mentor Slot ---------------->
  const HandleGetMentorSlot = async (newDayName) => {
    const GetMentorSlotData = {
      mentor_userid: MentorListById,
      day: newDayName,
      is_student: true,
    };
    console.log("GetMentorSlotData", GetMentorSlotData);

    try {
      const response = await GetMentorSlot(GetMentorSlotData, headers);
      const slots = response?.data?.data || [];
      console.log("Original Mentor Slots", slots);

      // Retrieve and map time zones dynamically
      const mentorTimeZone = getFullTimeZoneName(ActiveTimeZone); // e.g., 'Asia/Kolkata'
      const studentTimeZone = getFullTimeZoneName(GetUserProfile?.time_zone); // Default to UTC if not available

      console.log("Mentor Time Zone:", mentorTimeZone);
      console.log("Student Time Zone:", studentTimeZone);

      const convertedSlots = slots?.map((slot) => {
        // Extract and clean up time range
        const [startTime, endTime] = slot.booking_time
          .split("To")
          ?.map((time) => time.trim().replace(/(IST|UTC)/, ""));
        const timeFormat = "hh:mma";

        console.log("Parsed Start Time:", startTime);
        console.log("Parsed End Time:", endTime);

        // Parse times in mentor's time zone
        const startMentorTime = moment.tz(
          startTime,
          timeFormat,
          mentorTimeZone
        );
        const endMentorTime = moment.tz(endTime, timeFormat, mentorTimeZone);

        console.log(
          "Start Time (Mentor's TZ):",
          startMentorTime.format("hh:mm A")
        );
        console.log("End Time (Mentor's TZ):", endMentorTime.format("hh:mm A"));

        // Convert to student's time zone
        const startStudentTime = startMentorTime
          .clone()
          .tz(studentTimeZone)
          .format("hh:mm A");
        const endStudentTime = endMentorTime
          .clone()
          .tz(studentTimeZone)
          .format("hh:mm A");

        console.log("Converted Start Time (Student's TZ):", startStudentTime);
        console.log("Converted End Time (Student's TZ):", endStudentTime);

        return {
          ...slot,
          booking_time: `${startStudentTime} To ${endStudentTime}`,
        };
      });

      setMentorSlotList(convertedSlots);
      setSlotBooked(true);
    } catch (error) {
      console.error("Error fetching or converting mentor slots:", error);
      setSlotBooked(false);
      setMentorSlotList([]);
    }
    setDataFetched(true);
  };
  console.log("HandleGetMentorSlot", MentorSlotList);

  // <----------- Get Student Mentor Booking list------------>
  const GetHandleAllmentorbookinglist = async () => {
    const HandleStudentmentorbookinglistData = {
      status: 1,
    };
    const response = await GetStudentmentorbookinglist(
      HandleStudentmentorbookinglistData,
      headers
    );
    setMentorBookList(response?.data?.data?.booking);
  };

  const handleTimeSlotClick = (timeSlot) => {
    setSlot_time((prevSlots) => {
      const newSlots = prevSlots.includes(timeSlot)
        ? prevSlots.filter((slot) => slot !== timeSlot)
        : [...prevSlots, timeSlot];
      if (newSlots.length > 0) {
        const newTotalPrice = Math.round(newSlots.length * perHourPrice * 100);
        setTotalPrice(newTotalPrice);
      } else {
        setTotalPrice(0);
      }

      return newSlots;
    });
  };

  console.log("handleTimeSlotClick", slot_time);

  const handlePaymentSuccess = (details) => {
    setPaymentDetails(details);
    HandleBookMentors(details);
    setTimeout(() => {
      HandleGetMentorList();
      setMentorSlotList([])
      setDataFetched(false)
      setSlot_time([])
    }, 2000)

    // window.location.reload()
  };

  // <--------- Book Mentors ------------->
  const HandleBookMentors = async ({ paymentId, payment_status, amount }) => {
    const MentorSlotBookData = {
      slot_time,
      booking_day: dayName,
      mentor_userid: MentorListById,
      bookig_date,
      payment_status,
      paymentId,
      amount,
      slot_time_zone: Student_Login_time_zone,
    };
    const response = await BookMentorSlot(MentorSlotBookData, headers);
    if (response?.data?.statusCode == "200") {
      toast.success("Booking updated successfully.");
      setTimeout(() => {
        setShow(false);
        // HandleGetMentorList();
      }, 2000);
    }
  };

  const BookedStatus = () => {
    toast.warn("Mentor Already Booked ,Please Check the Upcoming sessions !!!");
    alert("Mentor Already Booked ,Please Check the Upcoming sessions !!!");
  };
  useEffect(() => {
    HandleGetMentorList();
    GetMentorListById();
    GetHandleAllmentorbookinglist();
  }, []);

  // <---------- Mentor time Zone ------------>
  const ActiveTimeZone = AllMentorList?.find(
    (job) => job.id === ActiveTabs
  )?.time_zone;

  console.log("ActiveTimeZone", ActiveTimeZone);

  const ProfileSections1 = async () => {
    const response = await ProfileSections({ user_id, headers });
    SetGetUserProfile(response?.data?.data?.profile);
  };
  useEffect(() => {
    ProfileSections1();
  }, []);

  useEffect(() => {
    if (show) {
      setIsCalendarOpen(true); // Open calendar when modal is shown
    }
  }, [show]);
  console.log("ProfileSections1", GetUserProfile);
  const Student_Time_Zone = GetUserProfile?.time_zone;
  console.log("Student_Time_Zone", Student_Time_Zone);

  const perHourPrice = filteredMentorList?.find(
    (job) => job.id === ActiveTabs
  )?.per_hour_price;

  // Automatically update ActiveTabs when the filteredList changes
  useEffect(() => {
    if (
      filteredMentorList?.length > 0 &&
      (!ActiveTabs || !filteredMentorList?.some((job) => job.id === ActiveTabs))
    ) {
      const firstActiveTab = filteredMentorList[0];
      if (firstActiveTab) {
        HandleActiveTabs(firstActiveTab?.id);
      }
    }
  }, [filteredMentorList, ActiveTabs]);

  return (
    <div>
      {is_Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="bg_nav-slid bg-white">
            <Student_Header />
          </div>

          <div className="header_space"></div>
          <div className="recruiter_list pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 my-2">
                  <ul
                    className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <Link
                        className="nav-link active"
                        to="/Student/Student_Mentor_List"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        All Mentors
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link
                        className="nav-link"
                        to="/Student/Student_Upcoming_Mentor_list"
                        id="pills-disabled-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-Upcoming"
                        type="button"
                        role="tab"
                        aria-controls="pills-Upcoming"
                        aria-selected="false"
                      >
                        Upcoming Session
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link
                        className="nav-link"
                        to="/Student/Student_Mentor_Complate_session"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Complete Session
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <form>
                    <div className="searchlocation">
                      <div className="iconfield">
                        <div className="icon_search">
                          <img src={CommonImageTransport?.searchicon} alt="" />
                        </div>

                        {!showSearchFields ? (
                          <div onClick={() => setShowSearchFields(true)}>
                            <Typewriter
                              options={{
                                loop: true,
                              }}
                              onInit={(typewriter) => {
                                typewriter
                                  .typeString("Search by title")
                                  .callFunction(() => { })
                                  .pauseFor(2500)
                                  .deleteAll()
                                  .callFunction(() => { })
                                  .typeString("Search by location")
                                  .pauseFor(2500)
                                  .start();
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              onChange={handleSearchInputChange}
                              placeholder=""
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                padding: "10px",
                                boxSizing: "border-box",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="iconfield">
                        <div className="icon_search">
                          <img
                            src={CommonImageTransport?.locationicon}
                            alt=""
                          />
                        </div>
                        <div className="input-field">
                          <input
                            type="text"
                            placeholder="USA, Florida"
                            onChange={handleSearchInputChange}
                            className="form-control"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="input-field ad121">
                        <button type="button" className="submit_btn">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {AllMentorList && AllMentorList?.length > 0 ? (
                <>
                  {is_Loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      <div className="tab-content" id="pills-tabContent">
                        {/* <---------- All Mentor Profile --------------> */}
                        <div
                          className={`tab-pane fade ${Active === 0 ? "show active" : ""
                            }`}
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          tabindex="0"
                        >
                          <div className="left_recruiterlists row">
                            <div className="col-md-4 col-lg-4">
                              <div className="sticky-header">
                                <div
                                  className="nav flex-column recruiter_all_lists nav-pills"
                                  id="v-pills-tab"
                                  role="tablist"
                                  aria-orientation="vertical"
                                >
                                  {filteredMentorList?.map(
                                    (MentorListResult, index) => {

                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`nav-link ${ActiveTabs ===
                                              MentorListResult?.id
                                              ? "active"
                                              : ""
                                              }`}
                                            onClick={() =>
                                              HandleActiveTabs(
                                                MentorListResult?.id
                                              )
                                            }
                                            id="v-pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#v-pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="v-pills-home"
                                            aria-selected="true"
                                          >
                                            <div className="card_rec position-relative">
                                              <div className="mentersvies0121">
                                                <div className="position-relative">
                                                  {MentorListResult?.profileimg !=
                                                    null ? (
                                                    <img
                                                      src={
                                                        MentorListResult?.profileimg
                                                      }
                                                      className="img-fluid rounded"
                                                      alt="Instructor"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        CommonImageTransport?.userLogo
                                                      }
                                                      style={{
                                                        width: "110px",
                                                        height: "110px",
                                                      }}
                                                      className="img-fluid rounded"
                                                      alt="Instructor"
                                                    />
                                                  )}

                                                  <span className="live_user">
                                                    Available
                                                  </span>
                                                </div>
                                                <div className="instructors-details-contents">
                                                  <div className="comnam">
                                                    <h3>
                                                      {MentorListResult?.fname}{" "}
                                                      {MentorListResult?.lname}
                                                    </h3>
                                                    <ul className="nav mentor4541s text-dark align-items-center">
                                                      <li className="me-2">
                                                        <i className="ri-map-pin-fill"></i>{" "}
                                                        {MentorListResult?.city}
                                                        ,{" "}
                                                        {
                                                          MentorListResult?.state
                                                        }
                                                        ,{" "}
                                                        {
                                                          MentorListResult?.country
                                                        }
                                                      </li>
                                                    </ul>
                                                    <h6>
                                                      {
                                                        MentorListResult?.user_type
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                  {MentorListResult?.profession
                                                    ?.replace(/[{}"]/g, "")
                                                    ?.split(", ")
                                                    ?.map((skill, index) => (
                                                      <div
                                                        className="d-inline text-dark"
                                                        key={index}
                                                      >
                                                        {skill}
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            {filteredMentorList?.find(
                              (job) => job.id === ActiveTabs
                            ) && (
                                <div className="col-md-8 col-lg-8">
                                  <div
                                    className="tab-content"
                                    id="v-pills-tabContent"
                                  >
                                    <div
                                      className={`tab-pane fade ${ActiveTabs ? "show active" : ""
                                        }`}
                                      id={`v-pills-${ActiveTabs}`}
                                      role="tabpanel"
                                      aria-labelledby={`v-pills-${ActiveTabs}-tab`}
                                    >
                                      <div className="instructors-details-img m-0">
                                        <div className="card-instrutors shadow position-relative bg-white p-4">
                                          <div className="mentersvies0121">
                                            <div className="position-relative menter_pro">
                                              {filteredMentorList?.find(
                                                (job) => job.id === ActiveTabs
                                              )?.profileimg != null ? (
                                                <>
                                                  <img
                                                    src={
                                                      filteredMentorList?.find(
                                                        (job) =>
                                                          job.id === ActiveTabs
                                                      )?.profileimg
                                                    }
                                                    className="rounded_01top"
                                                    alt="Instructor"
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src={
                                                      CommonImageTransport?.userLogo
                                                    }
                                                    className="rounded_01top"
                                                    alt="Instructor"
                                                  />
                                                </>
                                              )}

                                              <span className="live_user">
                                                Available
                                              </span>
                                            </div>

                                            <div className="instructors-details-contents">
                                              <div className="">
                                                <div className="comnam">
                                                  <h3>
                                                    {
                                                      filteredMentorList?.find(
                                                        (job) =>
                                                          job.id === ActiveTabs
                                                      )?.fname
                                                    }{" "}
                                                    {
                                                      filteredMentorList?.find(
                                                        (job) =>
                                                          job.id === ActiveTabs
                                                      )?.lname
                                                    }
                                                  </h3>
                                                  <ul className="nav my-2 hkddda justify-content-between text-dark align-items-center">
                                                    {/* <li className="me-2"><i className="ri-mail-line"></i>{AllMentorList[ActiveTabs]?.email}
                                    </li> */}
                                                    {/* <li className="me-2"><i className="ri-phone-line"></i>{AllMentorList[ActiveTabs]?.mobile}</li> */}
                                                    <li className="me-2">
                                                      <i className="ri-map-pin-fill"></i>
                                                      {
                                                        filteredMentorList?.find(
                                                          (job) =>
                                                            job.id === ActiveTabs
                                                        )?.city
                                                      }
                                                      ,{" "}
                                                      {
                                                        filteredMentorList?.find(
                                                          (job) =>
                                                            job.id === ActiveTabs
                                                        )?.state
                                                      }
                                                      ,{" "}
                                                      {
                                                        filteredMentorList?.find(
                                                          (job) =>
                                                            job.id === ActiveTabs
                                                        )?.country
                                                      }
                                                    </li>
                                                  </ul>

                                                  <div className="d-flex align-items-center justify-content-between">
                                                    <div className="reviewsreat mb-3">
                                                      <h6>
                                                        (
                                                        {
                                                          filteredMentorList?.find(
                                                            (job) =>
                                                              job.id ===
                                                              ActiveTabs
                                                          )?.user_type
                                                        }
                                                        )
                                                      </h6>
                                                    </div>

                                                    <div className="reviewsreat">
                                                      <h4>
                                                        {
                                                          filteredMentorList?.find(
                                                            (job) =>
                                                              job.id ===
                                                              ActiveTabs
                                                          )?.per_hour_price
                                                        }{" "}
                                                        <span>$/Hour</span>
                                                      </h4>
                                                    </div>
                                                    <div className="reviewsreat">
                                                      {filteredMentorList?.find(
                                                        (job) =>
                                                          job.id === ActiveTabs
                                                      )?.isBooking == true ? (
                                                        <Link
                                                          href="#"
                                                          data-bs-toggle="modal"
                                                          onClick={BookedStatus}
                                                          data-bs-target="#bookinterview"
                                                          className="btn btn-success"
                                                        >
                                                          Booked ✔{" "}
                                                        </Link>
                                                      ) : (
                                                        <Link
                                                          href="#"
                                                          data-bs-toggle="modal"
                                                          onClick={() => {
                                                            setShow(true);
                                                            GetMentorListById(
                                                              filteredMentorList?.find(
                                                                (job) =>
                                                                  job.id ===
                                                                  ActiveTabs
                                                              )?.id
                                                            );
                                                          }}
                                                          data-bs-target="#bookinterview"
                                                          className="default-btn rounded"
                                                        >
                                                          Book Now{" "}
                                                        </Link>
                                                      )}
                                                    </div>
                                                    <div>
                                                      {/* <----- Meeting Sessions here -----------> */}
                                                      <Modal
                                                        size="lg"
                                                        className="modal fade"
                                                        id="bookinterview"
                                                        tabindex="-1"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                        show={show}
                                                        onHide={() => {
                                                          setShow(false)
                                                          setSlotBooked(false);
                                                          setTimeout(() => {
                                                            setMentorSlotList([])
                                                            setDataFetched(false)
                                                            setSlot_time([])
                                                          }, 1000)
                                                        }
                                                        }

                                                      >
                                                        <Modal.Header closeButton>
                                                          <Modal.Title className="m-0">
                                                            <h3 className="m-0">
                                                              Meeting Session Date
                                                              and Time
                                                            </h3>{" "}
                                                          </Modal.Title>
                                                          <ToastContainer
                                                            style={{
                                                              marginTop: "120px",
                                                            }}
                                                          />
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                          <div className="row">
                                                            <div className="col-12">
                                                              <Datetime
                                                                value={null}
                                                                onChange={handleDateChange}
                                                                inputProps={{
                                                                  placeholder: "Select Date & Time",
                                                                }}
                                                                dateFormat="MMMM D, YYYY"
                                                                timeFormat={false}
                                                                isOpen={isCalendarOpen}
                                                              />


                                                            </div>

                                                            {!(
                                                              slotBooked &&
                                                              slot_time
                                                            ) && (
                                                                <p className="text-danger">
                                                                  {" "}
                                                                  Please select a
                                                                  slot before Book
                                                                  Now
                                                                </p>
                                                              )}
                                                            <div className="card">
                                                              <div className="card-body">
                                                                <div className="timeslotbook skillstopic addtimes">
                                                                  {isDateInvalid ? (
                                                                    <>
                                                                      <h5 className="text-danger">
                                                                        Invalid
                                                                        date
                                                                        selected.
                                                                        Please
                                                                        choose a
                                                                        valid
                                                                        Date.
                                                                      </h5>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {dataFetched ? (
                                                                        <>
                                                                          {MentorSlotList &&
                                                                            MentorSlotList?.length >
                                                                            0 ? (
                                                                            <>
                                                                              This slot is in the EST time zone. Please check the slot time
                                                                              against your local time zone.
                                                                              {MentorSlotList?.map(
                                                                                (
                                                                                  MentorSlotListResult,
                                                                                  index
                                                                                ) => (
                                                                                  <div
                                                                                    key={
                                                                                      index
                                                                                    }
                                                                                    onClick={() =>
                                                                                      handleTimeSlotClick(
                                                                                        MentorSlotListResult?.booking_time
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      backgroundColor:
                                                                                        slot_time?.includes(
                                                                                          MentorSlotListResult?.booking_time
                                                                                        )
                                                                                          ? "#004766"
                                                                                          : "white",
                                                                                      color:
                                                                                        slot_time?.includes(
                                                                                          MentorSlotListResult?.booking_time
                                                                                        )
                                                                                          ? "white"
                                                                                          : "black",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      MentorSlotListResult?.booking_time
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          ) : (
                                                                            <>
                                                                              <h5 className="text-danger">
                                                                                Not
                                                                                Any
                                                                                Slot
                                                                                Here...
                                                                              </h5>
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      ) : null}
                                                                    </>
                                                                  )}
                                                                </div>
                                                                {slot_time?.length >
                                                                  0 && (
                                                                    <div className="mt-3">
                                                                      <strong>
                                                                        {totalPrice /
                                                                          100}{" "}
                                                                      </strong>
                                                                      <span>
                                                                        {" "}
                                                                        $/Hour
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            </div>
                                                            <div className="col-12 pb-2">
                                                              {/* Conditionally render the "Book Now" button */}
                                                              {slot_time?.length >
                                                                0 && (
                                                                  <div className="w-100"
                                                                    disabled={
                                                                      !slot_time
                                                                    }
                                                                  >
                                                                    <RazorpayPaymentButton
                                                                      Price={
                                                                        totalPrice
                                                                      }
                                                                      buttonContent="Book Now"
                                                                      onPaymentSuccess={
                                                                        handlePaymentSuccess
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </div>
                                                        </Modal.Body>
                                                      </Modal>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                          <div className="card-instrutors shadow bg-white p-4">
                                            <h5>Profession: </h5>
                                            {filteredMentorList
                                              ?.find(
                                                (job) => job.id === ActiveTabs
                                              )
                                              ?.profession?.replace(/[{}"]/g, "")
                                              ?.split(", ")
                                              ?.map((skill, index) => (
                                                <div
                                                  className="d-inline"
                                                  key={index}
                                                >
                                                  {skill}
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                        <div className="instructors-details-contents mt-3">
                                          <div className="card-instrutors shadow bg-white p-4">
                                            <h5>About Mentor</h5>
                                            <p>
                                              {ReactHtmlParser(
                                                filteredMentorList?.find(
                                                  (job) => job.id === ActiveTabs
                                                )?.userdesc
                                              )}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="text-center mt-5">
                    <h2>Not Avaliable Mentor Profile here...</h2>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <Student_Footer />
    </div>
  );
};

export default Student_Mentor_List;
