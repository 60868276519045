import React, { useState, useEffect } from 'react';
import Mentor_header from '../mentor_header/Mentor_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Typewriter from 'typewriter-effect';
import { MentorBookInprocessComplete, MentorSeenStudentList, MentorBookReject } from '../../../../api/Mentor';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../loader/Loader';
import { TimeZoneMappings } from '../../../common/timezone/TimeZone';
import Mentor_Footer from '../mentor_footer/Mentor_Footer';

const Mentor_Student_Profile = () => {
    const navigate = useNavigate();
    const [is_Loading, setIs_Loading] = useState(false);
    const [meeting_link, setMeeting_Link] = useState("");
    const [MentorStudentList, setMentorStudentList] = useState([]);
    const [MentorStudentDetails, setMentorStudentDetails] = useState({});
    const [ActiveTabs, setActiveTabs] = useState(MentorStudentList[0]?.id);
    const [showSearchFields, setShowSearchFields] = useState(false);
    const mentor_Login_Data = localStorage.getItem("user_Login_Time_Zone")
    const mentor_login_parse_data = JSON.parse(mentor_Login_Data)
    console.log("mentor_login_parse_data", mentor_login_parse_data)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredStudentList, setFilteredStudentList] = useState([]);
    
    // <-------- User Authentication Token's ---------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    // <--------------- Handle The Active Tabs ------------->
    const HandleActiveTabs = (tabs) => {
        setActiveTabs(tabs);
    }

    // <--------- Accept Popup Modal Function --------->
    const [Acceptshow, setAcceptShow] = useState(false);
    const handleAcceptClose = () => setAcceptShow(false);
    const handleAcceptShow = () => setAcceptShow(true);

    // <--------- Reject Popup Modal Function --------->
    const [Rejectshow, setRejectshow] = useState(false);
    const handleRejectshow = () => setRejectshow(true);
    const handleRejectClose = () => setRejectshow(false);

    // Function to validate a URL
    const validateMeetingLink = (url) => {
        const regex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w- ./?%&=]*)?$/i;
        return regex.test(url);
    };
    

    // <---------- Accept the Upcoming Slot ------------->
    const handleAcceptUpcomingSlot = async () => {
        if (!meeting_link) {
            toast.error("Please provide a meeting link before submitting!");
            return;
        }
    
        // Validate the meeting link
        if (!validateMeetingLink(meeting_link)) {
            toast.error("Please provide a valid meeting URL!");
            return;
        }
    
        const MentorBookInprocessdata = {
            meeting_link,
            status: 2,
            id: ActiveTabs,
        };
    
        try {
            const response = await MentorBookInprocessComplete(MentorBookInprocessdata, headers);
            if (response?.data?.msg === "Booking status updated successfully.") {
                toast.success("Booking status updated successfully !!!");
                setMeeting_Link("");
                setTimeout(() => {
                    handleAcceptClose();
                }, 1000);
                setTimeout(() => {
                    navigate("/mentor_pages/Mentor_UpComing_Session");
                }, 2000);
            }
        } catch (error) {
            toast.error("An error occurred while updating the booking status!");
            console.error(error);
        }
    };
    
   
    



    // <---------- Reject the  Slot ------------->
    const handleRejectSlot = async () => {
        const MentorBookRejectdata = {
            id: ActiveTabs,
            status: "0",
        }
        const response = await MentorBookReject(MentorBookRejectdata, headers)
        console.log("handleRejectSlot", response)
        if (response?.data?.msg === "Booking cancel successfully.") {
            toast.error("Booking cancel successfully.")
            setTimeout(() => {
                handleRejectClose();
                handleMentorSeenStudentList();
            }, 2000)
        }

    }

    // <----------- Student Profile & Mentor Booking  ----------->
    const handleMentorSeenStudentList = async () => {
        setIs_Loading(true)
        try {
            const MentorSeenStudentListData = {
                status: 1,
            };
            const response = await MentorSeenStudentList(MentorSeenStudentListData, headers);
            setMentorStudentList(response?.data?.data?.booking);
            console.log("jfdsjfdsjgjksdgdshfkgjfd-----------", response)
            handleMentorSeenStudentDetails(response?.data?.data?.booking[0]?.id)
            setIs_Loading(false);
        } catch (error) {
            setIs_Loading(true)
        }

    };
    console.log("handleMentorSeenStudentList+++++12345", MentorStudentList)
  

    const getFullTimeZoneName = (abbreviation) => {
        return TimeZoneMappings[abbreviation] || abbreviation;
    };

    const handleMentorSeenStudentDetails = async (id) => {
        try {
            const MentorSeenStudentListData = {
                status: 1,
                id: id ? id : ActiveTabs
            };
            const response = await MentorSeenStudentList(MentorSeenStudentListData, headers);
            const studentData = response?.data?.data;
            console.log("studentData", studentData?.booking?.slot_time);

            // Retrieve dynamic time zones
            const mentorTimeZone = getFullTimeZoneName(mentor_login_parse_data); // Dynamic mentor timezone
            console.log("mentorTimeZone", mentorTimeZone);

            // Assuming studentTimeZone could be 'IST' or another valid timezone
            let studentTimeZone = getFullTimeZoneName(studentData?.booking?.slot_time_zone); // Dynamic student timezone
            console.log("studentTimeZone", studentTimeZone);

            if (!mentorTimeZone || !studentTimeZone || !studentData?.booking?.slot_time) {
                throw new Error('Missing time zone or slot time data');
            }

            // Split slot times
            const slotStrings = studentData.booking.slot_time.match(/"([^"]+)"/g)?.map(slot => slot.replace(/"/g, ''));
            const referenceDate = moment().format('YYYY-MM-DD');

            const convertedSlots = slotStrings?.map((slot) => {
                const [startTimeStr, endTimeStr] = slot.split(' To ');
                try {
                    // Parse times in student's time zone
                    const startTime = moment.tz(`${referenceDate} ${startTimeStr}`, 'YYYY-MM-DD h:mm A', studentTimeZone);
                    const endTime = moment.tz(`${referenceDate} ${endTimeStr}`, 'YYYY-MM-DD h:mm A', studentTimeZone);

                    // Convert to mentor's time zone
                    const startTimeMentor = startTime.clone().tz(mentorTimeZone).format('h:mm A');
                    const endTimeMentor = endTime.clone().tz(mentorTimeZone).format('h:mm A');

                    return `${startTimeMentor} to ${endTimeMentor}`;
                } catch (error) {
                    console.error(`Error converting time slot ${slot}:`, error);
                    return slot; // Return original slot if there's an error
                }
            });

            console.log("convertedSlots", convertedSlots);

            setMentorStudentDetails({
                ...studentData,
                booking: {
                    ...studentData.booking,
                    slot_time: convertedSlots
                }
            });

        } catch (error) {
            console.error('Error fetching mentor details:', error);
        }
    };


    const handleMentorSeenStudentById = (id) => {
        setActiveTabs(id)
        handleMentorSeenStudentDetails(id);
    }
    console.log("handleMentorSeenStudentById", ActiveTabs)

    // Function to handle search input change
    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // <------- render Student Profile ------------->
    useEffect((id) => {
        handleMentorSeenStudentList();
        handleMentorSeenStudentById(id);
    }, [])


    useEffect(() => {
        const filtered = MentorStudentList?.filter((MentorStudentListResult) => {
            const searchQueryLower = searchQuery.toLowerCase();
            const matchesMentorStudentList = 
                MentorStudentListResult?.fname?.toLowerCase().includes(searchQueryLower) ||
                MentorStudentListResult?.lname?.toLowerCase().includes(searchQueryLower) ||
                MentorStudentListResult?.mobile?.toLowerCase().includes(searchQueryLower) ||
                MentorStudentListResult?.email?.toLowerCase().includes(searchQueryLower);
            
            return matchesMentorStudentList;
        });
        setFilteredStudentList(filtered);
        if (filtered.length > 0) {
            handleMentorSeenStudentDetails(filtered[0].id);
        }
    }, [MentorStudentList, searchQuery]);
    

  
    useEffect(() => {
        if (filteredStudentList?.length > 0 && (!ActiveTabs || !filteredStudentList?.some(job => job.id === ActiveTabs))) {
            const firstActiveTab = filteredStudentList[0];
            if (firstActiveTab) {
                setActiveTabs(firstActiveTab?.id);
            }
        }
    }, [filteredStudentList, ActiveTabs]);
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Mentor_header />
            </div>
            <div className="header_space"></div>
            <div className="recruiter_list pt-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav tabs_signup hiredpppp justify-content-start gap-2 mb-3">
                                <li className="nav-item">
                                    <Link className="nav-link active"
                                        to="/mentor_pages/Mentor_Student_Profile">All Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/mentor_pages/Mentor_UpComing_Session">Upcoming Sessions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " aria-current="page" to="/mentor_pages/CoachingMentor"> Completed Sessions</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>
                ) : (
                    <>
                        {
                            MentorStudentList?.length > 0 ? (
                                <div className="recruiter_list pb-5 pt-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <form>
                                                    <div className="searchlocation">
                                                        <div className="iconfield">
                                                            <div className="icon_search">
                                                                <img src={CommonImageTransport?.searchicon} alt="" />
                                                            </div>
                                                            {!showSearchFields ? (
                                                                <div
                                                                    onClick={() => setShowSearchFields(true)}>
                                                                    <Typewriter
                                                                        options={{
                                                                            loop: true,
                                                                        }}
                                                                        onInit={(typewriter) => {
                                                                            typewriter.typeString('Search by title')
                                                                                .callFunction(() => {
                                                                                })
                                                                                .pauseFor(2500)
                                                                                .deleteAll()
                                                                                .callFunction(() => {
                                                                                })
                                                                                .typeString('Search by location')
                                                                                .pauseFor(2500)
                                                                                .start();
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <input type="text"
                                                                        placeholder=""
                                                                        onChange={handleSearchInputChange}
                                                                        style={{ border: "none", outline: "none" }} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="iconfield">
                                                            <div className="icon_search">
                                                                <img src={CommonImageTransport?.locationicon} alt="" />
                                                            </div>
                                                            <div className="input-field">
                                                                <input type="text" placeholder="USA, Florida"
                                                                    onChange={handleSearchInputChange}
                                                                    className="form-control"
                                                                    required="" />
                                                            </div>
                                                        </div>


                                                        <div className="input-field ad121">
                                                            <button type="submit" className="submit_btn">Search</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="left_recruiterlists row">
                                            <div className="col-md-4 col-lg-4">
                                                <div className="sticky-header">
                                                    <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                        aria-orientation="vertical">
                                                        {
                                                            filteredStudentList?.map((MentorStudentListResult) => {
                                                                return (
                                                                    <>
                                                                        <div className={`nav-link ${ActiveTabs === MentorStudentListResult?.id ? "active" : ""}`}
                                                                            onClick={() => {
                                                                                HandleActiveTabs(MentorStudentListResult?.id)
                                                                                handleMentorSeenStudentById(MentorStudentListResult?.id)
                                                                            }} id="v-pills-home-tab" data-bs-toggle="pill"
                                                                            data-bs-target="#v-pills-home" type="button" role="tab"
                                                                            aria-controls="v-pills-home"
                                                                            aria-selected="true">
                                                                            <div className="card_rec position-relative">

                                                                                <div className="mentersvies0121">
                                                                                    <div className="position-relative">
                                                                                        {
                                                                                            MentorStudentListResult?.profileimg != null ? (
                                                                                                <>
                                                                                                    <img src={MentorStudentListResult?.profileimg}
                                                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                                                </>

                                                                                            ) : (
                                                                                                <>
                                                                                                    <img src={CommonImageTransport?.userLogo}
                                                                                                        className="img-fluid rounded" alt="Instructor" />
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                        <span className="live_user bg-success newuser">Available</span>
                                                                                    </div>
                                                                                    <div className="instructors-details-contents">
                                                                                        <div className="d-flex justify-content-between gap-2">
                                                                                            <div className="comnam">
                                                                                                <h3>{MentorStudentListResult?.fname}</h3>
                                                                                                <h6 className="sub-title ">Email :{MentorStudentListResult?.email}</h6>
                                                                                                {/* <h6 className="sub-title">Phone :{MentorStudentListResult?.mobile}</h6> */}
                                                                                                <p className="sub-title">{MentorStudentListResult?.p_title}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <p className="perographsv">
                                                                                            {MentorStudentDetails?.student_detail?.company}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                filteredStudentList?.find(job => job.id === ActiveTabs) && (
                                                    <>
                                                        <div className="col-md-8 col-lg-8">
                                                            <div className="tab-content" id="v-pills-tabContent">
                                                                {MentorStudentDetails &&
                                                                    <div className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                        id={`v-pills-${ActiveTabs}`}
                                                                        role="tabpanel"
                                                                        aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                        <div className="instructors-details-img m-0">
                                                                            <div className="card-instrutors shadow position-relative bg-white p-4">
                                                                                <div className="mentersvies0121">
                                                                                    <div className="position-relative menter_pro">
                                                                                        {
                                                                                            MentorStudentDetails?.student_info?.profileimg != null ? (
                                                                                                <>
                                                                                                    <img src={MentorStudentDetails?.student_info?.profileimg}
                                                                                                        className="rounded_01top" alt="Instructor" />
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <img src={CommonImageTransport?.userLogo}
                                                                                                        className="rounded_01top" alt="Instructor" />
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                        <span className="live_user bg-success newuser">Available</span>
                                                                                    </div>
                                                                                    <div className="instructors-details-contents mt-3">
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <div>
                                                                                                <h3>{MentorStudentDetails?.student_info?.fname}</h3>
                                                                                                <p>Email: {MentorStudentDetails?.student_info?.email} </p>
                                                                                                {/* <p>Phone: {MentorStudentDetails?.student_info?.countrycode} {MentorStudentDetails?.student_info?.mobile}</p> */}
                                                                                                <h6 className="sub-title mb-2">{MentorStudentDetails?.student_detail?.p_title}</h6>
                                                                                            </div>
                                                                                            <div className="bookbtnas d-flex align-items-center gap-2">

                                                                                                <a href="#" onClick={() => {
                                                                                                    handleAcceptShow();
                                                                                                    handleMentorSeenStudentById(MentorStudentDetails?.booking?.id);
                                                                                                }}
                                                                                                    className="btn btn-primary btn-sm rounded" data-bs-toggle="modal"
                                                                                                    data-bs-target="#feedback">Accept</a>


                                                                                                {/* <!-- Accept modal --> */}
                                                                                                <Modal size="lg" className="modal fade" id="feedback" tabindex="-1"
                                                                                                    aria-labelledby="exampleModalLabel"
                                                                                                    aria-hidden="true"
                                                                                                    show={Acceptshow} onHide={handleAcceptClose}>
                                                                                                    <ToastContainer style={{ marginTop: "50px" }} />
                                                                                                    <Modal.Body>
                                                                                                        <div className="modal-header">
                                                                                                            <h5 className="modal-title" id="exampleModalLabel">Meeting</h5>
                                                                                                            <button type="button" onClick={handleAcceptClose} className="btn-close" data-bs-dismiss="modal"
                                                                                                                aria-label="Close"></button>
                                                                                                        </div>
                                                                                                        <form method="post">
                                                                                                            <div className="modal-body">
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12 col-md-12">
                                                                                                                        <div className="form-group mb-2 myteamform">
                                                                                                                            <lable>Meeting link</lable>
                                                                                                                            <input type="text" name="meeting_link"
                                                                                                                                value={meeting_link}
                                                                                                                                onChange={(e) => setMeeting_Link(e.target.value)}
                                                                                                                                placeholder="https://meet.google.com/odp-nvra-ycf"
                                                                                                                                className="form-control"
                                                                                                                                required />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="dd-footer d-flex gap-3 p-3 border-top">
                                                                                                                <button type="button" onClick={handleAcceptUpcomingSlot}
                                                                                                                    className="btn default-btn w-100">Submit</button>
                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </Modal.Body>
                                                                                                </Modal>

                                                                                                <a href="#"
                                                                                                    className="btn btn-danger btn-sm rounded"
                                                                                                    onClick={handleRejectshow}
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target="#rejectmodal">Reject</a>

                                                                                                {/* <-------- Reject Modal--->  */}
                                                                                                <Modal className="modal fade" id="feedback" tabindex="-1" aria-labelledby="exampleModalLabel"
                                                                                                    aria-hidden="true"
                                                                                                    show={Rejectshow} onHide={handleRejectClose}>
                                                                                                    <Modal.Body>
                                                                                                        <div className="modal-header">
                                                                                                            <h5 className="modal-title" id="exampleModalLabel">Reject Meeting</h5>
                                                                                                            <button type="button" onClick={handleRejectClose} className="btn-close" data-bs-dismiss="modal"
                                                                                                                aria-label="Close"></button>
                                                                                                        </div>
                                                                                                        <form method="post">
                                                                                                            <div className="modal-body">
                                                                                                                <h6>Are You Sure to reject Meeting
                                                                                                                </h6>
                                                                                                            </div>
                                                                                                            <div className="modal-footer">
                                                                                                                <button type="button" className="btn btn-danger" onClick={handleRejectClose} data-bs-dismiss="modal">No</button>
                                                                                                                <button type="button" onClick={handleRejectSlot} className="btn btn-primary">Yes</button>
                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </Modal.Body>
                                                                                                </Modal>

                                                                                            </div>
                                                                                        </div>
                                                                                        <p>
                                                                                            {MentorStudentDetails?.student_detail?.company}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="card-instrutors rounded shadow position-relative mt-3 bg-white p-4">
                                                                                <div className="card-body p-0">
                                                                                    <div className="d-flex justify-content-between align-items-start gap-5">
                                                                                        <div>
                                                                                            <h6>Available Time</h6>
                                                                                            <div className="skillstopic">
                                                                                                <div className="fw-bold bg-light text-primary">Date:</div>
                                                                                                <div>{moment(MentorStudentDetails?.booking?.bookig_date).format("MM/DD/YYYY")}</div>
                                                                                                <div className="fw-bold bg-light text-primary">Day:</div>
                                                                                                <div>{MentorStudentDetails?.booking?.booking_day}</div>
                                                                                                {/* <div>{MentorStudentDetails?.booking?.slot_time_zone}</div> */}
                                                                                                <div className="fw-bold bg-light text-primary">Time:</div>
                                                                                                <div>
                                                                                                    {MentorStudentDetails?.booking?.slot_time
                                                                                                        ?.map((slot, index, array) => (
                                                                                                            <span key={index}>
                                                                                                                {slot}
                                                                                                                {index < array.length - 1 && ', '}
                                                                                                            </span>
                                                                                                        ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {/* <------- Skills Sections -----------> */}
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Skills</h5>
                                                                                <div className="skillstopic">
                                                                                    {
                                                                                        MentorStudentDetails?.student_skills?.map((StudentSkillsResult) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div>{StudentSkillsResult?.skill}</div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <------- Education Sections -----------> */}
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Education</h5>
                                                                                {
                                                                                    MentorStudentDetails?.student_educations?.map((StudentEductionResult) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="skillstopic_education">
                                                                                                    <div className="educationicon">
                                                                                                        <img src={CommonImageTransport?.education} alt="" />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <h6>{StudentEductionResult?.university}</h6>
                                                                                                        <span>{StudentEductionResult?.degreetype}</span>
                                                                                                        <p>{moment(StudentEductionResult?.startdate).format("MM/YYYY")} - {moment(StudentEductionResult?.enddate).format("MM/YYYY")}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        {/* <------- Work Experience Sections -----------> */}
                                                                        {
                                                                            MentorStudentDetails?.student_detail?.p_title != null && (
                                                                                <div className="instructors-details-contents mt-3">
                                                                                    <div className="card-instrutors shadow bg-white p-4">
                                                                                        <h5>Work Experience</h5>
                                                                                        <div className="skillstopic_education">
                                                                                            <div className="educationicon">
                                                                                                <img src={CommonImageTransport?.education} alt="" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <h6>{MentorStudentDetails?.student_detail?.p_title}</h6>
                                                                                                <span>{MentorStudentDetails?.student_detail?.company}</span>
                                                                                                <p>{moment(MentorStudentDetails?.student_detail?.start_date).format("MM/DD/YYYY")} - {moment(MentorStudentDetails?.student_detail?.end_date).format("MM/DD/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='text-center mt-5'>
                                    <h2>Not Avaliable Student Profile here...</h2>
                                </div>
                            )
                        }
                    </>
                )
            }


            <Mentor_Footer />
        </div>
    )
}

export default Mentor_Student_Profile