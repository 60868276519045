import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { StudentProfile, GetStudentProfile } from "../../../../api/Student";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { PrivacyPolicy, TermsCondtion } from "../../../../api/Global";
import { countries } from "../../../common/countrylist/CountryList";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const Student_Step = () => {
  const navigate = useNavigate();
  // <-------- UseState for the Students step form ------------->
  const [Linkedin_Profile, setLinkedin_Profile] = useState("");
  const [degreetype, setDegree_Type] = useState("");
  const [degreename, setBachelor_Degree] = useState("");
  const [university, setBachelor_University_Name] = useState("");
  const [enddate, setBachelor_End_date] = useState("");
  const [startdate, setBachelor_Start_date] = useState("");
  const [certificate, setCertification] = useState("");
  const [certification_date, setCertificate_Expiration_Date] = useState("");
  const [organication, setCertificate_Organization] = useState("");
  const [skill, setSkills] = useState("");
  const [expertise, setSelect_Expertise] = useState("");
  const [title, setProject_title] = useState("");
  const [websitelink, setWebsite_Link] = useState("");
  const [yourrole, setProject_role] = useState("");
  const [p_title, setProfessional_Title] = useState("");
  const [emp_type, setProfessional_Employment_type] = useState("");
  const [company, setProfessional_Company] = useState("");
  const [location, setProfessional_Company_Location] = useState("");
  const [location_type, setProfessional_Company_Location_type] = useState("");
  const [currently_working, setCurrently_working] = useState("1");
  const [start_date, setProfessional_Start_date] = useState("");
  console.log("setProfessional_Start_date", start_date);
  const [end_date, setProfessional_End_date] = useState("");
  const [industry, setProfessional_Industry] = useState("");
  const [job_desc, setJob_Desc] = useState("");

  // <---------- Show the Popup T&C ------------->
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [DOB, setDOB] = useState("");
  const [selectNationlity, setSelectNationlity] = useState("");
  // <---------- Show the Popup T&C ------------->
  const [ShowTermsCondition, setShowTermsCondition] = useState(false);

  // <------------ P & P ----------------->
  const [PrivacyPolicyList, setPrivacyPolicyList] = useState({});
  const [TermsConditionList, setTermsConditionList] = useState({});

  const [isChecked_Linkedin_Profile, setisChecked_Linkedin_Profile] =
    useState(false);
  const [LinkedinError, setLinkedinError] = useState("");
  const [isChecked_Other, setisChecked_Other] = useState(false);
  console.log("isChecked_Other", isChecked_Other);

  // <--------- This is UseState for Loader Pages-------->
  const [isLoading, setIsLoading] = useState(true);
  const [is_current_work, set_is_current_work] = useState("0");
  const tabs = ["0", "1", "2", "3", "4", "5", "6", "7"];

  const [AddBachelorDegree, setAddBachelorDegree] = useState([{ id: 1 }]);
  const [AddExperience, setAddExperience] = useState([{ id: 1 }]);
  console.log("setAddExperience", setAddExperience);
  const [AddCertifications, setAddCertifications] = useState([{ id: 1 }]);
  const [AddUniversityProjects, setAddUniversityProjects] = useState([
    { id: 1 },
  ]);
  const [AddSkills, setAddSkills] = useState([{ id: 1 }]);

  const [selectedOption, setSelectedOption] = useState(""); // For citizen
  const [otherInput, setOtherInput] = useState("");

  const [selectedEthnicityOption, setSelectedEthnicityOption] = useState(""); // For ethnicity
  const [otherEthnicityInput, setOtherEthnicityInput] = useState("");

  // <-------------- Dob Date section's ---------------->
  // Get today's date
  // Get today's date
  const today = new Date();

  // Calculate the date 18 years ago
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 18);

  // Convert the max date to ISO format for the input
  const maxDateString = maxDate.toISOString().split("T")[0];

  const token = localStorage.getItem("User_Token");
  // <--------- Add the Bachelor Degree -------------->
  const HandleAddEductions = () => {
    const newId = AddBachelorDegree?.length + 1;
    setAddBachelorDegree([...AddBachelorDegree, { id: newId }]);
  };
  const isValidLinkedinUrl = (url) => {
    const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
    return linkedinRegex.test(url);
  };

  // Converts an ISO date string to DD-MM-YYYY format
  const formatDateToDDMMYYYY = (isoDate) => {
    if (!isoDate) return "";
    const [year, month, day] = isoDate.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  };

  // Converts a DD-MM-YYYY format date to ISO (YYYY-MM-DD)
  const formatToISODate = (ddmmyyyyDate) => {
    if (!ddmmyyyyDate) return "";
    const [day, month, year] = ddmmyyyyDate.split("-");
    return `${year}-${month}-${day}`;
  };

  // At the top of your file or in your component
  const getCurrentDateISO = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Then, use it where you need the current date
  // Example:
  const [todayDate, setTodayDate] = useState(getCurrentDateISO());

  const handleProfessionalStartDateChange = (e, index) => {
    const selectedStartDateISO = e.target.value; // This is in ISO format from the date input
    const endDateISO = AddExperience[index].end_date
      ? AddExperience[index].end_date
      : null;

    const updatedExperience = [...AddExperience];
    updatedExperience[index].start_date = selectedStartDateISO;

    if (endDateISO && selectedStartDateISO >= endDateISO) {
      updatedExperience[index].end_date = ""; // Clear end date if invalid
      toast.error("Start date must be earlier than the end date.");
    }
    setProfessional_Start_date(updatedExperience);
  };

  // Handle 'Currently Working' checkbox change
  const handleCurrentlyWorkingChange = (index, checked) => {
    const updatedExperience = [...AddExperience];

    // Set currently_working to 1 if checked, otherwise 0
    updatedExperience[index].currently_working = checked ? 1 : 0;

    // If checkbox is checked, clear the end date
    if (checked) {
      updatedExperience[index].end_date = ''; // Clear the end date if currently working
    }

    // Update the state after modifying the experience
    setAddExperience(updatedExperience);
  };


  // Handle end date change
  const handleProfessionalEndDateChange = (e, index) => {
    const selectedEndDateISO = e.target.value; // ISO format from input
    const startDateISO = AddExperience[index].start_date
      ? AddExperience[index].start_date
      : null;

    const updatedExperience = [...AddExperience];

    if (!startDateISO) {
      toast.error("Please select a start date first.");
    } else if (selectedEndDateISO <= startDateISO) {
      updatedExperience[index].end_date = ""; // Clear end date if invalid
      toast.error("End date must be after the start date.");
    } else {
      updatedExperience[index].end_date = selectedEndDateISO;
    }

    // If end date is selected, uncheck the 'currently working' checkbox
    if (selectedEndDateISO) {
      updatedExperience[index].currently_working = "0"; // Uncheck the checkbox if end date is filled
    }

    setAddExperience(updatedExperience);
  };



  useEffect(() => {
    if (AddBachelorDegree?.length === 0) {
      setAddBachelorDegree([
        {
          id: 1,
          degreetype: "",
          degreename: "",
          university: "",
          startdate: "",
          enddate: "",
        },
      ]);
    } else if (AddCertifications?.length === 0) {
      setAddCertifications([
        { id: 1, organication: "", certification_date: "", certificate: "" },
      ]);
    } else if (AddSkills?.length === 0) {
      setAddSkills([{ id: 1, skill: "", expertise: "" }]);
    } else if (AddUniversityProjects?.length === 0) {
      setAddUniversityProjects([
        { id: 1, title: "", websitelink: "", yourrole: "" },
      ]);
    } else if (AddExperience?.length === 0) {
      setAddExperience([
        {
          id: 1,
          p_title: "",
          emp_type: "",
          company: "",
          location: "",
          currently_working: "0",
          location_type: "",
          start_date: "",
          end_date: "",
          industry: "",
          job_desc: "",
        },
      ]);
    }
  }, [
    AddBachelorDegree,
    AddCertifications,
    AddSkills,
    AddUniversityProjects,
    AddExperience,
  ]);

  // <--------- Add the Certifications Sections  -------------->
  const HandleAddCertifications = () => {
    const newId = AddCertifications.length + 1;
    setAddCertifications([...AddCertifications, { id: newId }]);
  };

  // <--------- Add the University Projects Sections -------------->
  const HandleAddUniversityProjects = () => {
    const newId = AddUniversityProjects.length + 1;
    setAddUniversityProjects([...AddUniversityProjects, { id: newId }]);
  };

  // <----------- Add The Professional Exp --------------->
  const handleAddExperience = () => {
    const newId = AddExperience.length + 1;
    setAddExperience([...AddExperience, { id: newId }]);
  };

  // <--------- Add the Skills Sections -------------->
  const HandleAddSkills = () => {
    const newId = AddSkills.length + 1;
    setAddSkills([...AddSkills, { id: newId }]);
  };

  // <----------- Handle The Active tabs ------------------>
  const handleTabClick = (tab) => {
    set_is_current_work(tab);
  };

  // <----------- Handle Previous Pages ------------------>
  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(is_current_work);
    const previousIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    set_is_current_work(tabs[previousIndex]);
  };

  // <----------- Handle Next Pages ------------------>
  const handleNext = () => {
    const currentIndex = tabs.indexOf(is_current_work);
    const nextIndex = (currentIndex + 1) % tabs.length;
    set_is_current_work(tabs[nextIndex]);
  };

  // <------ Handle Linkdin Url Form ----------------->
  const HandleLinkdinUrl = (e) => {
    e.preventDefault();
    // Check if the LinkedIn profile is valid
    if (!isValidLinkedinUrl(Linkedin_Profile)) {
      toast.error("Please enter a valid LinkedIn URL.");
      return;
    }

    // Collect the data to submit
    const Likdinurldata = {
      linkedin_profile: Linkedin_Profile,
      is_current_work: "1",
    };

    // You can add the code here to submit this data to an API
    console.log("Submitting LinkedIn URL:", Likdinurldata);

    // Display success message (if necessary)
    toast.success("LinkedIn profile updated successfully!");

    // Handle further logic like redirecting to the next page, etc.
    handleNext(); // Navigate to the next step
  };

  const handleDegreeChange = (index, field, value) => {
    const updatedDegrees = [...AddBachelorDegree];
    updatedDegrees[index][field] = value;
    setAddBachelorDegree(updatedDegrees); // Update the state with the new values
  };

  // <------ Handle Eduction Form ----------------->
  const HandleEduction = async (e) => {
    e.preventDefault();
    const StudentEducationDetail = {
      is_current_work: "2",
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "", // Send the updated project details
      ["experience"]: "", // Send the updated Experience details
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await StudentProfile(StudentEducationDetail, headers);
  };

  // <----------- Handle Certification(form) sections -------------->
  const HandleCertification = async (e) => {
    e.preventDefault();
    const hasAnyFilledFields = AddCertifications?.some(
      (cert) => cert.certificate || cert.organication || cert.certification_date
    );
    const hasIncompleteFields =
      hasAnyFilledFields &&
      AddCertifications?.some(
        (cert) =>
          (cert.certificate || cert.organication || cert.certification_date) &&
          (!cert.certificate || !cert.organication || !cert.certification_date)
      );
    if (hasIncompleteFields) {
      toast.error("Please fill all certification fields");
      return;
    }
    const StudentCertificationDetail = {
      is_current_work: "3",
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "",
      ["experience"]: "",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Submit form data and navigate to the next step if all fields are filled or skipped entirely
    await StudentProfile(StudentCertificationDetail, headers);

    // Proceed to the next page if submission is successful
    handleNext();
  };

  // <----------- Handle Skills(form) sections -------------->
  const HandleSkills = async (e) => {
    e.preventDefault();
    const StudentSkillsDetail = {
      is_current_work: "4",
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "", // Send the updated project details
      ["experience"]: "", // Send the updated Experience details
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await StudentProfile(StudentSkillsDetail, headers);
  };

  // <----------- Handle University Projects sections -------------->
  // URL validation function
  const validateURL = (url) => {
    try {
      new URL(url); // This will throw an error if the URL is invalid
      return true;
    } catch (_) {
      return false;
    }
  };
  const isValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z0-9][a-z0-9-]*[a-z0-9]?)\\.)+[a-z]{2,}|" + // domain name
      "localhost|" + // localhost
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" + // ipv4
      "\\[?[a-fA-F0-9]*:[a-fA-F0-9:]+\\]?)" + // ipv6
      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_+.~#?&=]*)*$"
    );
    return pattern.test(url);
  };

  const HandleUniversityProjects = async (e) => {
    e.preventDefault();

    // Check if any field in AddUniversityProjects is filled
    const hasFilledFields = AddUniversityProjects.some(
      (project) => project.title || project.websitelink || project.yourrole
    );

    // If any field is filled, proceed with validation
    if (hasFilledFields) {
      // Validate each project link
      const allLinksValid = AddUniversityProjects.every(
        (project) => !project.websitelink || isValidUrl(project.websitelink)
      );

      if (!allLinksValid) {
        toast.error("Please provide valid project links.");
        return;
      }

      // Check if role is filled whenever title or link is provided
      const invalidRole = AddUniversityProjects.some((project) => {
        const isRoleFilled = project.yourrole?.trim().length > 0;
        const isTitleOrLinkFilled = project.title || project.websitelink;
        return isTitleOrLinkFilled && !isRoleFilled;
      });

      if (invalidRole) {
        toast.error("Please complete all required fields. Project links are optional.");
        return;
      }

      // Validate role length (at least 100 characters excluding spaces)
      const invalidRoleLength = AddUniversityProjects.some((project) => {
        const roleWithoutSpaces = project.yourrole?.replace(/\s+/g, "");
        return roleWithoutSpaces && roleWithoutSpaces.length < 100;
      });

      if (invalidRoleLength) {
        toast.error(
          "Please enter at least 100 characters for the project role (excluding spaces)."
        );
        return;
      }
    }

    try {
      // Conditional structure for StudentprojectDetail based on filled fields in AddUniversityProjects
      const StudentprojectDetail = {
        is_current_work: "5",
        certificates: certificate,
        education: degreetype,
        skills: skill,
        experience: "", // Add empty experience if required
        project: hasFilledFields ? AddUniversityProjects : "", // Empty if no fields are filled
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Submit data
      await StudentProfile(StudentprojectDetail, headers);
      handleNext(); // Move to the next step
    } catch (error) {
      console.error("Error submitting project details:", error);
      toast.error("Failed to submit project details. Please try again.");
    }
  };

  // OnClick function for the Next button
  const handleNextClick = (e) => {
    e.preventDefault();

    // Check if user has filled any fields before calling handleNext()
    const hasFilledFields = AddUniversityProjects.some(
      (degree) => degree.title || degree.websitelink || degree.yourrole
    );

    // If any fields are filled, validate them
    if (hasFilledFields) {
      const invalidLink = AddUniversityProjects.some(
        (degree) => degree.websitelink && !validateURL(degree.websitelink)
      );

      if (invalidLink) {
        toast.error("Please enter a valid URL for all projects.");
        return; // Stop here if there's an invalid URL
      }
    }

    // If the fields are either empty or valid, proceed
    handleNext();
    HandleUniversityProjects(e); // Proceed to submit the form
  };

  // Helper function to check if any field in AddExperience has been filled
  const isAnyFieldFilledInExperience = () => {
    return AddExperience.some((experience) => {
      return (
        experience.p_title ||
        experience.company ||
        experience.location ||
        experience.industry ||
        experience.start_date ||
        experience.location_type
      );
    });
  };

  // Helper function to check if all required fields are filled and either end_date or currently_working is provided
  const areFieldsFilledInExperience = () => {
    return AddExperience.every((experience) => {
      const requiredFieldsFilled =
        experience.p_title &&
        experience.company &&
        experience.location &&
        experience.industry &&
        experience.start_date &&
        experience.location_type;

      const endDateOrCurrentChecked = experience.currently_working || experience.end_date;

      console.log('Experience:', experience);
      console.log('Required fields filled:', requiredFieldsFilled);
      console.log('End date or currently working:', endDateOrCurrentChecked);

      return requiredFieldsFilled && endDateOrCurrentChecked;
    });
  };




  // Handle Professional Experience form submission

  const HandleProfessionalexperience = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled and validation for end date/ currently working
    if (!areFieldsFilledInExperience()) {
      toast.error("Please fill in all required fields and either select an end date or check the 'currently working' box.");
      return; // Stop submission if validation fails
    }

    const StudentProfessionalexperience = {
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "",
      ["experience"]: AddExperience,
      is_current_work: "6", // Assuming this is always "6" for this scenario, change as needed
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Call the API to save the form data
      await StudentProfile(StudentProfessionalexperience, headers);
      handleNext(); // Move to the next step
    } catch (error) {
      console.error("Error saving professional experience:", error);
      toast.error("Failed to save professional experience. Please try again.");
    }
  };



  // Handler for partially filled form with end date and checkbox dependency
  const handleIncompleteForm = (e) => {
    e.preventDefault();
    toast.error("Please fill in all required fields, including either the 'End Date' or the 'Currently Working' checkbox.");
  };


  // <----------- Handle Professional experience sections -------------->
  const HandleProfessionalexperienceEmptyFeilds = async (e) => {
    e.preventDefault();

    const StudentProfessionalexperience = {
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "",
      ["experience"]: "", // If any field is filled, use `AddExperience`, else ""
      is_current_work: "6",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Call the API to save the form data
      await StudentProfile(StudentProfessionalexperience, headers);
      handleNext(); // Move to the next step
    } catch (error) {
      console.error("Error saving professional experience:", error);
      toast.error("Failed to save professional experience. Please try again.");
    }
  };

  const handleNationlityChange = (e) => {
    setSelectNationlity(e.target.value);
  };

  // <----------- Handle Other Sections -------------->
  const HandleOtherSections = async (e) => {
    e.preventDefault();
    const StudentOtherDetails = {
      DOB,
      nationality: selectNationlity,
      is_current_work: "7",
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "",
      ["experience"]: "",
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await StudentProfile(StudentOtherDetails, headers);
  };

  // <----------- Handle Other Sections -------------->
  const HandleWorkAuthorizationSections = async (e) => {
    e.preventDefault();
    const StudentOtherDetails = {
      ethnicity:
        selectedEthnicityOption === "Other"
          ? otherEthnicityInput
          : selectedEthnicityOption,
      citizen: selectedOption === "Other" ? otherInput : selectedOption,
      is_current_work: "8",
      ["certificates"]: certificate,
      ["education"]: degreetype,
      ["skills"]: skill,
      ["project"]: "",
      ["experience"]: "",
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await StudentProfile(StudentOtherDetails, headers);
    toast.success("Student Profile Successfully Created !!!");
    setTimeout(() => {
      navigate("/Student/Student_dashBoard");
    }, 2000);
  };

  // <--------- Remove the Bachelor Degree-------------->
  const HandleRemoveBachelorDegree = (id) => {
    const updatedDegrees = AddBachelorDegree?.filter(
      (degree) => degree?.id !== id
    );
    setAddBachelorDegree(updatedDegrees);
  };

  // <--------- Remove the Certifications Sections-------------->
  const HandleRemoveCertifications = (id) => {
    const updatedDegrees = AddCertifications?.filter(
      (degree) => degree?.id !== id
    );
    setAddCertifications(updatedDegrees);
  };

  // <--------- Remove the Certifications Sections-------------->
  const HandleRemoveSkills = (id) => {
    const updatedDegrees = AddSkills?.filter((degree) => degree?.id !== id);
    setAddSkills(updatedDegrees);
  };

  // <--------- Remove the Certifications Sections-------------->
  const HandleRemoveUniversityProjects = (id) => {
    const updatedDegrees = AddUniversityProjects?.filter(
      (degree) => degree.id !== id
    );
    setAddUniversityProjects(updatedDegrees);
  };

  // <------------- Remove the handleRemoveExperience ---------->
  const handleRemoveExperience = (id) => {
    const updatedDegrees = AddExperience?.filter((degree) => degree.id !== id);
    setAddExperience(updatedDegrees);
  };

  // <--------- This UseEffect for Loader Pages-------->
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2200);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetStudentProfile();

      // Set the various states from the response
      setAddBachelorDegree(response?.data?.data?.educations);
      setAddExperience(response?.data?.data?.st_experience);
      console.log("setAddExperience_UseEffect", AddExperience);
      setAddCertifications(response?.data?.data?.certification);
      setAddSkills(response?.data?.data?.skills);
      setAddUniversityProjects(response?.data?.data?.university_projects);
      setProfessional_Title(response?.data?.data?.satudent_detail?.p_title);
      setProfessional_Employment_type(
        response?.data?.data?.satudent_detail?.emp_type
      );
      setProfessional_Company(response?.data?.data?.satudent_detail?.company);
      setProfessional_Company_Location(
        response?.data?.data?.satudent_detail?.location
      );
      setProfessional_Company_Location_type(
        response?.data?.data?.satudent_detail?.location_type
      );
      setSelectedOption(response?.data?.data?.satudent_detail?.citizen);
      setSelectNationlity(response?.data?.data?.satudent_detail?.nationality);
      setSelectedEthnicityOption(
        response?.data?.data?.satudent_detail?.ethnicity
      );
      setOtherEthnicityInput(response?.data?.data?.satudent_detail?.ethnicity);
      setOtherInput(response?.data?.data?.satudent_detail?.citizen);

      // Format start_date and end_date correctly
      const apiStartDate = response?.data?.data?.satudent_detail?.start_date;
      const apiEndDate = response?.data?.data?.satudent_detail?.end_date;

      // Convert to YYYY-MM-DD format if the date exists
      if (apiStartDate) {
        const formattedStartDate = new Date(apiStartDate)
          .toISOString()
          .split("T")[0];
        setProfessional_Start_date(formattedStartDate);
      } else {
        setProfessional_Start_date(""); // or set to a default value if needed
      }

      if (apiEndDate) {
        const formattedEndDate = new Date(apiEndDate)
          .toISOString()
          .split("T")[0];
        setProfessional_End_date(formattedEndDate);
      } else {
        setProfessional_End_date(""); // or set to a default value if needed
      }

      setProfessional_Industry(response?.data?.data?.satudent_detail?.industry);
      setDOB(response?.data?.data?.satudent_detail?.dob);
      setJob_Desc(response?.data?.data?.satudent_detail?.job_desc);
      set_is_current_work(
        response?.data?.data?.satudent_detail?.is_current_work || "0"
      );
    };

    fetchData();
  }, []);

  console.log(
    " AddBachelorDegree[0]?.status == true",
    AddBachelorDegree[0]?.status
  );
  // <--------- When User Fill the Complete Form ---------------->
  useEffect(() => {
    if (is_current_work === "8") {
      navigate("/Student/Student_dashBoard");
    }
  }, [is_current_work, navigate]);

  const steps = [
    { index: 0, label: "Linkedin Profile" },
    { index: 1, label: "Education" },
    { index: 2, label: "Certification" },
    { index: 3, label: "Skills" },
    { index: 4, label: "University Projects" },
    { index: 5, label: "Professional experience" },
    { index: 6, label: "Other" },
    { index: 7, label: "Work Authorization" },
  ];

  // <---------- Get Privacy & ploicy ------------->
  const GetPrivacyPolicy = async () => {
    try {
      const response = await PrivacyPolicy();
      setPrivacyPolicyList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // <---------- Get Privacy & ploicy ------------->
  const GetTermsCondtion = async () => {
    try {
      const response = await TermsCondtion();
      setTermsConditionList(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetPrivacyPolicy();
    GetTermsCondtion();
  }, []);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);

    // Clear other input if a predefined option is selected
    if (value !== "Other") {
      setOtherInput(""); // Clear the input when an option other than 'Other' is selected
    }
  };
  const handleEthnicitySelectChange = (e) => {
    const { value } = e.target;
    setSelectedEthnicityOption(value); // Update selected option

    // Clear other input if a predefined option is selected
    if (value !== "Other") {
      setOtherEthnicityInput(""); // Clear the input when an option other than 'Other' is selected
    }
  };

  return (
    <div>
      <div className="stepbg">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="bg_nav-slid bg-white">{/* <Header /> */}</div>
            <div className="client_filter-job pt-2 pb-0">
              <div className="stepsForm">
                <form method="post" id="msform">
                  <div className="container">
                    <ToastContainer style={{ marginTop: "120px" }} />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="sf-steps">
                          <h5 className="default-btn w-100 text-white m-0 py-3 px-4">
                            All Steps
                          </h5>
                          <div className="left-slid-filter space-box-two shadow-none">
                            <div className="sf-steps-content">
                              {steps?.map((step) => (
                                <div
                                  key={step.index}
                                  // onClick={() => handleTabClick(step.index)}
                                  className={`joblisttitle ${is_current_work == step.index
                                    ? "sf-active"
                                    : "nav-link"
                                    }
                                     ${step.index <= is_current_work
                                      ? "disabled"
                                      : "disabled"
                                    }`}
                                >
                                  <span>{step.index + 1}</span>
                                  <aside className="list-icons">
                                    <h5>{step?.label}</h5>
                                  </aside>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 d-flex">
                        <div className="flexdr position-relative">
                          <div className="sf-steps-form sf-radius">
                            {/* <------ Linkedin Profile Sections -------------> */}
                            {is_current_work == "0" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Linkedin Profile </h4>
                                    <h6>
                                      Fill all form field to go to next step
                                    </h6>
                                  </div>
                                  <hr />
                                  <div className="professional-sp row">
                                    <div className="col-12 col-md-12">
                                      <form className="form-group">
                                        <label className="fieldlabels">
                                          Linkedin Profile Url
                                        </label>
                                        <input
                                          type="text"
                                          name="Linkedin_Profile"
                                          value={Linkedin_Profile}
                                          onChange={(e) => {
                                            setLinkedin_Profile(e.target.value);
                                            if (
                                              !isValidLinkedinUrl(
                                                e.target.value
                                              )
                                            ) {
                                              setLinkedinError(
                                                "Please enter a valid LinkedIn URL."
                                              );
                                            } else {
                                              setLinkedinError("");
                                            }
                                          }}
                                          required
                                          placeholder="https://www.linkedin.com/in/yourprofile"
                                        />
                                        {LinkedinError && (
                                          <p style={{ color: "red" }}>
                                            {LinkedinError}
                                          </p>
                                        )}

                                        <div className="col-lg-12 form-condition">
                                          <div className="agree-label d-flex gap-2">
                                            <input
                                              type="checkbox"
                                              id="chb1"
                                              className="termcheck"
                                              onChange={() =>
                                                setisChecked_Linkedin_Profile(
                                                  !isChecked_Linkedin_Profile
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor="chb1"
                                              className="small text-muted"
                                            >
                                              All your information is collected,
                                              stored and processed as per our
                                              data processing guidelines. By
                                              signing up on FreshGard, you agree
                                              to our{" "}
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  setShowPrivacyPolicy(true)
                                                }
                                              >
                                                Privacy Policy
                                              </Link>{" "}
                                              and{" "}
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  setShowTermsCondition(true)
                                                }
                                              >
                                                Terms of Use
                                              </Link>
                                              .
                                            </label>
                                          </div>
                                          {!isChecked_Linkedin_Profile && (
                                            <p style={{ color: "red" }}>
                                              Please accept the terms and
                                              conditions before proceeding.
                                            </p>
                                          )}
                                        </div>

                                        <div className="sf-steps-navigation d-flex justify-content-between">
                                          {/* <button onClick={handlePrevious} id="sf-prev" type="button"
                                          className="btn btn-light">Previous</button> */}
                                          <span
                                            id="sf-msg"
                                            className="sf-msg-error"
                                          ></span>
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                isChecked_Linkedin_Profile &&
                                                Linkedin_Profile &&
                                                isValidLinkedinUrl(
                                                  Linkedin_Profile
                                                )
                                              ) {
                                                handleNext();
                                                HandleLinkdinUrl(e); // Call the function here
                                              } else if (
                                                !isValidLinkedinUrl(
                                                  Linkedin_Profile
                                                )
                                              ) {
                                                setLinkedinError(
                                                  "Please enter a valid LinkedIn URL."
                                                );
                                              }
                                            }}
                                            id="sf-next"
                                            type="submit"
                                            className="btn default-btn"
                                            disabled={
                                              !isChecked_Linkedin_Profile ||
                                              !Linkedin_Profile ||
                                              LinkedinError
                                            }
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            )}
                            {/* <------ Degree Sections -------------> */}
                            {is_current_work == "1" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Degree Section</h4>
                                  </div>
                                  <hr />
                                  <form className="professional-sp">
                                    {AddBachelorDegree?.map((degree, index) => (
                                      <div
                                        className="degree-fields"
                                        key={index}
                                      >
                                        <div className="text-end">
                                          {index !== 0 && (
                                            <IoClose
                                              className="text-end"
                                              style={{
                                                color: "red",
                                                fontSize: "2rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                HandleRemoveBachelorDegree(
                                                  degree.id
                                                )
                                              }
                                            />
                                          )}
                                        </div>

                                        <div className="col-12 col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">
                                              {" "}
                                              Degree Type{" "}
                                              <span className="text-danger fw-bold">
                                                *
                                              </span>
                                            </label>
                                            <div className="mb-3">
                                              <select
                                                name="degreetype"
                                                value={degree?.degreetype}
                                                onChange={(e) => {
                                                  const updatedDegrees = [
                                                    ...AddBachelorDegree,
                                                  ];
                                                  updatedDegrees[
                                                    index
                                                  ].degreetype = e.target.value;
                                                  setDegree_Type(
                                                    updatedDegrees
                                                  );
                                                }}
                                              >
                                                <option>
                                                  Select Degree Type
                                                </option>
                                                <option value="Bachelor">
                                                  Bachelor
                                                </option>
                                                <option value="Master">
                                                  Master
                                                </option>
                                                <option value="PHD">PHD</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">
                                              Degree Name{" "}
                                              <span className="text-danger fw-bold">
                                                *
                                              </span>
                                            </label>
                                            <div className="mb-3">
                                              <input
                                                type="text"
                                                className="m-0"
                                                name="degreename"
                                                value={degree?.degreename}
                                                // required
                                                onChange={(e) => {
                                                  const updatedDegrees = [
                                                    ...AddBachelorDegree,
                                                  ];
                                                  updatedDegrees[
                                                    index
                                                  ].degreename = e.target.value;
                                                  setBachelor_Degree(
                                                    updatedDegrees
                                                  );
                                                }}
                                                placeholder="Degree Name"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">
                                              University Name
                                            </label>
                                            <input
                                              type="text"
                                              name={`university${degree.id}`}
                                              value={degree.university}
                                              onChange={(e) => {
                                                const updatedDegrees = [
                                                  ...AddBachelorDegree,
                                                ];
                                                updatedDegrees[
                                                  index
                                                ].university = e.target.value;
                                                setBachelor_University_Name(
                                                  updatedDegrees
                                                );
                                              }}
                                              required
                                              placeholder="University Name"
                                            />
                                            <div className="d-flex w-100 gap-2">
                                              <div className="w-100">
                                                <label className="fieldlabels">
                                                  Start date
                                                </label>
                                                <input
                                                  type="date"
                                                  name={`startdate${degree.id}`}
                                                  value={
                                                    degree?.startdate
                                                      ? degree.startdate.split(
                                                        "T"
                                                      )[0]
                                                      : ""
                                                  }
                                                  required
                                                  onChange={(e) => {
                                                    const selectedStartDate =
                                                      e.target.value;
                                                    const updatedDegrees = [
                                                      ...AddBachelorDegree,
                                                    ];

                                                    // Update the start date
                                                    updatedDegrees[
                                                      index
                                                    ].startdate =
                                                      selectedStartDate;

                                                    // Reset end date if it is before the new start date
                                                    const selectedEndDate =
                                                      updatedDegrees[index]
                                                        .enddate;
                                                    if (
                                                      selectedEndDate &&
                                                      new Date(
                                                        selectedEndDate
                                                      ) <
                                                      new Date(
                                                        selectedStartDate
                                                      )
                                                    ) {
                                                      updatedDegrees[
                                                        index
                                                      ].enddate = ""; // Clear the invalid end date
                                                      alert(
                                                        "End date must be greater than start date."
                                                      );
                                                    }

                                                    setBachelor_Start_date(
                                                      updatedDegrees
                                                    );
                                                  }}
                                                  placeholder="start date"
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  } // Disable keyboard input
                                                />
                                              </div>

                                              <div className="w-100">
                                                <label className="fieldlabels">
                                                  End date
                                                </label>
                                                <input
                                                  type="date"
                                                  name={`enddate${degree.id}`}
                                                  value={
                                                    degree?.enddate
                                                      ? degree.enddate.split(
                                                        "T"
                                                      )[0]
                                                      : ""
                                                  }
                                                  required
                                                  min={
                                                    degree?.startdate
                                                      ? degree.startdate.split(
                                                        "T"
                                                      )[0]
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    const selectedEndDate =
                                                      e.target.value;
                                                    const updatedDegrees = [
                                                      ...AddBachelorDegree,
                                                    ];
                                                    const selectedStartDate =
                                                      updatedDegrees[index]
                                                        .startdate;
                                                    if (
                                                      selectedStartDate &&
                                                      selectedEndDate.length ===
                                                      10
                                                    ) {
                                                      if (
                                                        new Date(
                                                          selectedEndDate
                                                        ) <=
                                                        new Date(
                                                          selectedStartDate
                                                        )
                                                      ) {
                                                        alert(
                                                          "End date must be greater than start date."
                                                        );
                                                        return;
                                                      }
                                                    }
                                                    updatedDegrees[
                                                      index
                                                    ].enddate = selectedEndDate;
                                                    setBachelor_End_date(
                                                      updatedDegrees
                                                    );
                                                  }}
                                                  placeholder="end date"
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                      </div>
                                    ))}
                                    <div className="float-right mb-3">
                                      <Link
                                        to="#"
                                        id="add"
                                        onClick={HandleAddEductions}
                                        className="add2 default-btn cursor-pointer p-0 rounded px-3 py-2"
                                      >
                                        Add +
                                      </Link>
                                    </div>

                                    <hr />
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      {AddBachelorDegree[0]?.status === true ||
                                        (university &&
                                          degreename &&
                                          degreetype &&
                                          startdate &&
                                          enddate) ? (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleNext();
                                            HandleEduction(e);
                                          }}
                                          id="sf-next"
                                          type="submit"
                                          className="btn default-btn"
                                        >
                                          Next
                                        </button>
                                      ) : (
                                        <button
                                          id="sf-next"
                                          type="submit"
                                          disabled
                                          className="btn default-btn"
                                        >
                                          Next
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </ul>
                            )}
                            {/* <------ Certifications Sections -------------> */}
                            {is_current_work == "2" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp d-flex align-items-center justify-content-between">
                                    <div>
                                      <h4>Certifications: (Optionals) </h4>
                                      <h6>
                                        Fill all form field to go to next step
                                      </h6>
                                    </div>
                                    <div className="float-right mb-3">
                                      <Link
                                        to="#"
                                        id="add"
                                        onClick={HandleAddCertifications}
                                        className="btn-lg default-btn py-2 px-3 cursor-pointer rounded"
                                      >
                                        Add Certification +
                                      </Link>
                                    </div>
                                  </div>
                                  {AddCertifications?.map((degree, index) => {
                                    return (
                                      <>
                                        <hr />
                                        <div className="professional-sp row">
                                          <div className="text-end">
                                            {index !== 0 && (
                                              <IoClose
                                                className="text-end"
                                                style={{
                                                  color: "red",
                                                  fontSize: "2rem",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  HandleRemoveCertifications(
                                                    degree.id
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label className="fieldlabels">
                                                Certification
                                              </label>
                                              <input
                                                type="text"
                                                name="certificate"
                                                value={degree?.certificate}
                                                onChange={(e) => {
                                                  const updatedDegrees = [
                                                    ...AddCertifications,
                                                  ];
                                                  updatedDegrees[
                                                    index
                                                  ].certificate =
                                                    e.target.value;
                                                  setCertification(
                                                    updatedDegrees
                                                  );
                                                }}
                                                placeholder="Certification"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                              <label className="fieldlabels">
                                                Expiration Date
                                              </label>
                                              <input
                                                type="date"
                                                name="certification_date"
                                                value={
                                                  degree?.certification_date
                                                    ? new Date(
                                                      degree?.certification_date
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  const updatedDegrees = [
                                                    ...AddCertifications,
                                                  ];
                                                  updatedDegrees[
                                                    index
                                                  ].certification_date =
                                                    e.target.value;
                                                  setCertificate_Expiration_Date(
                                                    updatedDegrees
                                                  );
                                                }}
                                                placeholder="Expire Date"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group">
                                              <label className="fieldlabels">
                                                Organization
                                              </label>
                                              <input
                                                type="text"
                                                name="organication"
                                                value={degree?.organication}
                                                onChange={(e) => {
                                                  const updatedDegrees = [
                                                    ...AddCertifications,
                                                  ];
                                                  updatedDegrees[
                                                    index
                                                  ].organication =
                                                    e.target.value;
                                                  setCertificate_Organization(
                                                    updatedDegrees
                                                  );
                                                }}
                                                placeholder="Organization"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div className="sf-steps-navigation d-flex justify-content-between">
                                    <button
                                      onClick={handlePrevious}
                                      id="sf-prev"
                                      type="button"
                                      className="btn btn-light"
                                    >
                                      Previous
                                    </button>
                                    <span
                                      id="sf-msg"
                                      className="sf-msg-error"
                                    ></span>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // handleNext();
                                        HandleCertification(e); // Validate and submit
                                      }} // Use the updated handler
                                      id="sf-next"
                                      type="submit"
                                      className="btn default-btn"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </ul>
                            )}
                            {/* <------ Skills Sections -------------> */}
                            {is_current_work == "3" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Skills Updates</h4>
                                    <h6>
                                      Fill all form field to go to next step
                                    </h6>
                                  </div>
                                  <hr />
                                  {/* <---------- Add Skills Sections -------- */}
                                  <div className="professional-sp">
                                    <label className="fieldlabels">
                                      Skills
                                    </label>
                                    {AddSkills?.map((degree, index) => {
                                      return (
                                        <>
                                          <div className="row">
                                            <div className="text-end">
                                              {index !== 0 && (
                                                <IoClose
                                                  className="text-end"
                                                  style={{
                                                    color: "red",
                                                    fontSize: "2rem",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    HandleRemoveSkills(
                                                      degree.id
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="m-0"
                                                  value={degree?.skill}
                                                  name="skill"
                                                  onChange={(e) => {
                                                    const updatedDegrees = [
                                                      ...AddSkills,
                                                    ];
                                                    updatedDegrees[
                                                      index
                                                    ].skill = e.target.value;
                                                    setSkills(updatedDegrees);
                                                  }}
                                                  placeholder="Skills"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <select
                                                  name="expertise"
                                                  value={degree?.expertise}
                                                  onChange={(e) => {
                                                    const updatedDegrees = [
                                                      ...AddSkills,
                                                    ];
                                                    updatedDegrees[
                                                      index
                                                    ].expertise =
                                                      e.target.value;
                                                    setSelect_Expertise(
                                                      updatedDegrees
                                                    );
                                                  }}
                                                >
                                                  <option>
                                                    Select Expertise
                                                  </option>
                                                  <option value="Beginner">
                                                    Beginner
                                                  </option>
                                                  <option value="Intermediate">
                                                    Intermediate
                                                  </option>
                                                  <option value="Advanced">
                                                    Advanced
                                                  </option>
                                                  <option value="Expert">
                                                    Expert
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    })}
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      {AddSkills[0]?.status === true ||
                                        (skill && expertise) ? (
                                        <>
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleNext();
                                              HandleSkills(e);
                                            }}
                                            id="sf-next"
                                            type="submit"
                                            className="btn default-btn"
                                          >
                                            Next
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            id="sf-next"
                                            type="submit"
                                            className="btn default-btn"
                                            disabled
                                          >
                                            Next
                                          </button>
                                        </>
                                      )}
                                    </div>
                                    <div className="items2 mb-3"></div>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={HandleAddSkills}
                                      className="default-btn rounded"
                                    >
                                      Add +
                                    </a>
                                  </div>
                                </div>
                              </ul>
                            )}
                            {/* <------ University Projects Sections -------------> */}
                            {is_current_work == "4" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>University Projects </h4>
                                    <h6>
                                      Fill all form field to go to next step
                                    </h6>
                                  </div>

                                  <div className="professional-sp row">
                                    {AddUniversityProjects?.map(
                                      (degree, index) => {
                                        return (
                                          <>
                                            <div className="row">
                                              <div className="text-end">
                                                {index !== 0 && (
                                                  <IoClose
                                                    className="text-end"
                                                    style={{
                                                      color: "red",
                                                      fontSize: "2rem",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      HandleRemoveUniversityProjects(
                                                        degree.id
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                  <label className="fieldlabels">
                                                    Project title
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="m-0"
                                                    name="title"
                                                    value={degree.title}
                                                    onChange={(e) => {
                                                      const updatedDegrees = [
                                                        ...AddUniversityProjects,
                                                      ];
                                                      updatedDegrees[
                                                        index
                                                      ].title = e.target.value;
                                                      setProject_title(
                                                        updatedDegrees
                                                      );
                                                    }}
                                                    placeholder="Project title"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                  <label className="fieldlabels">
                                                    Project Link
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="m-0"
                                                    name="websitelink"
                                                    value={degree?.websitelink}
                                                    onChange={(e) => {
                                                      const updatedDegrees = [
                                                        ...AddUniversityProjects,
                                                      ];
                                                      updatedDegrees[
                                                        index
                                                      ].websitelink =
                                                        e.target.value;
                                                      setWebsite_Link(
                                                        updatedDegrees
                                                      );
                                                    }}
                                                    placeholder="Project Link"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                  <label className="fieldlabels">
                                                    Role
                                                  </label>
                                                  <textarea
                                                    className="m-0"
                                                    name="yourrole"
                                                    value={degree?.yourrole}
                                                    onChange={(e) => {
                                                      const updatedDegrees = [
                                                        ...AddUniversityProjects,
                                                      ];
                                                      updatedDegrees[
                                                        index
                                                      ].yourrole =
                                                        e.target.value;
                                                      setProject_role(
                                                        updatedDegrees
                                                      );
                                                    }}
                                                    placeholder="Project Role"
                                                    rows={4}
                                                    cols={50}
                                                  />
                                                </div>
                                              </div>
                                              <hr />
                                            </div>
                                          </>
                                        );
                                      }
                                    )}

                                    <div className="col-md-4">
                                      <div className=" mb-3"></div>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={HandleAddUniversityProjects}
                                        className="default-btn rounded"
                                      >
                                        Add +
                                      </a>
                                    </div>
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      {/* <button
    onClick={(e) => {
      e.preventDefault();
      handleNext();
      HandleUniversityProjects(e); // Validate and submit
    }}
    id="sf-next"
    type="submit"
    className="btn default-btn"
  >
    Next
  </button> */}
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          HandleUniversityProjects(e);
                                        }}
                                        id="sf-next"
                                        type="submit"
                                        className="btn default-btn"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            )}
                            {/* <------ Professional experience Sections -------------> */}
                            {is_current_work == "5" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Professional Experience</h4>
                                    <h6>
                                      Fill all form fields to go to the next
                                      step
                                    </h6>
                                  </div>
                                  <hr />
                                  <form className="professional-sp">
                                    {/* Iterate through each professional experience */}
                                    {AddExperience?.map((experience, index) => (
                                      <div className="experience-fields" key={experience.id}>
                                        <div className="text-end">
                                          {index !== 0 && (
                                            <IoClose className="text-end" style={{
                                              color: "red", fontSize: "2rem", cursor: "pointer",
                                            }}
                                              onClick={() => handleRemoveExperience(experience.id)} />)}
                                        </div>

                                        {/* Title */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">
                                              Title*
                                            </label>
                                            <input type="text" value={experience?.p_title} name="p_title" onChange={(e) => {
                                              const updatedDegrees = [...AddExperience,]; updatedDegrees[index].p_title = e.target.value; setProfessional_Title(updatedDegrees);
                                            }}
                                              placeholder="Retail Sales Manager"
                                              className="form-control"
                                            />
                                          </div>
                                        </div>

                                        {/* Employment Type */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">
                                              Employment Type
                                            </label>
                                            <select className="form-control" name="emp_type" value={experience?.emp_type}
                                              onChange={(e) => {
                                                const updatedDegrees = [...AddExperience,];
                                                updatedDegrees[index].emp_type = e.target.value; setProfessional_Employment_type(updatedDegrees);
                                              }}
                                            >
                                              <option>
                                                Select Employment Type
                                              </option>
                                              <option value="Full time">Full time</option>
                                              <option value="Part-time">Part-time</option>
                                              <option value="Freelance">Freelance</option>
                                              <option value="Trainee">Trainee</option>
                                            </select>
                                          </div>
                                        </div>

                                        {/* Company Name */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">Company Name*</label>
                                            <input type="text" placeholder="Microsoft" name="company" value={experience.company}
                                              onChange={(e) => {
                                                const updatedDegrees = [...AddExperience,];
                                                updatedDegrees[index].company = e.target.value;
                                                setProfessional_Company(updatedDegrees);
                                              }} className="form-control" />
                                          </div>
                                        </div>

                                        {/* Location */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">Location* </label>
                                            <input type="text" placeholder="London, United Kingdom" name="location"
                                              value={experience.location}
                                              onChange={(e) => {
                                                const updatedDegrees = [...AddExperience,];
                                                updatedDegrees[index].location = e.target.value;
                                                setProfessional_Company_Location(updatedDegrees);
                                              }}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>

                                        {/* Location Type */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">Location Type*</label>
                                            <div className="d-flex gap-4">
                                              <div className="form-check checkiform">
                                                <input className="form-check-input" type="radio" name="location_type"
                                                  value="On-site"
                                                  onChange={(e) => {
                                                    const updatedExperience = [...AddExperience,];
                                                    updatedExperience[index].location_type = e.target.value;
                                                    setProfessional_Company_Location_type(updatedExperience);
                                                  }}
                                                  checked={experience.location_type == "On-site"} />
                                                <label className="form-check-label">On-site</label>
                                              </div>

                                              <div className="form-check checkiform">
                                                <input className="form-check-input" type="radio" name="location_type" value="Hybrid"
                                                  onChange={(e) => {
                                                    const updatedExperience = [...AddExperience,];
                                                    updatedExperience[index].location_type = e.target.value; setProfessional_Company_Location_type(updatedExperience);
                                                  }}
                                                  checked={experience.location_type == "Hybrid"} />
                                                <label className="form-check-label">Hybrid</label>
                                              </div>

                                              <div className="form-check checkiform">
                                                <input className="form-check-input" type="radio" name="location_type" value="Remote"
                                                  onChange={(e) => {
                                                    const updatedExperience = [...AddExperience,];
                                                    updatedExperience[index].location_type = e.target.value; setProfessional_Company_Location_type(updatedExperience);
                                                  }} checked={experience.location_type == "Remote"}
                                                />
                                                <label className="form-check-label">Remote</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="form-group">
                                            <div className="agree-label d-flex gap-2">
                                              <input
                                                type="checkbox"
                                                name="currently_working"
                                                checked={AddExperience[index]?.currently_working === 1} // Checkbox is checked if currently_working is 1
                                                onChange={(e) => handleCurrentlyWorkingChange(index, e.target.checked)} // Handle checkbox change
                                                id={`chb${index}`}
                                                className="termcheck"
                                              />
                                              <label htmlFor={`chb${index}`} className="small text-muted">
                                                I am currently working in this role
                                              </label>
                                            </div>
                                          </div>

                                        </div>
                                        <div className="w-100">
                                          <label className="fieldlabels">Start Date</label>
                                          <input type="date" name="start_date" value={experience.start_date ? formatToISODate(formatDateToDDMMYYYY(experience.start_date)) : ""
                                          }
                                            onChange={(e) => handleProfessionalStartDateChange(e, index)} placeholder="Start Date"
                                            onKeyDown={(e) => e.preventDefault()} />
                                        </div>
                                        <div className="w-100">
                                          <label className="fieldlabels">End Date</label>
                                          <input
                                            type="date"
                                            name="end_date"
                                            value={experience?.end_date ? formatToISODate(formatDateToDDMMYYYY(experience.end_date)) : ""}
                                            onChange={(e) => handleProfessionalEndDateChange(e, index)} // Handle end date change
                                            placeholder="End Date"
                                            min={experience.start_date ? experience.start_date : getCurrentDateISO()}
                                            onKeyDown={(e) => e.preventDefault()} // Prevent manual date entry
                                          />
                                        </div>
                                        {/* Industry */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">Industry*</label>
                                            <input type="text" placeholder="Information Technology" name="industry" value={experience.industry}
                                              onChange={(e) => {
                                                const updatedDegrees = [...AddExperience,]; updatedDegrees[index].industry = e.target.value;
                                                setProfessional_Industry(updatedDegrees);
                                              }} className="form-control"
                                            />
                                          </div>
                                        </div>

                                        {/* Job Description */}
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label className="fieldlabels">Job Description</label>
                                            <input type="text" placeholder="Job description" name="job_desc" value={experience?.job_desc}
                                              onChange={(e) => {
                                                const updatedDegrees = [...AddExperience,];
                                                updatedDegrees[index].job_desc = e.target.value; setJob_Desc(updatedDegrees);
                                              }}
                                              className="form-control" />
                                          </div>
                                        </div>

                                        <hr />
                                      </div>
                                    ))}

                                    {/* Add Experience Button */}
                                    {/* <div className="float-right mb-3">
                                      <Link
                                        to="#"
                                        id="add"
                                        onClick={handleAddExperience}
                                        className="add2 default-btn cursor-pointer p-0 rounded px-3 py-2"
                                      >
                                        Add +
                                      </Link>
                                    </div> */}

                                    <hr />

                                    {/* Navigation Buttons */}
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      {/* Conditional Next Buttons based on form status */}
                                      {!isAnyFieldFilledInExperience() ? (
                                        // If no fields are filled, allow skipping with Next1
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleNext();
                                            HandleProfessionalexperienceEmptyFeilds(e); // No form data to save
                                          }}
                                          id="sf-next"
                                          type="submit"
                                          className="btn default-btn"
                                        >
                                          Next
                                        </button>
                                      ) : (
                                        // If at least one field is filled, check if all required fields are filled
                                        areFieldsFilledInExperience() ? (
                                          // If all required fields are filled, allow submission with Next2
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              HandleProfessionalexperience(e);
                                            }}
                                            id="sf-next"
                                            type="submit"
                                            className="btn default-btn"
                                          >
                                            Next
                                          </button>
                                        ) : (
                                          // If some fields are missing, show an error toast without submitting
                                          <button
                                            onClick={handleIncompleteForm}
                                            id="sf-next"
                                            type="button"
                                            className="btn default-btn"
                                          >
                                            Next
                                          </button>
                                        )
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </ul>
                            )}
                            {/* <------ Other Sections -------------> */}
                            {is_current_work == "6" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>PII - 1 </h4>
                                    <h6>
                                      Fill all form field to go to next step
                                    </h6>
                                  </div>
                                  <hr />
                                  <div className="professional-sp row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">
                                          DOB
                                        </label>
                                        <input
                                          type="date"
                                          value={
                                            DOB
                                              ? new Date(DOB)
                                                .toISOString()
                                                .split("T")[0]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setDOB(e.target.value)
                                          }
                                          name="DOB"
                                          placeholder="dob"
                                          max={maxDateString}
                                          required
                                        />
                                        {DOB && new Date(DOB) > maxDate && (
                                          <p style={{ color: "red" }}>
                                            You must be at least 18 years old.
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">
                                          Select Nationality:
                                        </label>
                                        <select
                                          id="country-select"
                                          value={selectNationlity}
                                          onChange={handleNationlityChange}
                                          className="form-control"
                                        >
                                          <option value="">
                                            --Select a Nationality--
                                          </option>
                                          {countries?.map((country, index) => (
                                            <option key={index} value={country}>
                                              {country}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <button
                                        onClick={handlePrevious}
                                        id="sf-prev"
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        Previous
                                      </button>
                                      <span
                                        id="sf-msg"
                                        className="sf-msg-error"
                                      ></span>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (DOB) {
                                            HandleOtherSections(e);
                                            handleNext();
                                          }
                                        }}
                                        id="sf-next"
                                        type="submit"
                                        className="btn default-btn"
                                        disabled={!DOB || !selectNationlity}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            )}

                            {/* <------ Work Authorization Sections -------------> */}
                            {is_current_work == "7" && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>PII - 2 </h4>
                                    <h6>
                                      Fill all form field to go to next step
                                    </h6>
                                  </div>
                                  <hr />
                                  <div className="professional-sp row">
                                    <div className="col-md-12">
                                      <div className="form-group"></div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <div className="visa-options-form">
                                          <label className="small text-muted">
                                            Work Authorization
                                          </label>
                                          <select
                                            name="citizen"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            className="form-select"
                                          >
                                            <option value="">Select</option>
                                            <option value="Citizen">
                                              Citizen{" "}
                                            </option>
                                            <option value="Green Card">
                                              Green Card
                                            </option>
                                            <option value="Student Visa">
                                              Student Visa
                                            </option>
                                            <option value="Other">Other</option>
                                          </select>

                                          {/* Show input box if 'Other' is selected */}
                                          {selectedOption === "Other" && (
                                            <div className="mt-2">
                                              <label
                                                htmlFor="otherInput"
                                                className="small text-muted"
                                              >
                                                Please specify:
                                              </label>
                                              <input
                                                type="text"
                                                id="otherInput"
                                                value={otherInput}
                                                onChange={(e) =>
                                                  setOtherInput(e.target.value)
                                                }
                                                placeholder="Enter"
                                                className="form-control"
                                              />
                                            </div>
                                          )}
                                        </div>

                                        <div className="ethnicity-race-form">
                                          <label className="small text-muted">
                                            Select your Ethnicity/Race:
                                          </label>
                                          <select
                                            value={selectedEthnicityOption}
                                            name="ethnicity"
                                            onChange={
                                              handleEthnicitySelectChange
                                            }
                                            className="form-select"
                                          >
                                            <option value="">Select</option>
                                            <option value="American Indian or Alaska Native">
                                              American Indian or Alaska Native
                                            </option>
                                            <option value="Asian">Asian</option>
                                            <option value="Asian (South)">
                                              Asian (South)
                                            </option>
                                            <option value="Asian (Chinese)">
                                              Asian (Chinese)
                                            </option>
                                            <option value="Black or African American">
                                              Black or African American
                                            </option>
                                            <option value="Indian (Asian)">
                                              Indian (Asian)
                                            </option>
                                            <option value="Hispanic">
                                              Hispanic
                                            </option>
                                            <option value="Native Hawaiian or other Pacific Islander">
                                              Native Hawaiian or other Pacific
                                              Islander
                                            </option>
                                            <option value="White">White</option>
                                            <option value="Other">Other</option>
                                          </select>

                                          {/* Show input box if 'Other' is selected */}
                                          {selectedEthnicityOption ===
                                            "Other" && (
                                              <div className="mt-2">
                                                <label
                                                  htmlFor="otherEthnicityInput"
                                                  className="small text-muted"
                                                >
                                                  Please specify:
                                                </label>
                                                <input
                                                  type="text"
                                                  id="otherEthnicityInput"
                                                  value={otherEthnicityInput}
                                                  onChange={(e) =>
                                                    setOtherEthnicityInput(
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Enter Ethnicity/Race"
                                                  className="form-control"
                                                />
                                              </div>
                                            )}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                          <div className="form-group">
                                            <div className="agree-label d-flex gap-2">
                                              <input
                                                type="checkbox"
                                                id="Consent"
                                                className="termcheck"
                                                checked={isChecked_Other} // Bind checked property to isChecked_Other
                                                onChange={() =>
                                                  setisChecked_Other(
                                                    !isChecked_Other
                                                  )
                                                } // Toggle state on change
                                              />
                                              <label
                                                htmlFor="Consent"
                                                className="small text-muted"
                                              >
                                                We will help you to find a job
                                                if you complete the training
                                                program successfully, so please
                                                consent to this form. "I
                                                authorize FreshGrad to submit my
                                                profiles/resume and contact
                                                details to other companies."
                                              </label>
                                            </div>
                                            {!isChecked_Other && (
                                              <p style={{ color: "red" }}>
                                                Please accept the terms and
                                                conditions before proceeding to
                                                the next page.
                                              </p>
                                            )}
                                          </div>
                                        </div>

                                        {/* ... Rest of your form elements ... */}

                                        <div className="sf-steps-navigation d-flex justify-content-between">
                                          <button
                                            onClick={handlePrevious}
                                            id="sf-prev"
                                            type="button"
                                            className="btn btn-light"
                                          >
                                            Previous
                                          </button>
                                          <span
                                            id="sf-msg"
                                            className="sf-msg-error"
                                          ></span>
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              HandleWorkAuthorizationSections(
                                                e
                                              );
                                            }}
                                            id="sf-next"
                                            type="submit"
                                            className="btn default-btn"
                                            disabled={
                                              !isChecked_Other ||
                                              (!selectedOption &&
                                                !otherInput) ||
                                              (selectedOption === "Other" &&
                                                !otherInput) ||
                                              (!selectedEthnicityOption &&
                                                !otherEthnicityInput) ||
                                              (selectedEthnicityOption ===
                                                "Other" &&
                                                !otherEthnicityInput)
                                            }
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <--------- T & C Modal section's ------------> */}
              <Modal
                size="lg"
                show={ShowTermsCondition}
                onHide={() => setShowTermsCondition(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row pt-1">
                      <div className="col-lg-12">
                        <div className="single-content">
                          <h3>{TermsConditionList?.title}</h3>
                          <ul>
                            <p>
                              {ReactHtmlParser(TermsConditionList?.desciption)}
                            </p>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* <--------- Privacy & Policy section's ------------> */}
              <Modal
                size="lg"
                show={showPrivacyPolicy}
                onHide={() => setShowPrivacyPolicy(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Privacy & Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row pt-1">
                      <div className="col-lg-12">
                        <div className="single-content">
                          <h3>{PrivacyPolicyList?.title}</h3>
                          <ul>
                            <p>
                              {ReactHtmlParser(PrivacyPolicyList?.desciption)}
                            </p>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Student_Step;
