import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import CommonImageTransport from "../../../common/images/CommonImages";
import Modal from "react-bootstrap/Modal";
import TagsInput from "react-tagsinput";
import moment from "moment-timezone";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  GetRecuriterTeamMemeberList,
  PostInterviewRounds,
  Postinterviewroundfeedback,
  CompleteInterviewRound,
} from "../../../../api/Recuriter";
import "react-tagsinput/react-tagsinput.css";
import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Recuriter_Behaviral_Interviewing = ({ GetInterviewWithRounds }) => {
  // <----------- Behaviral Interview Feedback Modal Open & Close ------------------->
  const [BehaviralInterviewFeedbackShow, setBehaviralInterviewFeedbackShow] =
    useState(false);
  const [ScheduleBehaviralInterviewShow, setScheduleBehaviralInterviewShow] =
    useState(false);
  const [
    showEditBehaviralInterviewFeedbackShow,
    setShowEditBehaviralInterviewFeedbackShow,
  ] = useState(false);
  const [AddMoreBehaviralInterviewShow, setAddMoreBehaviralInterviewShow] =
    useState(false);
  const [
    BehaviralInterviewFinishModalShow,
    setBehaviralInterviewFinishModalShow,
  ] = useState(false);

  // <---------------- Schedule The Behaviral Interview Sections --------------->
  const [
    ScheduleBehavrialInterviewselectedOptions,
    setScheduleBehavrialInterviewselectedOptions,
  ] = useState([]);
  const [
    ScheduleBehavrialInterviewFromDate,
    setScheduleBehavrialInterviewFromDate,
  ] = useState([]);
  const [
    ScheduleBehavrialInterviewToDate,
    setScheduleBehavrialInterviewToDate,
  ] = useState([]);
  const [ScheduleBehaviralInterviewLink, setScheduleBehaviralInterviewLink] =
    useState([]);
  const [
    ScheduleBehaviralInterviewFromScheduledAt,
    setScheduleBehaviralInterviewFromScheduledAt,
  ] = useState("");
  const [
    ScheduleBehaviralInterviewToScheduledAt,
    setScheduleBehaviralInterviewToScheduledAt,
  ] = useState("");

  // <--------------------- Behaviral Interview Feedback sections ------------------->
  const [
    FeedbackBehaviralInterviewSkills,
    setFeedbackBehaviralInterviewSkills,
  ] = useState([]);
  const [
    feedbackbehaviralinterviewstatus,
    setFeedbackBehaviralinterviewstatus,
  ] = useState("");
  const [
    FeedbackBehaviralInterviewGiveFeedback,
    setFeedbackBehaviralInterviewGiveFeedback,
  ] = useState([]);
  const [
    feedbackbehaviralFeedbackrecordedLink,
    setFeedbackbehaviralFeedbackrecordedLink,
  ] = useState("");
  const [rating, setRating] = useState(0);

  // <------------- Add More Behaviral Interview Sections ------------------->
  const [addMoreSelectedOptions, setAddMoreSelectedOptions] = useState([]);
  const [AddMoreBehavrialInterviewLink, setAddMoreBehavrialInterviewLink] =
    useState([]);
  const [
    AddMoreBehaviralInterviewFromDate,
    setAddMoreBehaviralInterviewFromDate,
  ] = useState([]);
  const [
    AddMoreBehaviralInterviewFromScheduledAt,
    setAddMoreBehaviralInterviewFromScheduledAt,
  ] = useState("");
  const [AddMoreBehaviralInterviewToDate, setAddMoreBehaviralInterviewToDate] =
    useState([]);
  const [
    AddMoreBehaviralInterviewToScheduledAt,
    setAddMoreBehaviralInterviewToScheduledAt,
  ] = useState("");

  // <---------------------- Get the Student Details with the Rounds here (Like Technical , Behaviral & Decision) -------------------->
  const [interviewWithRounds, setInterviewRounds] = useState([]);
  console.log("interviewWithRounds", interviewWithRounds[0]?.interviewdetails);

  // <-------------- Show More & Less Feedback here --------------->
  const [openBehavirolAccordions, setOpenBehavirolAccordions] = useState({});
  const [showMoreBehavirolContent, setShowMoreBehavirolContent] = useState({});
  const [GetInterviewer, setGetInterviewer] = useState([]);
  const [specificInterviewId, setSpecificInterviewId] = useState();
  const [InterviewIdinterviewWithRounds, setInterviewIdInterviewRounds] =
    useState("");

  // <---------- Edit Behaviral Interview Sections here ---------------->
  const [EditFeedbackStatus, setEditFeedbackStatus] = useState("");
  const [EditFeedbackStarRatting, setEditFeedbackStarRatting] = useState("");
  const [EditFeedbackSkills, setEditFeedbackSkills] = useState([]);
  const [EditFeedbackRecordedLink, setEditFeedbackRecordedLink] = useState("");
  const [EditFeedbackGiveFeedback, setEditFeedbackGiveFeedback] = useState("");

  // <---------- User Authentication Token here -------------->
  const token = localStorage.getItem("User_Token");
  const id = localStorage.getItem("GetInterviewWithRounds_id");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <---------- Toggle  Functionlity on the Behavirol interview Accordtion section's ------------->
  const BehaviroltoggleAccordion = (key) => {
    setOpenBehavirolAccordions((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  // <------------- Toggle For Show More & Less Functionlity on the Behavirol interview Accordtion section's ------------->
  const BehaviroltoggleTechShowMore = (key) => {
    setShowMoreBehavirolContent((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  //  <----------- retrive the present date ---------------->
  const PresentDateTime = new Date();

  const contentRef = useRef(null);

  // <----------- Handle the Behaviral Interview Star ----------------->
  const handleBehaviralInterviewStarClick = (value) => {
    setRating(value);
  };

  // <---------- handle Edit Technical Interview Feedback form -------------------->
  const handleEditTechnicalInterviewFeedbackForm = async (e) => {
    e.preventDefault();
    // Validation
    if (!EditFeedbackStarRatting) {
      toast.error("Please provide a star rating.");
      return;
    }

    if (!EditFeedbackStatus) {
      toast.error("Please select 'Hire' or 'Reject'.");
      return;
    }

    if (!EditFeedbackSkills.length) {
      toast.error("Please provide at least one skill.");
      return;
    }

    if (!EditFeedbackGiveFeedback) {
      toast.error("Please provide your feedback.");
      return;
    }

    if (!EditFeedbackRecordedLink) {
      toast.error("Please provide a recorded link.");
      return;
    }

    const PostinterviewroundfeedbackData = {
      id: specificInterviewId,
      rating: EditFeedbackStarRatting,
      skills: EditFeedbackSkills,
      feedback: EditFeedbackGiveFeedback,
      is_feedback: 1,
      is_hire_status: EditFeedbackStatus,
      recordedLink: EditFeedbackRecordedLink,
    };
    const response = await Postinterviewroundfeedback(
      PostinterviewroundfeedbackData,
      headers
    );
    if (response?.data?.statusCode == "200") {
      setShowEditBehaviralInterviewFeedbackShow(false);
      GetRecuriterInterviewWithRounds(id);
    }
  };

  // <---------- Select the Multiple Interviewer in the Add More Behaviral interview sections ------------->
  const addMorehandleChangeOptions = (event) => {
    const {
      target: { value },
    } = event;
    setAddMoreSelectedOptions(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // <---------- Select the Multiple Interviewer in the Schedule Behaviral interview sections ------------->
  const ScheduleBehaviralInterviewhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setScheduleBehavrialInterviewselectedOptions(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // <----------- Date , Time & Time zone in the Add More Behaviral Interview sections -------->
  const AddMoreBehaviralformatDateTimeWithFromTimeZone = (e) => {
    const selectedDateTime = e.target.value;
    const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
    const formattedDisplay = dateObj.format("ddd MMM DD YYYY HH:mm:ss");
    setAddMoreBehaviralInterviewFromScheduledAt(formattedDisplay);
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setAddMoreBehaviralInterviewFromDate(formattedForInput);
  };

  // <----------- Date , Time & Time zone in the Add More Behaviral Interview sections -------->
  const AddMoreBehaviralformatDateTimeWithToTimeZone = (e) => {
    const selectedDateTime = e.target.value;
    const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
    const formattedDisplay = dateObj.format("ddd MMM DD YYYY HH:mm:ss");
    setAddMoreBehaviralInterviewToScheduledAt(formattedDisplay);
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setAddMoreBehaviralInterviewToDate(formattedForInput);
  };

  // <----------- Date , Time & Time zone in the Schedule Behaviral Interview sections -------->
  const scheduleBehaviralformatDateTimeWithFromTimeZone = (e) => {
    const selectedDateTime = e.target.value;
    const dateObj = moment(selectedDateTime).tz(moment.tz.guess());
    const formattedDisplay = dateObj.format("ddd MMM DD YYYY HH:mm:ss");
    setScheduleBehaviralInterviewFromScheduledAt(formattedDisplay);
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setScheduleBehavrialInterviewFromDate(formattedForInput);
  };

  const scheduleBehaviralformatDateTimeWithToTimeZone = (e) => {
    const selectedDateTime = e.target.value;

    if (!selectedDateTime) {
      // If no valid date is selected, set to an empty string or handle as needed
      setScheduleBehavrialInterviewToDate("");
      toast.error("Please select a valid 'Interview To' date.");
      return;
    }

    try {
      // Parse and format the date using moment with timezone detection
      const dateObj = moment(selectedDateTime).tz(moment.tz.guess());

      // Check if date is valid
      if (!dateObj.isValid()) {
        toast.error("Invalid date selected. Please choose a valid date.");
        setScheduleBehavrialInterviewToDate("");
        return;
      }

      // Format the date for display and input values
      const formattedDisplay = dateObj.format("ddd MMM DD YYYY HH:mm:ss");
      const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm");

      // Set the formatted date values
      setScheduleBehaviralInterviewToScheduledAt(formattedDisplay);
      setScheduleBehavrialInterviewToDate(formattedForInput);
    } catch (error) {
      // Log error and handle any unexpected issues
      console.error("Error formatting the selected date:", error);
      toast.error(
        "An error occurred while processing the date. Please try again."
      );
    }
  };

  // <--------- get the All Team Member List here -------------->
  const GetTeamMemeberList = async () => {
    try {
      const response = await GetRecuriterTeamMemeberList(headers);
      setGetInterviewer(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------ handle the Behaviral Interview Feedback Form ------------->
  const handleBehaviralInterviewFeedbackForm = async (e) => {
    e.preventDefault();

    // Check if rating is provided
    if (!rating) {
      toast.error("Please provide a star rating.");
      return false; // Mark form as invalid
    }

    // Check if the interview status (hire or reject) is selected
    if (!feedbackbehaviralinterviewstatus) {
      toast.error("Please select hire or reject.");
      return false;
    }

    // Check if at least one skill is provided
    if (!FeedbackBehaviralInterviewSkills.length) {
      toast.error("Please provide at least one skill.");
      return false;
    }

    // Check if feedback is provided
    if (!FeedbackBehaviralInterviewGiveFeedback) {
      toast.error("Please provide your feedback.");
      return false;
    }

    // Check if a recorded link is provided
    if (!feedbackbehaviralFeedbackrecordedLink) {
      toast.error("Please provide a recorded link.");
      return false;
    }

    const PostinterviewroundfeedbackData = {
      id: specificInterviewId,
      rating: rating,
      skills: FeedbackBehaviralInterviewSkills,
      feedback: FeedbackBehaviralInterviewGiveFeedback,
      is_feedback: 1,
      is_hire_status: feedbackbehaviralinterviewstatus,
      recordedLink: feedbackbehaviralFeedbackrecordedLink,
    };
    const response = await Postinterviewroundfeedback(
      PostinterviewroundfeedbackData,
      headers
    );
    if (response?.data?.statusCode == "200") {
      setBehaviralInterviewFeedbackShow(false);
      setShowEditBehaviralInterviewFeedbackShow(false);
      GetRecuriterInterviewWithRounds(id);
    }
    setFeedbackBehaviralInterviewSkills([]);
    setFeedbackBehaviralinterviewstatus();
    setFeedbackbehaviralFeedbackrecordedLink();
    setRating();
    setFeedbackBehaviralInterviewGiveFeedback();
  };

  // <------------ get the Interviewr id From the Selected Options ----------------->
  const getIdByName = (objects, ids) => {
    return ids
      .map((id) => {
        const obj = objects.find((obj) => obj.id === id);
        return obj ? obj.id : null; // Returns the ID if found, else null
      })
      .filter((id) => id !== null); // Filter out null values
  };

  // <----------- Get the Last id and Interview id form the Last Feedback sections ------------->
  const HandleAddMoreTechnicalInterviewIdSections = (details) => {
    const technicalDetails = details?.filter(
      (detail) => detail?.roundtype === "Behavioral"
    );
    const technicalIds = technicalDetails?.map((detail) => detail.id);
    const interviewid = technicalDetails?.map((detail) => detail.interviewid);
    if (technicalIds.length > 0) {
      const lastId = technicalIds[technicalIds.length - 1];
      const interview = interviewid[interviewid.length - 1];
      setInterviewIdInterviewRounds(interview);
    }
  };

  // <---------- handle Add More Behaviral interview sections  form -------------------->
  const HandleAddMoreBehaviralInterview = async (e) => {
    e.preventDefault();

    // Validate interviewer selection
    if (addMoreSelectedOptions.length === 0) {
      toast.error(
        "Please select at least one interviewer for the behavioral interview."
      );
      return;
    }

    // Validate 'Interview From' date
    if (!AddMoreBehaviralInterviewFromDate) {
      toast.error("Please provide the 'Interview From' date.");
      return;
    }

    // Validate 'Interview To' date
    if (!AddMoreBehaviralInterviewToDate) {
      toast.error("Please provide the 'Interview To' date.");
      return;
    }

    // Validate interview link
    if (
      !AddMoreBehavrialInterviewLink ||
      typeof AddMoreBehavrialInterviewLink !== "string" ||
      !AddMoreBehavrialInterviewLink.trim()
    ) {
      toast.error(
        "Please provide a valid interview link (e.g., https://meet.google.com/...)."
      );
      return;
    }

    // Prepare data for submission
    const ids = getIdByName(GetInterviewer, addMoreSelectedOptions);
    const PostBehaviralInterviewData = {
      lastId: specificInterviewId,
      interviewerID: ids,
      interviewId: InterviewIdinterviewWithRounds,
      roundType: "Behavioral",
      roundNumber: 1,
      scheduledAt: AddMoreBehaviralInterviewFromScheduledAt,
      scheduledAtTo: AddMoreBehaviralInterviewToScheduledAt,
      interviewLink: AddMoreBehavrialInterviewLink.trim(),
    };

    // Submit data
    const response = await PostInterviewRounds(
      PostBehaviralInterviewData,
      headers
    );
    if (response?.data?.statusCode === "200") {
      GetRecuriterInterviewWithRounds(id);
      // toast.success("Behavioral interview added successfully!");
      setAddMoreBehaviralInterviewShow(false);
    }

    // Reset form fields
    setAddMoreBehavrialInterviewLink("");
    setAddMoreBehaviralInterviewFromDate([]);
    setAddMoreBehaviralInterviewToDate([]);
    setAddMoreSelectedOptions([]);
  };

  // <---------- handle Secdeule Behavrial sections form -------------------->
  const HandleScheduleBehaviralInterview = async (e) => {
    e.preventDefault();
    // Validation checks
    if (!ScheduleBehavrialInterviewselectedOptions.length) {
      toast.error("Please select at least one interviewer");
      return;
    }
    if (!ScheduleBehaviralInterviewFromScheduledAt) {
      toast.error("Please select the 'Interview From' date and time");
      return;
    }
    if (!ScheduleBehaviralInterviewToScheduledAt) {
      toast.error("Please select the 'Interview To' date and time");
      return;
    }
    const urlPattern = /^https?:\/\/([\w-]+\.)+[\w-]+(\/[\w-]*)*$/;
    // Validate the TechInterviewLink
    if (!urlPattern.test(ScheduleBehaviralInterviewLink)) {
      toast.error("Please enter a valid interview link!");
      return;
    }
    // Validate that the 'Interview To' date is after the 'Interview From' date
    if (
      new Date(ScheduleBehavrialInterviewFromDate) >=
      new Date(ScheduleBehavrialInterviewToDate)
    ) {
      toast.error(
        "'Interview To' date must be later than 'Interview From' date"
      );
      return;
    }
    const ids = getIdByName(
      GetInterviewer,
      ScheduleBehavrialInterviewselectedOptions
    );
    e.preventDefault();
    const PostTechnicalInterviewData = {
      interviewerID: ids,
      interviewId: id,
      roundType: "Behavioral", //'Technical', 'Behavioral', 'Decision'
      roundNumber: 1,
      scheduledAt: ScheduleBehaviralInterviewFromScheduledAt,
      scheduledAtTo: ScheduleBehaviralInterviewToScheduledAt,
      interviewLink: ScheduleBehaviralInterviewLink,
    };
    const response = await PostInterviewRounds(
      PostTechnicalInterviewData,
      headers
    );
    if (response?.data?.statusCode === "200") {
      GetRecuriterInterviewWithRounds(id);
      setScheduleBehaviralInterviewShow(false);
    }
    setScheduleBehaviralInterviewLink("");
    setScheduleBehavrialInterviewToDate([]);
    setScheduleBehavrialInterviewFromDate([]);
    setAddMoreSelectedOptions([]);
  };

  // <--------- Rating on the Feedback & Edit Feedback sections ----------------->
  const handleEditBehavirolFeedbackStarClick = (value) => {
    setEditFeedbackStarRatting(value);
  };

  // <------ get interview With Round ------------->
  const GetRecuriterInterviewWithRounds = async (id) => {
    const GetInterviewWithRoundsdata = {
      id,
    };
    const response = await GetInterviewWithRounds(
      GetInterviewWithRoundsdata,
      headers
    );
    setInterviewRounds(response?.data?.data);
  };

  // <------------ complete interview round -------------->
  const Handlecompleteinterviewround = async () => {
    const CompleteInterviewRoundData = {
      id: specificInterviewId,
    };
    const response = await CompleteInterviewRound(
      CompleteInterviewRoundData,
      headers
    );
    if (response?.data?.statusCode == "200") {
      GetRecuriterInterviewWithRounds(id);
      setBehaviralInterviewFinishModalShow(false);
    }
  };

  // <---------------- Get the Interview With Rounds --------------------->
  useEffect(() => {
    const id = localStorage.getItem("GetInterviewWithRounds_id");
    if (id) {
      GetRecuriterInterviewWithRounds(id);
    }
  }, [id]);

  // <--------- Get the Student Applied Jobs sections ----------->
  useEffect(() => {
    GetTeamMemeberList();
  }, []);

  // Filter the interview rounds to only include Behavioral rounds
  const behavioralInterviews = interviewWithRounds?.flatMap(
    (interviewWithRoundRes) =>
      interviewWithRoundRes?.interviewdetails?.filter(
        (detail) => detail?.roundtype === "Behavioral"
      ) || []
  );
  console.log(
    "behavioralInterviews",
    interviewWithRounds[0]?.interviewdetails[0]?.roundtype
  );
  // <----------------- get the Behaviral interview feedback by id ------------------>
  const handleGetFeedbackById = (id) => {
    const technicalInterviewsById =
      interviewWithRounds[0]?.interviewdetails?.find(
        (detail) => detail?.id === id
      );
    setEditFeedbackStatus(technicalInterviewsById?.is_hire_status);
    setEditFeedbackRecordedLink(technicalInterviewsById?.recordedlink);
    const SkillsArray = technicalInterviewsById?.skills
      .replace(/[{}]/g, "")
      .split(",");
    setEditFeedbackSkills(SkillsArray);
    setEditFeedbackGiveFeedback(technicalInterviewsById?.feedback);
    setEditFeedbackStarRatting(technicalInterviewsById?.rating);
  };

  return (
    <div>
      <div>
        <ToastContainer style={{ marginTop: "120px" }} />
        {
          // Check if any interview has a roundtype of "Behavioral"
          !interviewWithRounds?.some((interview) =>
            interview?.interviewdetails?.some(
              (detail) => detail.roundtype === "Behavioral"
            )
          ) && (
            <div className="firsthire">
              <div className="card">
                <a
                  href="#"
                  className="default-btn rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setScheduleBehaviralInterviewShow(true)}
                >
                  Schedule Interview
                </a>
              </div>
            </div>
          )
        }

        {behavioralInterviews?.length > 0 &&
          behavioralInterviews?.map((detail, idx) => {
            return (
              <div key={idx}>
                {detail?.rating && (
                  <div className="card mt-3">
                    <div className="interviewround1">{idx + 1}</div>
                    <div className="card-body p-0">
                      <div className="profileinterview p-3 d-flex gap-3">
                        <img
                          width="50"
                          className="rounded-pill"
                          height="50"
                          src={CommonImageTransport?.profile1}
                          onError="src='../assets/images/img_avatar.png'"
                          alt=""
                        />
                        <div>
                          Interviewer:-
                          {detail?.interviewers?.map((interviewer, idy) => (
                            <h6 key={idy} className="m-0">
                              {interviewer?.fname} {interviewer?.lname}
                            </h6>
                          ))}
                        </div>

                        <div className="form-check text-success">
                          <label
                            className="form-check-label text-success"
                            htmlFor="flexRadioDefault1"
                          >
                            {detail?.is_hire_status}
                          </label>
                        </div>
                      </div>

                      <div className="header0101 p-3 py-2">
                        <div className="d-flex justify-content-between gap-3">
                          <h6 className="m-0 fw-normal">Recommend </h6>
                          <div className="reviewsreat">
                            <Rating
                              readonly={true}
                              initialValue={detail?.rating}
                              className="custom-star-rating"
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Accordion
                      activeKey={openBehavirolAccordions[idx + 1] ? "1" : null}
                    >
                      <Accordion.Item className="pt-0 view1212" eventKey="1">
                        <Accordion.Header
                          onClick={() => BehaviroltoggleAccordion(idx + 1)}
                          className="btn-sm customebtsns"
                        >
                          {openBehavirolAccordions[idx + 1]
                            ? "View Less"
                            : "View More"}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="text-end mb-2">
                            <a
                              href="#"
                              className="btn btn-outline-primary btn-sm px-3"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setShowEditBehaviralInterviewFeedbackShow(true);
                                setSpecificInterviewId(detail?.id);
                                handleGetFeedbackById(detail?.id);
                              }}
                              data-bs-target="#technicalinterviewfeedback"
                            >
                              {" "}
                              Edit Feedback{" "}
                            </a>
                          </div>

                          <h6>Top 5 Skills:-</h6>
                          <ol>
                            {detail.skills
                              ?.replace(/[{}"\\]/g, "")
                              ?.split(/,\s*/)
                              ?.map((skill, skillIndex, array) => (
                                <li key={skillIndex}>
                                  {skill.trim()}
                                  {skillIndex < array.length - 1 && ", "}
                                </li>
                              ))}
                          </ol>
                          <h6 className="mb-0">Recorded Url</h6>
                          <a
                            href={detail?.recordedlink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                          >
                            {detail?.recordedlink}
                          </a>

                          <div className="showmorecontent">
                            <div className="block">
                              <div className="content" ref={contentRef}>
                                <h6>Feedback:-</h6>
                                <p
                                  className={
                                    showMoreBehavirolContent[idx]
                                      ? ""
                                      : "js-excerpt excerpt-hidden"
                                  }
                                >
                                  {detail?.feedback}
                                </p>
                              </div>
                              <button
                                role="button"
                                href="#"
                                onClick={() => BehaviroltoggleTechShowMore(idx)}
                              >
                                {showMoreBehavirolContent[idx]
                                  ? "Show Less"
                                  : "Show More"}
                              </button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                )}

                {!detail?.rating && (
                  <div className="firsthire">
                    <div className="card">
                      <div className="interviewround1">{idx + 1}</div>
                      <div className="card-body p-0">
                        <div className="profileinterview position-relative p-2 d-flex gap-2">
                          <img
                            width="40"
                            className="rounded-pill"
                            height="40"
                            src={CommonImageTransport?.profile1}
                            onError={(e) =>
                              (e.target.src = "../assets/images/img_avatar.png")
                            }
                            alt=""
                          />
                          <div>
                            Interviewers:-
                            {detail?.interviewers?.map(
                              (interviewersResult, idx) => (
                                <h6 key={idx} className="m-0">
                                  {interviewersResult?.fname}{" "}
                                  {interviewersResult?.lname}
                                </h6>
                              )
                            )}
                            <span className="small">
                              Date:{" "}
                              {/* {new Date(detail?.scheduledatto).toLocaleString()} */}
                              <div>
                                Date: {new Date(detail?.scheduledat).toLocaleDateString()}
                                <br />
                                Time: {new Date(detail?.scheduledat).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(detail?.scheduledatto).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              </div>

                            </span>
                          </div>
                        </div>

                        <div>
                          {detail?.status === "Completed" &&
                            detail?.rating === null &&
                            new Date(detail.scheduledatto) <
                            PresentDateTime && (
                              <div className="header0101 p-2 py-2">
                                <a
                                  href="#"
                                  className="default-btn rounded"
                                  onClick={() => {
                                    setBehaviralInterviewFeedbackShow(true);
                                    setSpecificInterviewId(detail.id);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Interview Feedback
                                </a>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between mt-2">
                  {idx == behavioralInterviews?.length - 1 &&
                    detail.status !== "Completed" &&
                    new Date(detail?.scheduledatto) < PresentDateTime && (
                      <span
                        className="btn btn-danger"
                        onClick={() => {
                          setBehaviralInterviewFinishModalShow(true);
                          setSpecificInterviewId(
                            behavioralInterviews[
                              behavioralInterviews?.length - 1
                            ].id
                          );
                        }}
                      >
                        Finish
                      </span>
                    )}
                  {idx == behavioralInterviews?.length - 1 &&
                    detail.status !== "Completed" &&
                    new Date(detail?.scheduledatto) < PresentDateTime && (
                      <span
                        className="btn btn-success"
                        onClick={() => {
                          setAddMoreBehaviralInterviewShow(true);
                          HandleAddMoreTechnicalInterviewIdSections(
                            behavioralInterviews || []
                          );
                          setSpecificInterviewId(
                            behavioralInterviews[
                              behavioralInterviews?.length - 1
                            ].id
                          );
                        }}
                      >
                        Add More
                      </span>
                    )}
                </div>
              </div>
            );
          })}

        {/* <--------- Behaviral Feedbacks sections --------------> */}
        <Modal
          size="lg"
          show={BehaviralInterviewFeedbackShow}
          onHide={() => {
            setBehaviralInterviewFeedbackShow(false);
            setFeedbackBehaviralInterviewSkills([]);
            setFeedbackBehaviralinterviewstatus();
            setFeedbackbehaviralFeedbackrecordedLink();
            setFeedbackBehaviralInterviewGiveFeedback();
            setRating();
          }}
          aria-labelledby="example-modal-sizes-title-lg"
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Interview Feedback
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setBehaviralInterviewFeedbackShow(false);
                  setFeedbackBehaviralInterviewSkills([]);
                  setFeedbackBehaviralinterviewstatus();
                  setFeedbackbehaviralFeedbackrecordedLink();
                  setFeedbackBehaviralInterviewGiveFeedback();
                  setRating();
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form method="post">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <div className="d-flex gap-4">
                        <label className="m-0 fw-normal">Recommend </label>
                        <div className="form-check text-success">
                          <input
                            className="form-check-input text-success"
                            type="radio"
                            name="feedbackbehaviralinterviewstatus"
                            value="Hire"
                            onChange={(e) =>
                              setFeedbackBehaviralinterviewstatus(
                                e.target.value
                              )
                            }
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label text-success"
                            htmlFor="flexRadioDefault1"
                          >
                            Hire
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input text-danger"
                            type="radio"
                            name="feedbackbehaviralinterviewstatus"
                            value="Reject"
                            onChange={(e) =>
                              setFeedbackBehaviralinterviewstatus(
                                e.target.value
                              )
                            }
                            id="flexRadioDefault2"
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor="flexRadioDefault2"
                          >
                            Reject
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group d-flex align-items-center gap-4">
                      <label>Star Rating</label>
                      <div>
                        <div className="rate">
                          {[...Array(5)].map((_, index) => {
                            const starValue = 5 - index;
                            return (
                              <label
                                key={index}
                                title={`${starValue} stars`}
                                style={{ fontSize: "1.5rem" }}
                                className={
                                  starValue <= rating ? "star filled" : "star"
                                }
                                onClick={() =>
                                  handleBehaviralInterviewStarClick(starValue)
                                }
                              >
                                ★
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2">
                      <label>Top 5 Skills</label>
                      <TagsInput
                        className="form-control"
                        value={FeedbackBehaviralInterviewSkills}
                        name="FeedbackBehaviralInterviewSkills"
                        onChange={(skill) =>
                          setFeedbackBehaviralInterviewSkills(skill)
                        }
                        inputProps={{ placeholder: "Enter Skills" }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Recorded Link</label>
                      <input
                        type="text"
                        name="feedbackbehaviralFeedbackrecordedLink"
                        value={feedbackbehaviralFeedbackrecordedLink}
                        onChange={(e) =>
                          setFeedbackbehaviralFeedbackrecordedLink(
                            e.target.value
                          )
                        }
                        placeholder="https://meet.google.com/odp-nvra-ycf"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Feedback</label>
                      <textarea
                        placeholder="Message"
                        name="FeedbackBehaviralInterviewGiveFeedback"
                        value={FeedbackBehaviralInterviewGiveFeedback}
                        onChange={(e) =>
                          setFeedbackBehaviralInterviewGiveFeedback(
                            e.target.value
                          )
                        }
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dd-footer d-flex gap-3 p-3 border-top">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  data-bs-dismiss="modal"
                  onClick={() => setBehaviralInterviewFeedbackShow(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleBehaviralInterviewFeedbackForm}
                  // disabled={!rating || !feedbackbehaviralinterviewstatus}
                  className="btn default-btn w-100"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* <-------- Add More Behavrial Interviwe ----------> */}
        <Modal
          size="lg"
          show={AddMoreBehaviralInterviewShow}
          onHide={() => setAddMoreBehaviralInterviewShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Behavioural Interview
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setAddMoreBehaviralInterviewShow(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form method="post">
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="form-group mb-2 myteamform">
                    <h6>Select Interviewer</h6>
                    <FormControl sx={{ m: 1, width: 750 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Interviewer
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={addMoreSelectedOptions} // This should contain an array of selected IDs
                        onChange={addMorehandleChangeOptions}
                        input={<OutlinedInput label="Select Interviewer" />}
                        renderValue={(selected) =>
                          selected
                            .map((id) => {
                              const interviewer = GetInterviewer.find(
                                (i) => i.id === id
                              );
                              return `${interviewer?.fname} ${interviewer?.lname}`;
                            })
                            .join(", ")
                        }
                      >
                        {GetInterviewer?.map((interviewer) => (
                          <MenuItem key={interviewer.id} value={interviewer.id}>
                            <Checkbox
                              checked={
                                addMoreSelectedOptions.indexOf(interviewer.id) >
                                -1
                              }
                            />
                            <ListItemText
                              primary={`${interviewer.fname} ${interviewer.lname}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview From</h6>
                    <div className="datetime-timezone-input form-group">
                      <input
                        type="datetime-local"
                        name="AddMoreBehaviralInterviewFromScheduledAt"
                        placeholder="date"
                        className="form-control datetime-input"
                        required
                        value={AddMoreBehaviralInterviewFromDate}
                        onChange={
                          AddMoreBehaviralformatDateTimeWithFromTimeZone
                        }
                        min={moment().format("YYYY-MM-DDTHH:mm")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview To</h6>
                    <div className="datetime-timezone-input form-group">
                      <input
                        type="datetime-local"
                        name="AddMoreBehaviralInterviewToScheduledAt"
                        placeholder="date"
                        className="form-control datetime-input"
                        required
                        value={AddMoreBehaviralInterviewToDate}
                        onChange={AddMoreBehaviralformatDateTimeWithToTimeZone}
                        min={moment().format("YYYY-MM-DDTHH:mm")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview Link</h6>
                    <input
                      type="text"
                      name="AddMoreBehavrialInterviewLink"
                      value={AddMoreBehavrialInterviewLink}
                      onChange={(e) =>
                        setAddMoreBehavrialInterviewLink(e.target.value)
                      }
                      placeholder="https://meet.google.com/odp-nvra-ycf"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="dd-footer d-flex gap-3 p-3 border-top">
              <button
                type="button"
                className="btn btn-secondary w-100"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn default-btn w-100"
                onClick={HandleAddMoreBehaviralInterview}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        {/* <-------- setSchedule Behaviral Interview  ----------> */}
        <Modal
          size="lg"
          show={ScheduleBehaviralInterviewShow}
          onHide={() => {
            setScheduleBehaviralInterviewShow(false);
            setScheduleBehaviralInterviewLink([]);
            setScheduleBehavrialInterviewToDate([]);
            setScheduleBehavrialInterviewFromDate([]);
            setScheduleBehavrialInterviewselectedOptions([]);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Behavioural Interview
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setScheduleBehaviralInterviewShow(false);
                setScheduleBehaviralInterviewLink([]);
                setScheduleBehavrialInterviewToDate([]);
                setScheduleBehavrialInterviewFromDate([]);
                setScheduleBehavrialInterviewselectedOptions([]);
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form method="post">
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="form-group mb-2 myteamform">
                    <h6>Select Interviewer</h6>
                    <FormControl sx={{ m: 1, width: 750 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Interviewer
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={ScheduleBehavrialInterviewselectedOptions} // Array of selected IDs
                        onChange={ScheduleBehaviralInterviewhandleChange}
                        input={<OutlinedInput label="Select Interviewer" />}
                        renderValue={(selected) =>
                          selected
                            .map((id) => {
                              const interviewer = GetInterviewer.find((i) => i.id === id);
                              return `${interviewer?.fname} ${interviewer?.lname}`;
                            })
                            .join(", ")
                        }
                      >
                        {GetInterviewer?.map((interviewer) => (
                          <MenuItem key={interviewer.id} value={interviewer.id}>
                            <Checkbox
                              checked={
                                ScheduleBehavrialInterviewselectedOptions.indexOf(interviewer.id) > -1
                              }
                            />
                            <ListItemText
                              primary={`${interviewer.fname} ${interviewer.lname}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview Date</h6>
                    <div className="datetime-timezone-input form-group">
                      <input
                        type="datetime-local"
                        name="ScheduledAt"
                        placeholder="date"
                        className="form-control datetime-input"
                        required
                        value={ScheduleBehavrialInterviewFromDate}
                        onChange={
                          scheduleBehaviralformatDateTimeWithFromTimeZone
                        }
                        min={moment().format("YYYY-MM-DDTHH:mm")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview Date</h6>
                    <div className="datetime-timezone-input form-group">
                      <input
                        type="datetime-local"
                        name="ScheduledAt"
                        placeholder="date"
                        className="form-control datetime-input"
                        required
                        value={ScheduleBehavrialInterviewToDate}
                        onChange={scheduleBehaviralformatDateTimeWithToTimeZone}
                        min={moment().format("YYYY-MM-DDTHH:mm")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group mb-2 myteamform">
                    <h6>Interview Link</h6>
                    <input
                      type="text"
                      name="ScheduleBehaviralInterviewLink"
                      value={ScheduleBehaviralInterviewLink}
                      onChange={(e) =>
                        setScheduleBehaviralInterviewLink(e.target.value)
                      }
                      placeholder="https://meet.google.com/odp-nvra-ycf"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="dd-footer d-flex gap-3 p-3 border-top">
              <button
                type="button"
                className="btn btn-secondary w-100"
                data-bs-dismiss="modal"
                onClick={() => setScheduleBehaviralInterviewShow(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn default-btn w-100"
                onClick={HandleScheduleBehaviralInterview}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        {/* <--------- Behaviral Edit Feedbacks sections --------------> */}
        <Modal
          size="lg"
          show={showEditBehaviralInterviewFeedbackShow}
          onHide={() => {
            setShowEditBehaviralInterviewFeedbackShow(false);
            setBehaviralInterviewFeedbackShow(false);
            setFeedbackBehaviralInterviewSkills([]);
            setFeedbackBehaviralinterviewstatus();
            setFeedbackbehaviralFeedbackrecordedLink();
            setFeedbackBehaviralInterviewGiveFeedback();
            setRating();
          }}
          aria-labelledby="example-modal-sizes-title-lg"
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Feedback
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowEditBehaviralInterviewFeedbackShow(false);
                  setFeedbackBehaviralInterviewSkills([]);
                  setFeedbackBehaviralinterviewstatus();
                  setFeedbackbehaviralFeedbackrecordedLink();
                  setRating();
                  setFeedbackBehaviralInterviewGiveFeedback();
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form method="post">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <div className="d-flex gap-4">
                        <label className="m-0 fw-normal">Recommend </label>
                        <div className="form-check text-success">
                          <input
                            className="form-check-input text-success"
                            type="radio"
                            name="EditFeedbackStatus"
                            value="Hire"
                            checked={EditFeedbackStatus === "Hire"}
                            onChange={(e) =>
                              setEditFeedbackStatus(e.target.value)
                            }
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label text-success"
                            htmlFor="flexRadioDefault1"
                          >
                            Hire
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input text-danger"
                            type="radio"
                            name="EditFeedbackStatus"
                            value="Reject"
                            checked={EditFeedbackStatus === "Reject"}
                            onChange={(e) =>
                              setEditFeedbackStatus(e.target.value)
                            }
                            id="flexRadioDefault2"
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor="flexRadioDefault2"
                          >
                            Reject
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group d-flex align-items-center gap-4">
                      <label>Star Rating</label>
                      <div>
                        <div className="rate">
                          {[...Array(5)].map((_, index) => {
                            const starValue = 5 - index;
                            return (
                              <label
                                key={index}
                                title={`${starValue} stars`}
                                style={{ fontSize: "1.5rem" }}
                                className={
                                  starValue <= EditFeedbackStarRatting
                                    ? "star filled"
                                    : "star"
                                }
                                onClick={() =>
                                  handleEditBehavirolFeedbackStarClick(
                                    starValue
                                  )
                                }
                              >
                                ★
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2">
                      <label>Top 5 Skills</label>
                      <TagsInput
                        className="form-control"
                        value={EditFeedbackSkills}
                        name="EditFeedbackSkills"
                        onChange={(skill) => setEditFeedbackSkills(skill)}
                        inputProps={{ placeholder: "Enter Skills" }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Recorded Link</label>
                      <input
                        type="text"
                        name="EditFeedbackRecordedLink"
                        value={EditFeedbackRecordedLink}
                        onChange={(e) =>
                          setEditFeedbackRecordedLink(e.target.value)
                        }
                        placeholder="https://meet.google.com/odp-nvra-ycf"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Feedback</label>
                      <textarea
                        placeholder="Message"
                        name="EditFeedbackGiveFeedback"
                        value={EditFeedbackGiveFeedback}
                        onChange={(e) =>
                          setEditFeedbackGiveFeedback(e.target.value)
                        }
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dd-footer d-flex gap-3 p-3 border-top">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    setShowEditBehaviralInterviewFeedbackShow(false)
                  }
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={handleEditTechnicalInterviewFeedbackForm}
                  // disabled={!EditFeedbackStarRatting || !EditFeedbackStatus}
                  className="btn default-btn w-100"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* <--------------- Complete the Behaviral Interview sections--------------> */}
        <Modal
          show={BehaviralInterviewFinishModalShow}
          onHide={() => setBehaviralInterviewFinishModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are You Sure Finish the Interview</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure Finish the Interview!</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setBehaviralInterviewFinishModalShow(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => Handlecompleteinterviewround()}
            >
              Finish
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Recuriter_Behaviral_Interviewing;
