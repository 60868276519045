import React, { useEffect,useState } from 'react'
import Modal from "react-bootstrap/Modal";
import CommonImageTransport from "../../../common/images/CommonImages";
import moment from "moment-timezone";
import { GetStudentResume } from '../../../../api/Student';

const Recuriter_Student_Resume_Modal = ({student_userid, show, onHide}) => {
    console.log("Recuriter_Student_Resume_Modal",student_userid)

    const [StudentProfile, setStudentProfile] = useState([]);
      // <------- Student Profile Show Section's -------------->
//   const [StudentProfileShow, setStudentProfileShow] = useState(false);

   // <--------- Authorization sections ----------->
   const token = localStorage.getItem("User_Token");
   const headers = {
     Authorization: `Bearer ${token}`,
   };

    //    <---------- Get the Student Profile here ------------>
  const GetStudentProfile = async () => {
    const StudentResumeData = {
      id: student_userid
    };
    console.log("StudentResumeData", StudentResumeData)
    const response = await GetStudentResume(StudentResumeData, headers)
    setStudentProfile(response?.data?.data)
  }

  useEffect(()=>{
    GetStudentProfile();
  },[])

  return (
 
    <Modal size='xl' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Student Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="resumefile">
          <page size="A4">
            <div class="d-flex">
              <div class="leftPanel">
                {
                  StudentProfile?.student_info?.profileimg != null ? (
                    <>
                      <img src={StudentProfile?.student_info?.profileimg} />
                    </>
                  ) : (
                    <>
                      <img src={CommonImageTransport?.userLogo} />
                    </>
                  )
                }
                <div class="details">
                  <div class="item bottomLineSeparator">
                    <div class="smallText">
                      <p>
                        <i class="fa fa-phone contactIcon" aria-hidden="true"></i>
                        <p>{StudentProfile?.student_info?.fname} {StudentProfile?.student_info?.lname}</p>
                      </p>
                    </div>

                    <div class="smallText">
                      <p>
                        <i class="fa fa-phone contactIcon" aria-hidden="true"></i> {StudentProfile?.student_info?.mobile}
                      </p>
                      <p>
                        <i class="fa fa-envelope contactIcon" aria-hidden="true"></i>
                        <a href="mailto:lorem@ipsum.com@gmail.com">{StudentProfile?.student_info?.email}</a>
                      </p>
                      <p>
                        <i class="fa fa-map-marker contactIcon" aria-hidden="true"></i>
                        {StudentProfile?.student_info?.city},   {StudentProfile?.student_info?.state},   {StudentProfile?.student_info?.country}
                      </p>
                    </div>
                  </div>
                  <div class="item bottomLineSeparator">
                    <h2>
                      SKILLS
                    </h2>
                    <div class="smallText">
                      {
                        StudentProfile?.student_skills?.map((StudentSkillsResult) => {
                          return (
                            <>
                              <div class="skill">
                                <div>
                                  <span>{StudentSkillsResult?.skill}</span>
                                </div>
                                <div class="yearsOfExperience">
                                  <span class="alignright">{StudentSkillsResult?.expertise}</span>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }

                    </div>
                  </div>
                  <div class="item">
                    <h2>EDUCATION</h2>
                    <div class="smallText">
                      {
                        StudentProfile?.student_educations?.map((StudentEdutionResult) => {
                          return (
                            <>
                              <div class="smallText">
                                <p class="bolded white">
                                  {StudentEdutionResult?.degreetype}
                                </p>
                                <p class="bolded white">
                                  {StudentEdutionResult?.degreename}
                                </p>
                                <p>
                                  {StudentEdutionResult?.university}
                                </p>
                                <p>
                                  {moment.utc(StudentEdutionResult?.startdate).format("MM/YYYY")}  -  {moment.utc(StudentEdutionResult?.enddate).format("MM/YYYY")}
                                </p>
                              </div>
                            </>
                          )
                        })

                      }
                    </div>


                  </div>
                </div>

              </div>
              <div class="rightPanel pt-0">
                <div class="workExperience">
                  <h4>
                    Work Experience
                  </h4>
                  <ul>
                    {
                      StudentProfile?.st_experience?.map((StudentProfessnalExp, index) => {
                        return (
                          <>
                            <li>
                              <div class="jobPosition">
                                <span class="bolded">
                                  <h3>{StudentProfessnalExp?.p_title} <span>
                                    ({StudentProfessnalExp?.emp_type})
                                  </span></h3>
                                  <h6>
                                    {StudentProfessnalExp?.company}{" "}

                                  </h6>
                                </span>

                                {StudentProfessnalExp?.start_date && (
                                  <small className="fw-normal small">
                                    {moment.utc(
                                      StudentProfessnalExp?.start_date
                                    ).format(
                                      "MM/DD/YYYY"
                                    )}{" "}
                                    -{" "}
                                    <>
                                      {StudentProfessnalExp?.end_date !=
                                        null && (
                                          <>
                                            {moment.utc(
                                              StudentProfessnalExp?.end_date
                                            ).format(
                                              "MM/DD/YYYY"
                                            )}
                                          </>
                                        )}
                                    </>
                                  </small>
                                )}
                              </div>
                              <p>{StudentProfessnalExp?.job_desc}{" "}</p>
                              <div className="col-md-12 mb-3">
                                <div className="form-group">
                                  <div className="agree-label d-flex gap-2">
                                    {StudentProfessnalExp?.currently_working ==
                                      1 && (
                                        <>
                                          <input
                                            type="checkbox"
                                            name="currently_working"
                                            checked={
                                              StudentProfessnalExp?.currently_working ===
                                              1
                                            }
                                            id={`chb${index}`}
                                            className="termcheck"
                                            readOnly
                                          />
                                          <label
                                            htmlFor={`chb${index}`}
                                            className="small text-muted"
                                          >
                                            I am currently working
                                            in this role
                                          </label>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        )
                      })
                    }

                  </ul>
                </div>
                <div class="workExperience">
                  <h4>
                    Projects
                  </h4>
                  <ul>
                    {
                      StudentProfile?.university_projects?.map((StudentWorkExpResult) => {
                        return (
                          <>
                            <li>
                              <div class="jobPosition">
                                <span class="bolded">
                                  {StudentWorkExpResult?.title}
                                </span>
                              </div>
                              <div>
                                {
                                  StudentWorkExpResult?.websitelink && (
                                    <a
                                      href={StudentWorkExpResult.websitelink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {StudentWorkExpResult.websitelink}
                                    </a>
                                  )
                                }
                              </div>

                              <div class="smallText">
                                <p>{StudentWorkExpResult?.yourrole}</p>
                              </div>


                            </li>
                          </>
                        )
                      })
                    }
                  </ul>
                </div>
                <div class="workExperience">
                  <h4>
                    Certificate
                  </h4>
                  <ul>
                    {
                      StudentProfile?.student_certification?.length > 0 && (
                        <>
                          {
                            StudentProfile?.student_certification?.map((StudentCertificateResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCertificateResult?.certificate}
                                      </span>
                                      <span>
                                        <small className="fw-normal small">
                                          {moment.utc(StudentCertificateResult?.certification_date).format("MM/DD/YYYY")}
                                        </small>

                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCertificateResult?.organication}
                                      </p>
                                    </div>
                                  </li>
                                </>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </ul>
                </div>
                <div class="workExperience">
                  <h4>
                    Training Program
                  </h4>
                  <ul>
                    {
                      StudentProfile?.complete_course?.length > 0 && (
                        <>
                          {
                            StudentProfile?.complete_course?.map((StudentCompleteCourseResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCompleteCourseResult?.title}
                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCompleteCourseResult?.duration}
                                      </p>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCompleteCourseResult?.description}
                                      </p>
                                    </div>
                                  </li>
                                </>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
          </page>

        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Recuriter_Student_Resume_Modal