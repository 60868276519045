import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import Recruiter_Header from "../recruiter_header/Recruiter_Header";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Button,
} from "@mui/material";
import { GetRecuriterAllAppliedJobs } from "../../../../api/Recuriter";
import Modal from "react-bootstrap/Modal";
import { GetStudentResume } from "../../../../api/Student";
import moment from "moment";
import CommonImageTransport from "../../../common/images/CommonImages";
import Recruiter_Footer from "../recruiter_footer/Recruiter_Footer";
// <----------- Table header sections ---------->
const columns = [
  { id: "unique_id", label: "Job ID", minWidth: 50 },
  { id: "applied_status", label: "Job Status", minWidth: 150 },
  { id: "title", label: "Job Title", minWidth: 150 },
  { id: "student_firstname", label: "First Name", minWidth: 150 },
  { id: "student_lastname", label: "Last Name", minWidth: 150 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "student_email", label: "Email", minWidth: 100 },
  { id: "location", label: "Location", minWidth: 200 },
  { id: "student_userid", label: "Profile", minWidth: 150 },
];

const AllProfile = () => {
  // <--------- This is UseState for Loader Pages-------->
  const [isLoading, setIsLoading] = useState(true);
  const [StudnetId, setStudentId] = useState("");
  const [StudentProfile, setStudentProfile] = useState([]);

  // <--------- Filter the Data from the Table section's ------------>
  const [filters, setFilters] = useState({
    unique_id: "",
    applied_status: "",
    student_name: "",
    title: "",
    mobile: "",
    expertise: "",
    organization: "",
    employmenttype: "",
    location: "",
    skills: "",
  });

  // <------- Student Profile Show Section's -------------->
  const [StudentProfileShow, setStudentProfileShow] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // <------------ User Authentication token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <---------- Change the page -------------->
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // <-------- Handle the How many Data Show in the Row ---------->
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // <------------ Filter the data By the column -------------->
  const handleFilterChange = (e, columnId) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: value.trim(), // Update the specific column's filter
    }));
  };

  const renderStatusButton = (status, columnId, row) => {
    if (row.fianl_decision === 0 && row.behavioral_status === 2) {
      return <Button color="error">Rejected</Button>;
    }
    if (
      row.technical_status === 2 &&
      row.behavioral_status === 2 &&
      row.fianl_decision === 1
    ) {
      return <Button color="info">Decision Pending</Button>;
    }
    if (
      row.technical_status === 2 &&
      row.behavioral_status === 2 &&
      row.fianl_decision === 3
    ) {
      return <Button color="success">Hired</Button>;
    }
    if (row.behavioral_status === 2 && row.technical_status !== 2) {
      return <Button color="secondary">Technical</Button>;
    }
    if (row.technical_status === 2 && row.fianl_decision !== 2) {
      return <Button color="info">Behavioral</Button>;
    }

    // Default applied_status logic
    switch (status) {
      case 1:
        return <Button color="warning">Pending</Button>;
      case 2:
        return <Button color="success">Accepted</Button>;
      case 0:
        return <Button color="error">Rejected</Button>;
      case 3:
        return <Button className="btn btn-info">Processing</Button>;
      default:
        return null;
    }
  };

  // <---------- show the Button behalf of the status of the job ------------>
  const getCustomStatus = (row) => {
    // Custom status mapping based on multiple columns
    if (row.fianl_decision === 0 && row.behavioral_status === 2) {
      return "Rejected"; // Rejected status when final decision is 0 and behavioral is passed
    }
    if (
      row.technical_status === 2 &&
      row.behavioral_status === 2 &&
      row.fianl_decision === 1
    ) {
      return "Decision Pending"; // Decision pending status when both technical and behavioral are passed but final decision is pending
    }
    if (
      row.technical_status === 2 &&
      row.behavioral_status === 2 &&
      row.fianl_decision === 3
    ) {
      return "Hired"; // Hired status when both technical and behavioral are passed and final decision is 3
    }
    if (row.behavioral_status === 2 && row.technical_status !== 2) {
      return "Technical"; // Technical status when behavioral is passed but technical is not yet passed
    }
    if (row.technical_status === 2 && row.fianl_decision !== 2) {
      return "Behavioral"; // Behavioral status when technical is passed but behavioral is not yet passed
    }
    if (row.applied_status === 1) {
      return "Pending"; // Pending status when applied_status is 1
    }
    if (row.applied_status === 2) {
      return "Accepted"; // Accepted status when applied_status is 2
    }
    if (row.applied_status === 0) {
      return "Rejected"; // Rejected status when applied_status is 0
    }
    if (row.applied_status === 3) {
      return "Processing"; // Processing status when applied_status is 3
    }
    return ""; // Return empty if no match
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Accepted";
      case 0:
        return "Rejected";
      case 3:
        return "Processing";
      default:
        return "";
    }
  };

  // Filter logic with individual column filters applied
  const filteredRows = rows.filter((row) => {
    return columns.every((column) => {
      const filterValue = filters[column.id]?.toString() || "";
      let columnValue = row[column.id]?.toString() || "";

      if (column.id === "applied_status") {
        columnValue = getCustomStatus(row); // Handle custom applied_status logic
      }

      if (filterValue) {
        return columnValue.toLowerCase().includes(filterValue.toLowerCase()); // Case-insensitive filtering
      }

      return true; // No filter for this column
    });
  });

  const GetStudentDetailsById = (student_userid) => {
    setStudentId(student_userid);
    GetStudentProfile(student_userid);
  };
  //    <---------- Get the Student Profile here ------------>
  const GetStudentProfile = async (StudnetId) => {
    const StudentResumeData = {
      id: StudnetId,
    };
    console.log("StudentResumeData", StudentResumeData);
    const response = await GetStudentResume(StudentResumeData, headers);
    setStudentProfile(response?.data?.data);
  };
  console.log("GetStudentProfile", StudentProfile);
  // <--------- Get the All Applied Jobs Profile ------------->
  useEffect(() => {
    const GetAllAppliedJobsProfile = async () => {
      setIsLoading(true);
      try {
        const GetRecuriterAllAppliedJobsData = {
          status: null,
        };

        const response = await GetRecuriterAllAppliedJobs(
          GetRecuriterAllAppliedJobsData,
          headers
        );
        if (response?.data?.statusCode === "200") {
          setIsLoading(false);
        }
        setRows(response?.data?.data || []);
      } catch (error) {
        setIsLoading(true);
        console.log(error);
      }
    };

    GetAllAppliedJobsProfile();
  }, []);
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div class="bg_nav-slid bg-white">
            <Recruiter_Header />
          </div>
          <div class="recruiter_list pb-5 pt-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <ul class="nav tabs_signup justify-content-start gap-2 mb-3">
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        aria-current="page"
                        to="/recruiter_pages/NewProfile"
                      >
                        New Profile
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        to="/recruiter_pages/RecruiterInterviewing"
                      >
                        Interviewing
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link"
                        to="/recruiter_pages/RecruiterList"
                      >
                        Hired
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/Recuriter_Rejected"
                      >
                        Rejected
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class="nav-link active"
                        to="/recruiter_pages/AllProfile"
                      >
                        All
                      </Link>
                    </li>
                  </ul>
                  {rows?.length > 0 ? (
                    <>
                      {/* <-------- Table sections ------> */}
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      backgroundColor: "#f1f1f1",
                                    }}
                                  >
                                    {column.label}
                                    {/* Check if the column is 'applied_status', if so, use a dropdown */}
                                    {column.id === "applied_status" ? (
                                      <select
                                        className="form-control"
                                        value={filters[column.id] || ""}
                                        onChange={(e) =>
                                          handleFilterChange(e, column.id)
                                        }
                                      >
                                        <option value="">
                                          Filter Job Status
                                        </option>
                                        <option value="Rejected">
                                          Rejected
                                        </option>
                                        <option value="Decision Pending">
                                          Decision Pending
                                        </option>
                                        <option value="Hired">Hired</option>
                                        <option value="Technical">
                                          Technical
                                        </option>
                                        <option value="Behavioral">
                                          Behavioral
                                        </option>
                                        <option value="Pending">Pending</option>
                                        <option value="Accepted">
                                          Accepted
                                        </option>
                                        <option value="Processing">
                                          Processing
                                        </option>
                                      </select>
                                    ) : (
                                      <TextField
                                        variant="standard"
                                        value={filters[column.id] || ""}
                                        onChange={(e) =>
                                          handleFilterChange(e, column.id)
                                        }
                                        placeholder={`Filter ${column.label}`}
                                        size="small"
                                      />
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {filteredRows.length === 0 ? (
                                <TableRow>
                                  <TableCell
                                    colSpan={columns.length}
                                    align="center"
                                  >
                                    No records found
                                  </TableCell>
                                </TableRow>
                              ) : (
                                filteredRows
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {column.id === "applied_status" ? (
                                              renderStatusButton(
                                                row[column.id],
                                                "applied_status",
                                                row
                                              )
                                            ) : column.id ===
                                              "student_userid" ? (
                                              <button
                                                onClick={() => {
                                                  setStudentProfileShow(true);
                                                  GetStudentDetailsById(
                                                    row?.student_userid
                                                  );
                                                }}
                                                className="btn btn-primary"
                                              >
                                                View More
                                              </button>
                                            ) : (
                                              value
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={filteredRows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </>
                  ) : (
                    <div className="text-center mt-5">
                      <h4>Not Any Student Profile Available here</h4>
                    </div>
                  )}

                  {/* <------ Student Profile section's -------------> */}
                  <Modal
                    size="xl"
                    show={StudentProfileShow}
                    onHide={() => setStudentProfileShow(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Student Resume</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="resumefile">
                        <page size="A4">
                          <div class="d-flex">
                            <div class="leftPanel">
                              {StudentProfile?.student_info?.profileimg !=
                              null ? (
                                <>
                                  <img
                                    src={
                                      StudentProfile?.student_info?.profileimg
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <img src={CommonImageTransport?.userLogo} />
                                </>
                              )}
                              <div class="details">
                                <div class="item bottomLineSeparator">
                                  <div class="smallText">
                                    <p>
                                      <i
                                        class="fa fa-phone contactIcon"
                                        aria-hidden="true"
                                      ></i>
                                      <p>
                                        {StudentProfile?.student_info?.fname}{" "}
                                        {StudentProfile?.student_info?.lname}
                                      </p>
                                    </p>
                                  </div>

                                  <div class="smallText">
                                    <p>
                                      <i
                                        class="fa fa-phone contactIcon"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {StudentProfile?.student_info?.mobile}
                                    </p>
                                    <p>
                                      <i
                                        class="fa fa-envelope contactIcon"
                                        aria-hidden="true"
                                      ></i>
                                      <a href="mailto:lorem@ipsum.com@gmail.com">
                                        {StudentProfile?.student_info?.email}
                                      </a>
                                    </p>
                                    <p>
                                      <i
                                        class="fa fa-map-marker contactIcon"
                                        aria-hidden="true"
                                      ></i>
                                      {StudentProfile?.student_info?.city},{" "}
                                      {StudentProfile?.student_info?.state},{" "}
                                      {StudentProfile?.student_info?.country}
                                    </p>
                                  </div>
                                </div>
                                <div class="item bottomLineSeparator">
                                  <h2>SKILLS</h2>
                                  <div class="smallText">
                                    {StudentProfile?.student_skills?.map(
                                      (StudentSkillsResult) => {
                                        return (
                                          <>
                                            <div class="skill">
                                              <div>
                                                <span>
                                                  {StudentSkillsResult?.skill}
                                                </span>
                                              </div>
                                              <div class="yearsOfExperience">
                                                <span class="alignright">
                                                  {
                                                    StudentSkillsResult?.expertise
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div class="item">
                                  <h2>EDUCATION</h2>
                                  <div class="smallText">
                                    {StudentProfile?.student_educations?.map(
                                      (StudentEdutionResult) => {
                                        return (
                                          <>
                                            <div class="smallText">
                                              <p class="bolded white">
                                                {
                                                  StudentEdutionResult?.degreetype
                                                }
                                              </p>
                                              <p class="bolded white">
                                                {
                                                  StudentEdutionResult?.degreename
                                                }
                                              </p>
                                              <p>
                                                {
                                                  StudentEdutionResult?.university
                                                }
                                              </p>
                                              <p>
                                                {moment
                                                  .utc(
                                                    StudentEdutionResult?.startdate
                                                  )
                                                  .format("MM/YYYY")}{" "}
                                                -{" "}
                                                {moment
                                                  .utc(
                                                    StudentEdutionResult?.enddate
                                                  )
                                                  .format("MM/YYYY")}
                                              </p>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="rightPanel pt-0">
                              <div class="workExperience">
                                <h4>Work Experience</h4>
                                <ul>
                                  {StudentProfile?.st_experience?.map(
                                    (StudentProfessnalExp, index) => {
                                      return (
                                        <>
                                          <li>
                                            <div class="jobPosition">
                                              <span class="bolded">
                                                <h3>
                                                  {
                                                    StudentProfessnalExp?.p_title
                                                  }{" "}
                                                  <span>
                                                    (
                                                    {
                                                      StudentProfessnalExp?.emp_type
                                                    }
                                                    )
                                                  </span>
                                                </h3>
                                                <h6>
                                                  {
                                                    StudentProfessnalExp?.company
                                                  }{" "}
                                                </h6>
                                              </span>

                                              {StudentProfessnalExp?.start_date && (
                                                <small className="fw-normal small">
                                                  {moment
                                                    .utc(
                                                      StudentProfessnalExp?.start_date
                                                    )
                                                    .format("MM/DD/YYYY")}{" "}
                                                  -{" "}
                                                  <>
                                                    {StudentProfessnalExp?.end_date !=
                                                      null && (
                                                      <>
                                                        {moment
                                                          .utc(
                                                            StudentProfessnalExp?.end_date
                                                          )
                                                          .format("MM/DD/YYYY")}
                                                      </>
                                                    )}
                                                  </>
                                                </small>
                                              )}
                                            </div>
                                            <p>
                                              {StudentProfessnalExp?.job_desc}{" "}
                                            </p>
                                            <div className="col-md-12 mb-3">
                                              <div className="form-group">
                                                <div className="agree-label d-flex gap-2">
                                                  {StudentProfessnalExp?.currently_working ==
                                                    1 && (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        name="currently_working"
                                                        checked={
                                                          StudentProfessnalExp?.currently_working ===
                                                          1
                                                        }
                                                        id={`chb${index}`}
                                                        className="termcheck"
                                                        readOnly
                                                      />
                                                      <label
                                                        htmlFor={`chb${index}`}
                                                        className="small text-muted"
                                                      >
                                                        I am currently working
                                                        in this role
                                                      </label>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                              <div class="workExperience">
                                <h4>Projects</h4>
                                <ul>
                                  {StudentProfile?.university_projects?.map(
                                    (StudentWorkExpResult) => {
                                      return (
                                        <>
                                          <li>
                                            <div class="jobPosition">
                                              <span class="bolded">
                                                {StudentWorkExpResult?.title}
                                              </span>
                                            </div>
                                            <div>
                                              {StudentWorkExpResult?.websitelink && (
                                                <a
                                                  href={
                                                    StudentWorkExpResult.websitelink
                                                  }
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {
                                                    StudentWorkExpResult.websitelink
                                                  }
                                                </a>
                                              )}
                                            </div>

                                            <div class="smallText">
                                              <p>
                                                {StudentWorkExpResult?.yourrole}
                                              </p>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                              <div class="workExperience">
                                <h4>Certificate</h4>
                                <ul>
                                  {StudentProfile?.student_certification
                                    ?.length > 0 && (
                                    <>
                                      {StudentProfile?.student_certification?.map(
                                        (StudentCertificateResult) => {
                                          return (
                                            <>
                                              <li>
                                                <div class="jobPosition">
                                                  <span class="bolded">
                                                    {
                                                      StudentCertificateResult?.certificate
                                                    }
                                                  </span>
                                                  <span>
                                                    <small className="fw-normal small">
                                                      {moment
                                                        .utc(
                                                          StudentCertificateResult?.certification_date
                                                        )
                                                        .format("MM/DD/YYYY")}
                                                    </small>
                                                  </span>
                                                </div>
                                                <div class="projectName">
                                                  <p>
                                                    {
                                                      StudentCertificateResult?.organication
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </ul>
                              </div>
                              <div class="workExperience">
                                <h4>Training Program</h4>
                                <ul>
                                  {StudentProfile?.complete_course?.length >
                                    0 && (
                                    <>
                                      {StudentProfile?.complete_course?.map(
                                        (StudentCompleteCourseResult) => {
                                          return (
                                            <>
                                              <li>
                                                <div class="jobPosition">
                                                  <span class="bolded">
                                                    {
                                                      StudentCompleteCourseResult?.title
                                                    }
                                                  </span>
                                                </div>
                                                <div class="projectName">
                                                  <p>
                                                    {
                                                      StudentCompleteCourseResult?.duration
                                                    }
                                                  </p>
                                                </div>
                                                <div class="projectName">
                                                  <p>
                                                    {
                                                      StudentCompleteCourseResult?.description
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </page>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Recruiter_Footer />
    </div>
  );
};

export default AllProfile;
