import React, { useState, useEffect } from 'react'
import TagsInput from 'react-tagsinput';
import { Button } from 'react-bootstrap';
import 'react-tagsinput/react-tagsinput.css';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import CommonImageTransport from '../../../common/images/CommonImages';
import Loader from '../../../../loader/Loader';
import moment from 'moment';
import Typewriter from 'typewriter-effect';
import { GetNewJobList, EdiRecuriterJobPost, DeleteRecuriterJobPost } from '../../../../api/Recuriter';
import ReactHtmlParser from "react-html-parser";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';
import { FaMoneyBill1Wave } from "react-icons/fa6";

const RecruiterJobs = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [is_Loading, setIs_Loading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [Logo, setLogo] = useState("");
    const [title, setTitle] = useState("")
    const [user_id, setuser_id] = useState(null)
    const [id, set_id] = useState(null)
    const [organization, setorganization] = useState("");
    const [companyurl, setCompanyUrl] = useState("")
    const [skills, setskills] = useState([])
    const [expertise, setexpertise] = useState("")
    const [employmentType, setemploymentType] = useState("")
    const [location, setlocation] = useState("")
    const [currencyType, setcurrencyType] = useState("")
    const [salarypackage, setsalaryPackage] = useState(null)
    const [startDate, setstartDate] = useState("")
    const [endDate, setendDate] = useState("")
    const [aboutJob, setaboutJob] = useState("")
    const [JobList, setJobList] = useState([]);
    const defaultTabIndex = 0;
    const [show, setShow] = useState(false);
    const [DeletePostshow, setDeletePostshow] = useState(false);
    const [status, setStatus] = useState(1);

    // <-------- User Authentication token's ------------>
    const token = localStorage.getItem("User_Token");
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
    };

    // <-------- Search the Jobs by title & location ------------->
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    // <------------ Render the Job List here ------------>
    useEffect(() => {
        GetJobList();
    }, [])

    // <------------ Get Job List ------------>
    const GetJobList = async () => {
        setIs_Loading(true)
        try {
            const response = await GetNewJobList(headers);
            setJobList(response?.data?.data)
            setIs_Loading(false)
        } catch (error) {
            setIs_Loading(true)
        }

    }
    const [ActiveTabs, setActiveTabs] = useState(JobList?.id);

    const GetJobListById = async (id) => {
        const filteredJob = JobList?.find(job => job?.id === id);
        if (filteredJob) {
            setaboutJob(filteredJob?.aboutjob)
            setTitle(filteredJob?.title)
            setorganization(filteredJob?.organization)
            setCompanyUrl(filteredJob?.companyurl)
            setexpertise(filteredJob?.expertise);
            setTimeout(() => {
                setexpertise(filteredJob?.expertise);
            }, 0);
            setendDate(filteredJob?.enddate)
            setstartDate(filteredJob?.startdate)
            setsalaryPackage(filteredJob?.salarypackage)
            setcurrencyType(filteredJob?.currencytype)
            setlocation(filteredJob?.location)
            setemploymentType(filteredJob?.employmentType)
            setuser_id(filteredJob?.user_id)
            set_id(filteredJob?.id)
            const skillsArray = filteredJob?.skills?.split(',')?.map(skill => skill.trim());
            setskills(skillsArray);
        }
    }

    // <-------------- Edit Jobs -------------->
    const EditJobList = async () => {
        const EditRecuriterJobPostData = {
            aboutJob,
            endDate,
            startDate,
            salarypackage,
            currencyType,
            location,
            employmentType,
            expertise,
            skills,
            companyurl,
            title,
            organization,
            user_id,
            id
        }
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await EdiRecuriterJobPost(EditRecuriterJobPostData, headers);
        if (response?.data?.statusCode === "200") {
            toast.success("Job updated successfully !!!");
            setTimeout(() => {
                setShow(false);
                GetJobList();
            }, 2000)

        }
    }
    // <-------------- Delete Jobs -------------->
    const DeleteJobs = async (id) => {
        const newStatus = status === 1 ? "0" : 1;
        setStatus(newStatus);
        const DeleteJobsData = {
            status: newStatus.toString(),
            id
        };
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await DeleteRecuriterJobPost(DeleteJobsData, headers)
        if (response?.data?.msg === "You are not able to do the action on this job.") {
            toast.error("You Cannot Delete This Jobs !!!")
        }
        if (response?.data?.statusCode === "200") {
            toast.warn("Post Deleted Successfully !!!")
        }
        setDeletePostshow(false)
        GetJobList();
    }

    // <-------------- Search the Job List ------------>
    const filteredList = JobList?.filter(item =>
        item?.title?.toLowerCase().includes(searchTerm) ||
        item?.organization?.toLowerCase().includes(searchTerm) ||
        item?.unique_id?.toLowerCase().includes(searchTerm) ||
        item?.location?.toLowerCase().includes(searchTerm)
    );

    // <--------- This UseEffect for Loader Pages-------->
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2200);

        return () => clearTimeout(timeout);
    }, []);

    // <------------ Handle the Active & UnActive Student List here -----------> 
    const HandleActiveTabs = (tabId) => {
        setActiveTabs(tabId);
    }

   
    // <----------------- Active the First Job's and when filter then automtics filter the active's tabs --------------->
    useEffect(() => {
        if (filteredList?.length > 0 && (!ActiveTabs || !filteredList?.some(job => job.id === ActiveTabs))) {
            const firstActiveTab = filteredList[0];
            if (firstActiveTab) {
                setActiveTabs(firstActiveTab?.id);
            }
        }
    }, [filteredList, ActiveTabs]);

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Recruiter_Header />
                        </div>
                        <div className="header_space"></div>
                        {
                            JobList?.length > 0 ? (

                                <>
                                    <div className="recruiter_list pb-5">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <form>
                                                        <ToastContainer style={{ marginTop: "120px" }} />
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>
                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)} >
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text"
                                                                            placeholder=""
                                                                            value={searchTerm}
                                                                            onChange={handleSearchChange}
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" onChange={handleSearchChange} className="form-control" required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <button type="button" className="submit_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="left_recruiterlist">
                                                        <div className="sticky-header">
                                                            <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                aria-orientation="vertical">
                                                                {
                                                                    filteredList?.reverse()?.map((JobListResult, index) => {
                                                                        return (
                                                                            <>
                                                                                <div key={index} className={`nav-link ${ActiveTabs === JobListResult?.id ? "active" : ""}`}
                                                                                    onClick={() => HandleActiveTabs(JobListResult?.id)}
                                                                                    id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                    data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                                                    aria-selected="true">
                                                                                    <div className="card_rec position-relative">
                                                                                        <div className="mentersvies0121">
                                                                                            <div>
                                                                                                {
                                                                                                    JobListResult?.job_image != null ? (
                                                                                                        <>
                                                                                                            <img src={JobListResult?.job_image} style={{ borderRadius: "5px" }}
                                                                                                                className="" alt="Instructor" />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img src={CommonImageTransport?.companybuilding} style={{ borderRadius: "5px" }}
                                                                                                                className="" alt="Instructor" />
                                                                                                        </>

                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className="instructors-details-contents">
                                                                                                <div className="comnam">
                                                                                                    <h3>{JobListResult?.title}</h3>
                                                                                                    <h6 className="sub-title">{JobListResult?.organization}</h6>
                                                                                                    <p>Job Id:{JobListResult?.unique_id}</p>
                                                                                                </div>
                                                                                                <p className="perographsv">
                                                                                                    <i className="ri-map-pin-fill"></i>{JobListResult?.location}
                                                                                                </p>
                                                                                                {
                                                                                                    JobListResult?.status == "1" ? (
                                                                                                        <a href="#"
                                                                                                            className="text-success">Active</a>
                                                                                                    ) : (
                                                                                                        <a href="#"
                                                                                                            className="text-danger">UnActive</a>
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <div className="skillstopic">
                                                                                                {/* <div>Engineering Students</div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                        {
                                                            filteredList?.find(job => job.id === ActiveTabs) && (
                                                                <div className="tab-content" id="v-pills-tabContent">
                                                                    <div className={`tab-pane fade ${ActiveTabs ? "show active" : ""
                                                                        }`}
                                                                        id={`v-pills-${ActiveTabs}`}
                                                                        role="tabpanel"
                                                                        aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                        <div className="instructors-details-img m-0">
                                                                            <div className="card-instrutors shadow position-relative bg-white p-4">

                                                                                <div className="mentersvies0121 recruiter101s">
                                                                                    {
                                                                                        filteredList?.find((job) => job.id === ActiveTabs)?.job_image != null ? (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={filteredList?.find((job) => job.id === ActiveTabs)?.job_image} className="" alt="Instructor" style={{ borderRadius: '5px' }} />
                                                                                                </div>
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={CommonImageTransport?.companybuilding}
                                                                                                     className="" alt="Instructor" style={{ borderRadius: '5px' }} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }


                                                                                    <div className="instructors-details-contents">
                                                                                        <h3>{filteredList?.find((job) => job.id === ActiveTabs)?.title}</h3>
                                                                                        <h6>Job Id:{filteredList?.find((job) => job.id === ActiveTabs)?.unique_id}</h6>
                                                                                        <p><Link to="#"><i className="ri-community-line"></i> {filteredList?.find((job) => job.id === ActiveTabs)?.organization}</Link></p>
                                                                                        <p><i className="ri-map-pin-fill"></i> {filteredList?.find((job) => job.id === ActiveTabs)?.location}</p>
                                                                                        <div className='d-flex justify-content-around align-items-center" '>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">
                                                                                                Application Start:</span>{moment(filteredList?.find((job) => job.id === ActiveTabs)?.startdate).format('MM/DD/YYYY')} </p>

                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">
                                                                                                Application End:</span>
                                                                                                {moment(filteredList?.find((job) => job.id === ActiveTabs)?.enddate).format('MM/DD/YYYY')}</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="footerapplly border-top d-flex justify-content-between mt-3 pt-2">
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        {
                                                                                            ((filteredList?.find((job) => job.id === ActiveTabs)?.currencytype != null) && (filteredList?.find((job) => job.id === ActiveTabs)?.salarypackage != "0")) && (
                                                                                                <>
                                                                                                    <div className="bg-light p-2"><FaMoneyBill1Wave /></div>
                                                                                                    <div>
                                                                                                        <p className="small m-0 fw-bold">{filteredList?.find((job) => job.id === ActiveTabs)?.currencytype}  {filteredList?.find((job) => job.id === ActiveTabs)?.salarypackage} </p>

                                                                                                    </div>
                                                                                                </>

                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <div className="bg-light p-2"><i className="ri-eye-line"></i></div>
                                                                                        <div>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">Job Type:</span>
                                                                                                {filteredList?.find((job) => job.id === ActiveTabs)?.employmenttype} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <div className="bg-light p-2"><i className="ri-user-line"></i></div>
                                                                                        <div>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">No of Openings:</span>
                                                                                                {filteredList?.find((job) => job.id === ActiveTabs)?.postion_no} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="d-flex gap-2 align-items-center">
                                    <div className="bg-light p-2">
                                        <i className="ri-calendar-2-line"></i></div>
                                    <div>
                                        <p className="small m-0 fw-bold">Impressions </p>
                                        <span className="small text-muted">363</span>
                                    </div>
                                </div> */}
                                                                                    <div className="align-items-center">
                                                                                        {/* <a onClick={() => {
                                                    handleShow()
                                                    GetJobListById(JobList[ActiveTabs]?.id)
                                                }} href="#" className="default-btn py-2 rounded"><i
                                                    className="ri-pencil-line editbtss" ></i>
                                                    Edit</a> */}
                                                                                        {filteredList?.find((job) => job.id === ActiveTabs)?.status == "1" ? (
                                                                                            <>
                                                                                                <a href="#" onClick={() => setDeletePostshow(true)}
                                                                                                    className="default-btn bg-success m-1 py-2 rounded">Active</a>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <a href="#"
                                                                                                    className="default-btn bg-danger m-1 py-2 rounded">UnActive</a>
                                                                                            </>
                                                                                        )
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* <--------- Delete Post ----------------> */}
                                                                            <Modal className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                                                                aria-hidden="true" show={DeletePostshow}
                                                                                onHide={() => setDeletePostshow(false)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Are You Sure For UnActive Job ?</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <p>You cannot activate jobs once you deactivate them.!!!</p>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="secondary" onClick={() => setShow(false)}>
                                                                                        Skip
                                                                                    </Button>
                                                                                    <Button variant="primary" onClick={() => {
                                                                                        setDeletePostshow(false)
                                                                                        DeleteJobs(filteredList?.find((job) => job.id === ActiveTabs)?.id)
                                                                                    }}>
                                                                                        Save
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                            {/* <---------- Edit Post --------------------> */}
                                                                            <Modal size='lg' className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                                                                aria-hidden="true" show={show} onHide={() => setShow(false)}>
                                                                                <Modal.Body>

                                                                                    <div className="modal-header">
                                                                                        <h5 className="modal-title" id="exampleModalLabel">Edit Post</h5>
                                                                                        <button type="button" className="btn-close" onClick={() => setShow(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="client_filter-job pt-1 pb-0">

                                                                                        <form method="post" className="mt-0" id="msform">
                                                                                            <div className="flexdr position-relative">
                                                                                                <div className="sf-steps-form sf-radius">
                                                                                                    <ul className="sf-content m-0">
                                                                                                        <div className="find-com-box p-0">

                                                                                                            <div className="professional-sp row">
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Upload
                                                                                                                            Logo</label>
                                                                                                                        <section className="bg-diffrent">
                                                                                                                            <div className="file-upload-contain">
                                                                                                                                <label htmlFor="file-upload" className="file-input theme-explorer-fas file-input-ajax-new file-thumb-loading">
                                                                                                                                    <div className="file-preview">
                                                                                                                                        <div className="file-drop-zone clickable" tabIndex="-1">
                                                                                                                                            <div className="file-drop-zone-title">
                                                                                                                                                <div className="upload-area">
                                                                                                                                                    <i className="ri-upload-cloud-line"></i>
                                                                                                                                                    <p>Browse or Drag and Drop .jpg, .png, .gif</p>
                                                                                                                                                    <div>
                                                                                                                                                        <a className="default-btn rounded-pill">Browse File</a>
                                                                                                                                                        <input id="file-upload" type="file"
                                                                                                                                                            name='Logo'
                                                                                                                                                            accept=".jpg,.gif,.png" onChange={(e) => setLogo(e.target.files[0])}
                                                                                                                                                            multiple hidden />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="clearfix"></div>
                                                                                                                                            <div className="file-preview-status text-center text-success">
                                                                                                                                                {selectedFiles.length > 0 ? `${selectedFiles.length} files selected` : 'No files selected'}
                                                                                                                                            </div>
                                                                                                                                            <div className="kv-fileinput-error file-error-message"
                                                                                                                                                style={{ display: "none" }} ></div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                        </section>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Job Title
                                                                                                                            *</label>
                                                                                                                        <input type="text" name="title"
                                                                                                                            value={title}
                                                                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                                                                            placeholder="Title" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Job
                                                                                                                            Location</label>
                                                                                                                        <input type="text" name="location" value={location}
                                                                                                                            onChange={(e) => setlocation(e.target.value)}
                                                                                                                            placeholder="Location" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Enter
                                                                                                                            Your Organisation *</label>
                                                                                                                        <input type="text" name="organization"
                                                                                                                            onChange={(e) => setorganization(e.target.value)}
                                                                                                                            value={organization}
                                                                                                                            placeholder="Your Organisation" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Website
                                                                                                                            URL </label>
                                                                                                                        <p className="small">The URL can be your
                                                                                                                            organization's website or an
                                                                                                                            opportunity-related
                                                                                                                            URL.</p>
                                                                                                                        <input type="text" name="companyurl"
                                                                                                                            value={companyurl}
                                                                                                                            onChange={(e) => setCompanyUrl(e.target.value)}
                                                                                                                            placeholder="Website URL"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Skills to
                                                                                                                            be Accessed </label>
                                                                                                                        <p className="small">List required
                                                                                                                            skills to attract participants
                                                                                                                            with matching abilities or
                                                                                                                            engage eager individuals seeking
                                                                                                                            to improve these skills.</p>
                                                                                                                        <TagsInput value={skills}
                                                                                                                            name="skills"
                                                                                                                            onChange={(skill) => setskills(skill)} />
                                                                                                                        <ul id="tagList">
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className="form-group">

                                                                                                                        <select name='expertise' value={expertise} onChange={(e) => setexpertise(e.target.value)}>
                                                                                                                            <option>Select Expertise</option>
                                                                                                                            <option value="Beginner">Beginner</option>
                                                                                                                            <option value="Intermediate">Intermediate</option>
                                                                                                                            <option value="Advanced">Advanced</option>
                                                                                                                            <option value="Expert">Expert</option>
                                                                                                                        </select>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <div className="d-flex gap-2">
                                                                                                                            <div className="w-100">

                                                                                                                                <label className="fieldlabels">Application
                                                                                                                                    Start date</label>
                                                                                                                                <input type="date"
                                                                                                                                    value={moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                                                                                                    onChange={(e) => setstartDate(e.target.value)}
                                                                                                                                    placeholder="start date" />


                                                                                                                            </div>
                                                                                                                            <div className="w-100">
                                                                                                                                <label className="fieldlabels">End
                                                                                                                                    date</label>
                                                                                                                                <input type="date"
                                                                                                                                    value={moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                                                                                                    onChange={(e) => setendDate(e.target.value)}
                                                                                                                                    placeholder="end date" />
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Employment
                                                                                                                            type</label>
                                                                                                                        <select className="form-controls"
                                                                                                                            onChange={(e) => setemploymentType(e.target.value)}
                                                                                                                            value={employmentType}
                                                                                                                            name='employmentType'>
                                                                                                                            <option>Select</option>
                                                                                                                            <option value="Full time">Full time</option>
                                                                                                                            <option value="Part-time">Part-time</option>
                                                                                                                            <option value="Freelance">Freelance</option>
                                                                                                                            <option value="Trainee">Trainee </option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </div>



                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Salary
                                                                                                                            Range/Year</label>
                                                                                                                        <div className="d-flex gap-2">
                                                                                                                            <div>
                                                                                                                                <select name='currencyType'
                                                                                                                                    onChange={(e) => setcurrencyType(e.target.value)}
                                                                                                                                    value={currencyType} style={{ width: "100px" }}>
                                                                                                                                    <option value="INR">INR</option>
                                                                                                                                    <option value="USA">USA</option>
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                            <input type="number"
                                                                                                                                placeholder="Crunt Enter salary"
                                                                                                                                className="form-control" name="salarypackage"
                                                                                                                                value={salarypackage}
                                                                                                                                onChange={(e) => setsalaryPackage(e.target.value)}
                                                                                                                            />

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="col-12 col-md-12">
                                                                                                                    <div className="form-group">
                                                                                                                        <label className="fieldlabels">Job
                                                                                                                            Description *</label>
                                                                                                                        <ReactQuill className="aboutPageEditorContainer"
                                                                                                                            value={aboutJob}
                                                                                                                            name="aboutJob"
                                                                                                                            onChange={(contant) => setaboutJob(contant)}
                                                                                                                        />
                                                                                                                        <span id="count">100 to 1500</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </ul>
                                                                                                </div>

                                                                                                <div className="sf-steps-navigation d-flex justify-content-between">
                                                                                                    <span id="sf-msg" className="sf-msg-error"></span>
                                                                                                    <button id="sf-next" type="button"
                                                                                                        className="btn default-btn"
                                                                                                        onClick={() => EditJobList(JobList?.id)}>Submit</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </Modal.Body>
                                                                            </Modal>
                                                                        </div>
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Skills</h5>
                                                                                <div>
                                                                                    {filteredList?.find((job) => job.id === ActiveTabs)?.skills
                                                                                        ?.replace(/[{}"\\]/g, '')
                                                                                        ?.split(/,\s*/)
                                                                                        ?.map((skill, index, array) => (
                                                                                            <span key={index}>
                                                                                                {skill.trim()}
                                                                                                {index < array.length - 1 && ', '}
                                                                                            </span>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="100"
                                                                            className="scrollspy-example" tabindex="0">
                                                                            <div id="scrollspyHeading1" className="instructors-details-contents mt-3">
                                                                                <div className="card-instrutors shadow bg-white p-4">
                                                                                    <h5>Details</h5>

                                                                                    {ReactHtmlParser(filteredList?.find((job) => job.id === ActiveTabs)?.aboutjob)}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            ) : (
                                <>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className='text-center mt-5' style={{ marginTop: "100px", marginBottom: "30px" }}>
                                        <h4>Not Any Job Avaiable Here....</h4>
                                    </div>
                                </>

                            )
                        }
                    </>
                )
            }

            <Recruiter_Footer />
        </div>
    )
}

export default RecruiterJobs