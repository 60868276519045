import React, { useState, useEffect, useRef } from "react";
import Student_Header from "../student_header/Student_Header";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  GetStudentClassMcq,
  GetStudentStep,
  PostStudentMcq,
  StudentClassData,
  StudentCourseListTraningProgramClassess,
  StudentPostStep,
} from "../../../../api/Student";
import { TypeAnimation } from "react-type-animation";
import CommonImageTransport from "../../../common/images/CommonImages";
import YouTubePlayer from "../../../common/youtube/YoutubePlayer";
import Accordion from "react-bootstrap/Accordion";
import Student_Footer from "../student_footer/Student_Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import TypewriterHtmlAnimation from "../../../common/typewriterHtmlAnimation/TypewriterHtmlAnimation";

const Student_Coaching_Session_Complete = () => {
  const { course_id } = useParams();
  const [is_Loading, setIs_Loading] = useState(false);
  const [is_current_work, set_is_current_work] = useState("0");
  const [CourseList, setCourseList] = useState([]);
  const [ClassList, setClassList] = useState([]);

  const [McqsList, setMcqsList] = useState([]);
  const [ClassDataList, setClassDataList] = useState([]);
  console.log("classList", ClassDataList);
  const [GetMcqsResultList, setGetMcqsResultList] = useState({});
  console.log("GetMcqsResultList", GetMcqsResultList);
  const [StudentStepList, setStudentStepList] = useState({});
  const student_id = localStorage.getItem("User_id");
  const [rightAnswerCount, setRightAnswerCount] = useState(0);
  console.log("Hello1", rightAnswerCount);
  const [wrongAnswerCount, setWrongAnswerCount] = useState(0);
  console.log("Hello2", wrongAnswerCount);
  const [totalAnswerCount, setTotalAnswerCount] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  console.log("currentVideoIndex", currentVideoIndex);
  const [currentYoTubeIndex, setCurrentYouTubeIndex] = useState(0);
  console.log("currentYoTubeIndex", currentYoTubeIndex);
  const [TotalYouTubeVideoIndex, setTotalYouTubeVideoIndex] = useState([]);
  const [TotalVideosIndex, setTotalVideosIndex] = useState([]);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showQuestionView, setShowQuestionView] = useState(false);
  const [showresultSection, setShowResultSection] = useState(false);
  const [showQuizSction, setShowQuizSction] = useState(true);
  const [showNext, setShowNext] = useState(false);
  console.log("ShowNext+1234567", showNext);
  const [showNextYouTube, setShowNextYouTube] = useState(false);
  const [showNextVideo, setShowNextVideo] = useState(false);
  console.log("showNextYouTube", showNextYouTube);
  const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  // const [showNextYouTubeVideoSection, setShowNextYouTubeVideoSection] = useState(false);
  const [activeClassIndex, setActiveClassIndex] = useState(null);
  const [isFirstLoadComplete, setIsFirstLoadComplete] = useState(false);

  //   useEffect(() => {
  //     const savedIndex = localStorage.getItem('activeAccordionIndex');
  //     if (savedIndex !== null) {
  //         setActiveClassIndex(parseInt(savedIndex));
  //     } else {
  //         setActiveClassIndex(0); // Default to the first accordion item if no index is saved
  //     }
  // }, []);

  // Extract plain text from the HTML content for animation
  const plainText =
    ClassDataList?.length >= 0 &&
    ClassDataList[0]?.course_notes?.replace(/<[^>]+>/g, ""); // Remove HTML tags

  //    const handleAnimationComplete = () => {
  //      setIsAnimationComplete(true);
  //    };
  const [currentQustionIndex, setCurrentQustionIndex] = useState(0);
  console.log("currentQustionIndex", currentQustionIndex);
  const [selectedOptions, setSelectedOptions] = useState({});
  console.log("selectedOptions", selectedOptions);
  const [lastTime, setLastTime] = useState(0);
  const videoRef = useRef(null);

  // <----------- Indxing of the is_current_work --------------->
  const tabs = ["0", "1", "2"];

  // <------- User Authentication token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <----------- Handle Previous Pages ------------------>
  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(is_current_work);
    const previousIndex = (currentIndex - 1 + tabs?.length) % tabs?.length;
    set_is_current_work(tabs[previousIndex]);
  };

  // <----------- Handle Next Pages ------------------>
  const handleNext = () => {
    const currentIndex = tabs.indexOf(is_current_work);
    const nextIndex = (currentIndex + 1) % tabs?.length;
    set_is_current_work(tabs[nextIndex]);
  };

  // <---------- Full Screen Functionlity -------------->
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // <--------- Get the Student Course & Classes Lists -------------->
  const HandleGetCourseList = async () => {
    const data = {
      course_id,
    };
    try {
      const response = await StudentCourseListTraningProgramClassess(
        data,
        headers
      );
      setCourseList(response?.data?.data);
      setClassList(response?.data?.data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("HandleGetCourseList", ClassList?.coursefiles);

  const [errorMessages, setErrorMessages] = useState({});
  const [rightSideErrorMessage, setRightSideErrorMessage] = useState(null);

  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentClassessData = async (class_id, index) => {
    setIs_Loading(true);
    const data = {
      course_id: course_id,
      class_id: class_id,
    };
    try {
      const response = await StudentClassData(data, headers);

      if (response?.data?.statusCode === "400") {
        toast.error(response?.data?.msg);

        // Update errorMessages for the specific accordion item
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [index]: response?.data?.msg,
        }));

        // Set the right side error message
        setRightSideErrorMessage(response?.data?.msg);

        setIs_Loading(false);
        return false;
      }

      // Clear any previous error for this item if successful
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [index]: null,
      }));

      // Clear the right-side error if the data loaded successfully
      setRightSideErrorMessage(null);

      setClassDataList(response?.data?.data[0]?.coursefiles);
      setMcqsList(response?.data?.data[0]?.coursemcqs);
      setIs_Loading(false);
      return true;
    } catch (error) {
      setIs_Loading(false);
      console.error("Error in HandleStudentClassessData:", error);
      return false;
    }
  };

  console.log("setClassDataList", ClassDataList);
  // <------------ Get the Video Url and title ------------------>
  // Access the vedio_file array
  const videoFileArray =
    ClassDataList?.[0]?.coursevedioslist?.[0]?.vedio_file || [];
  const videoFileLength = videoFileArray.length;
  console.log("Video File Array Length:", videoFileLength);

  // Get the current video data
  const videoFileString = videoFileArray[currentVideoIndex] || null;
  const videoData = videoFileString ? JSON.parse(videoFileString) : null;
  console.log("videoData1234", videoData);

  const currentVideoUrl = videoData?.url;
  const currentVideoTitle = videoData?.title;

  console.log("Current Video URL:", currentVideoUrl);
  console.log("Current Video Title:", currentVideoTitle);

  // <--------------- Get the YouLink's Array Length ---------------->
  const youtubeLinkDataString =
    ClassDataList?.length >= 0 &&
    ClassDataList[0]?.coursevedioslist[0]?.youtube_link
      ? ClassDataList[0]?.coursevedioslist[0]?.youtube_link[0]
      : null;
  let youtubeLinkDataArray = [];
  if (youtubeLinkDataString) {
    youtubeLinkDataArray = JSON?.parse(youtubeLinkDataString);
  }
  console.log("youtubeLinkDataArray", youtubeLinkDataArray);

  // <---------------- Handle the When we Click on the Prev button on the Video section's ------------->
  const handleYoutubeVideos = () => {
    setCurrentVideoIndex((prevIndex) => {
      // If current video is the first one, loop back to the last video
      // if (prevIndex == 0) {
      //   return youtubeLinkDataArray.length - 1; // Set index to last video
      // }
      // Otherwise, go to the previous video
      return prevIndex;
    });
  };

  // <------------ Get the Youtube video Url and title ------------------>
  const YouTubeLinkData =
    ClassDataList?.length >= 0 &&
    ClassDataList[0]?.coursevedioslist[0]?.youtube_link
      ? JSON.parse(ClassDataList[0].coursevedioslist[0].youtube_link)
      : [];
  const currentYouTubeLink = YouTubeLinkData[currentYoTubeIndex];
  const currentYouTubeLinkTitle = currentYouTubeLink?.yt_title;
  const currentYouTubeLinkUrl = currentYouTubeLink?.yt_link;

  // Extract YouTube video ID for embedding or other purposes
  const extractYouTubeVideoId = (url) => {
    const urlObj = new URL(url);
    const videoId =
      urlObj.searchParams.get("v") || urlObj.pathname.split("/").pop();
    return videoId;
  };

  // <------------ Extract the Current Youtube video id from extractYouTubeVideoId --------------->
  const currentYouTubeVideoId = currentYouTubeLinkUrl
    ? extractYouTubeVideoId(currentYouTubeLinkUrl)
    : null;
  console.log("currentYouTubeVideoId", currentYouTubeVideoId);
  //<------------------ Handle next button for the next Youtube Link-------------->
  const handleNextYouTubeLink = () => {
    if (currentYoTubeIndex < YouTubeLinkData?.length - 1) {
      setCurrentYouTubeIndex(currentYoTubeIndex + 1);
      setShowNextYouTube(false);
    }
  };

  //<------------------ Handle previous button for the prev Youtube Link-------------->
  const handlePrevYouTube = () => {
    if (currentYoTubeIndex > 0) {
      setCurrentYouTubeIndex(currentYoTubeIndex - 1);
      setShowNextYouTube(false);
    }
  };

  // <---------- Check the is video complete or not ------------>
  const handleVideoEnded = () => {
    setIsVideoCompleted(true);
    setTotalVideosIndex((TotalVideosIndex) => [
      ...TotalVideosIndex,
      currentVideoIndex + 1,
    ]);
  };

  // <--------- Next Video Show when next video available here ------------->
  const handleNextVideo = () => {
    if (
      currentVideoIndex <
      ClassDataList[0]?.coursevedioslist[0]?.vedio_file?.length - 1
    ) {
      setCurrentVideoIndex(currentVideoIndex + 1);
      setIsVideoCompleted(false);
      setLastTime(0);
    }
  };

  // <--------- Show the Prev video's  ------------->
  const handlePrevVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
      setIsVideoCompleted(false);
      setLastTime(0);
    }
  };

  // <---------- Track the video's time (lenght) ------------->
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastTime + 0.5) {
      videoRef.current.currentTime = lastTime;
    } else {
      setLastTime(currentTime);
    }
  };

  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentMultipleYouTubeVideosStep = async (class_id) => {
    const data = {
      course_id: course_id,
      class_id: class_id,
      syoutubestatus: TotalYouTubeVideoIndex,
      student_id: student_id,
    };
    try {
      await StudentPostStep(data, headers);
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentMultipleVideosStep = async (class_id) => {
    const data = {
      course_id: course_id,
      class_id: class_id,
      svideostatus: TotalVideosIndex,
      student_id: student_id,
    };
    try {
      await StudentPostStep(data, headers);
    } catch (error) {
      console.log("error", error);
    }
  };
  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentPostStep = async (class_id) => {
    const data = {
      course_id: course_id,
      class_id: class_id,
      is_vedio: 1,
      student_id: student_id,
    };
    try {
      const response = await StudentPostStep(data, headers);
      if (response?.data?.statusCode == "200") {
        handleNext();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentPostPDFStep = async (class_id2) => {
    const data = {
      course_id: course_id,
      class_id: class_id2,
      is_notes: 1,
      student_id: student_id,
    };
    try {
      const response = await StudentPostStep(data, headers);
      if (response?.data?.statusCode == "200") {
        handleNext();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // <--------- start the Test ------------>
  const handleStartTest = (e) => {
    e.preventDefault();
    setShowQuestionView(true);
    setShowResultSection(false);
  };

  // <--------- Retake the Test ------------>
  const handleRetakeTest = () => {
    setShowQuizSction(true);
    setShowResultSection(false);
    setSelectedOptions({});
    setTotalAnswerCount("");
    setWrongAnswerCount("");
    setRightAnswerCount("");
    setCurrentQustionIndex(0);

    // Reload the page
    window.location.reload(); // This will refresh the entire window
  };

  // <----------- Show the Result Section's -------------->
  const handleResultSection = (id) => {
    setTimeout(() => {
      HandleGetStudentClassMcq(id);
    }, 1000);
    setShowResultSection(true);
    setShowQuestionView(false);
    setShowQuizSction(false);
  };
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const handleOptionChange = (questionId, selectedValue) => {
    setSelectedOptions((prevState) => {
      const newState = {
        ...prevState,
        [questionId]: {
          value: selectedValue,
          isCorrect:
            selectedValue === McqsList[currentQustionIndex]?.right_option,
        },
      };
      console.log("Updated selectedOptions (before render):", newState);
      return newState;
    });
  };

  useEffect(() => {
    const currentQuestion = McqsList[currentQustionIndex];
    const questionId = currentQuestion?.id;
    const correctAnswer = currentQuestion?.right_option;

    // Get the current selected value and correctness for this question
    const selectedData = selectedOptions[questionId];
    if (selectedData) {
      const { value: selectedValue, isCorrect } = selectedData;

      // Determine if there was a correctness change for this question
      const previousData = attemptedQuestions.find((q) => q.id === questionId);
      const previousCorrect = previousData?.isCorrect;

      // If this is the first answer attempt, initialize counts
      if (!previousData) {
        setAttemptedQuestions((prev) => [
          ...prev,
          { id: questionId, isCorrect },
        ]);
        setTotalAnswerCount((prevCount) => prevCount + 1);

        if (isCorrect) {
          setRightAnswerCount((prevCount) => prevCount + 1);
        } else {
          setWrongAnswerCount((prevCount) => prevCount + 1);
        }
      } else if (previousCorrect !== isCorrect) {
        // If correctness changed, update counts accordingly
        setAttemptedQuestions((prev) =>
          prev.map((q) => (q.id === questionId ? { ...q, isCorrect } : q))
        );

        // Update counts based on the correctness change
        if (isCorrect) {
          setRightAnswerCount((prevCount) => prevCount + 1);
          setWrongAnswerCount((prevCount) => prevCount - 1);
        } else {
          setRightAnswerCount((prevCount) => prevCount - 1);
          setWrongAnswerCount((prevCount) => prevCount + 1);
        }
      }
    }
  }, [selectedOptions, McqsList, currentQustionIndex, attemptedQuestions]);

  // Monitor state changes with useEffect for debugging
  useEffect(() => {
    console.log("Right Answer Count:", rightAnswerCount);
  }, [rightAnswerCount]);

  useEffect(() => {
    console.log("Wrong Answer Count:", wrongAnswerCount);
  }, [wrongAnswerCount]);

  useEffect(() => {
    console.log("Total Answer Count:", totalAnswerCount);
  }, [totalAnswerCount]);

  // <------------Active the Current question ------------->
  const currentQuestion =
    McqsList?.length >= 0 && McqsList[currentQustionIndex];
  const options = currentQuestion ? JSON.parse(currentQuestion.options) : {};

  //<------------------- Handle next button on the Qustion's -------------->
  const handleNextQustion = () => {
    if (currentQustionIndex < McqsList?.length - 1) {
      setCurrentQustionIndex(currentQustionIndex + 1);
    }
  };

  //<------------------- Handle prev button on the Qustion's -------------->
  const handlePrevQustion = () => {
    if (currentQustionIndex > 0) {
      setCurrentQustionIndex(currentQustionIndex - 1);
    }
  };

  // <------------  Get the Student Classes Video Notes and MCQ -------->
  const HandleStudentPostMCQStep = async (class_id3) => {
    const data = {
      course_id: course_id,
      class_id: class_id3,
      is_mcq: 1,
      student_id: student_id,
    };
    try {
      const response = await StudentPostStep(data, headers);
      if (response?.data?.statusCode == "200") {
        HandleGetStudentStep(class_id3);
        HandleGetCourseList(course_id);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------------ Course Complete -------------->
  const HandleStudentCourseComplete = async (class_id3) => {
    const data = {
      course_id: course_id,
      class_id: class_id3,
      is_mcq: 1,
      is_courser_complete: 1,
      student_id: student_id,
    };
    try {
      const response = await StudentPostStep(data, headers);
      if (response?.data?.statusCode == "200") {
        HandleGetStudentStep(class_id3);
        HandleGetCourseList(course_id);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------------ submit the Mcq's Qustion's ------------>
  const handleStudentPostMcqs = async (class_id) => {
    const answerQuestion = {};
    Object.keys(selectedOptions).forEach((questionId) => {
      const selectedValue = selectedOptions[questionId];
      answerQuestion[questionId] = selectedValue;
      console.log("answerQuestion", selectedValue);
    });
    const submissionObject = {
      id: 4,
      course_id: course_id,
      class_id: class_id,
      total_answer: totalAnswerCount,
      right_answer: rightAnswerCount,
      wrong_answer: wrongAnswerCount,
      answer_question: answerQuestion,
    };
    try {
      await PostStudentMcq(submissionObject, headers);
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------ get-student-class-mcq ------------->
  const HandleGetStudentClassMcq = async (class_id3) => {
    const data = {
      class_id: class_id3,
      course_id,
    };
    try {
      const response = await GetStudentClassMcq(data, headers);
      setGetMcqsResultList(response?.data?.data);
      HandleGetStudentStep(class_id3);
    } catch (error) {
      console.log("error", error);
    }
  };

  // <------ get-student-step ------------->
  const HandleGetStudentStep = async (class_id) => {
    const data = {
      course_id: course_id,
      class_id: class_id,
      student_id: student_id,
    };
    try {
      const respone = await GetStudentStep(data, headers);
      setStudentStepList(respone?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Convert syoutubestatus to an array of numbers
  const syoutubestatus = StudentStepList?.syoutubestatus;
  const getCompleteYouTubeVideosArray = () => {
    return syoutubestatus
      ?.map((numStr) => parseInt(numStr, 10)) // Convert each value to a number
      .filter((num) => !isNaN(num)); // Filter out invalid numbers
  };

  const completeYouTubeVideosArray = getCompleteYouTubeVideosArray();
  console.log("completeYouTubeVideosArray", completeYouTubeVideosArray);

  // <------- get the List of the Complete Watched List ---------->
  const svideostatus = StudentStepList?.svideostatus;
  const getCompleteVideosArray = () => {
    if (svideostatus) {
      try {
        const cleanString = svideostatus.replace(/[{}]/g, "");
        const stringArray = cleanString.split(",");
        return stringArray
          .map((str) => parseInt(str, 10))
          .filter((num) => !isNaN(num));
      } catch (error) {
        console.error("Error processing svideostatus:", error.message);
        return [];
      }
    }
  };
  const completeVideosArray = getCompleteVideosArray();
  console.log("completeVideosArray+++123456", completeVideosArray);

  // const GetCompleteVideosArray = GetCompleteVideosSplit.map(videoCheckStatus);

  // <-------- Track if Video is Fully Watched -------------->
  const handleVideoFullyPlayed = () => {
    setShowNextYouTube(false);

    setTotalYouTubeVideoIndex((prevIndexes) => {
      const newIndex = currentYoTubeIndex + 1;

      // Check if the new index is already in the array to prevent duplicates
      if (!prevIndexes.includes(newIndex)) {
        const updatedIndexes = [...prevIndexes, newIndex];
        // Save the updated list to localStorage
        localStorage.setItem(
          "TotalYouTubeVideoIndex",
          JSON.stringify(updatedIndexes)
        );
        return updatedIndexes;
      }

      // If already exists, return previous state without changes
      return prevIndexes;
    });

    setShowNext(true);
  };

  const handleError = (errorCode) => {
    console.log("Error occurred with code:", errorCode);
  };

  // <---------- Render the student Course List here --------->
  useEffect(() => {
    HandleGetCourseList();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }, [currentVideoUrl, currentYouTubeLinkUrl]);

  // <------------ Fetch Handle Student Classess ---------------->
  // useEffect(() => {
  //   if (ClassList?.coursefiles?.length > 0) {
  //     const firstClassListId = ClassList?.coursefiles[0]?.id;
  //     HandleStudentClassessData(firstClassListId);
  //   }
  // }, [ClassList]);

  // <------Render the  HandleGetStudentStep  function------------->
  // useEffect(() => {
  //   if (ClassList?.coursefiles?.length > 0) {
  //     const firstClassListId = ClassList?.coursefiles[0]?.id;
  //     HandleGetStudentStep(firstClassListId);
  //   }
  // }, [ClassList]);

  // <------Render the  HandleGetStudentClassMcq  function------------->
  // useEffect(() => {
  //   if (ClassList?.coursefiles?.length > 0) {
  //     const firstClassListId = ClassList?.coursefiles[0]?.id;
  //     HandleGetStudentClassMcq(firstClassListId);
  //   }
  // }, [ClassList]);

  // <------Render the  HandleGetStudentClassMcq  function------------->
  useEffect(() => {
    if (
      StudentStepList?.is_vedio == 1 &&
      StudentStepList?.is_notes == 0 &&
      StudentStepList?.is_mcq == 0
    ) {
      set_is_current_work("1");
    }
    if (
      StudentStepList?.is_vedio == 1 &&
      StudentStepList?.is_notes == 1 &&
      StudentStepList?.is_mcq == 0
    ) {
      set_is_current_work("2");
    }
    if (
      StudentStepList?.is_vedio == 0 &&
      StudentStepList?.is_notes == 0 &&
      StudentStepList?.is_mcq == 0
    ) {
      set_is_current_work("0");
    }
    if (
      StudentStepList?.is_vedio == undefined &&
      StudentStepList?.is_notes == undefined &&
      StudentStepList?.is_mcq == undefined
    ) {
      set_is_current_work("0");
    }
    if (
      StudentStepList?.is_vedio == 1 &&
      StudentStepList?.is_notes == 1 &&
      StudentStepList?.is_mcq == 1
    ) {
      setShowResultSection(true);
    }
  }, [StudentStepList]);

  // <----------- Check the youtube Linkd data is present here -------------->
  useEffect(() => {
    if (currentYouTubeVideoId == null) {
      setShowNextYouTube(true);
    } else if (currentYouTubeVideoId != null) {
      setShowNextYouTube(false);
    }
  }, [currentYouTubeVideoId]);

  // <-------------- total Over All Performance ------------>
  const TotalOverAllPerformance =
    (parseInt(GetMcqsResultList?.right_answer) / parseInt(McqsList?.length)) *
    100;
  console.log("TotalOverAllPerformance", TotalOverAllPerformance);
  // <------- when Notes completed --------------->
  const handleAnimationComplete = () => {
    setIsAnimationComplete(true);
  };
  console.log("handleAnimationComplete", isAnimationComplete);

  const watchedVideos = StudentStepList?.svideostatus
    ? StudentStepList.svideostatus.replace(/[{}]/g, "").split(",").map(Number)
    : [];

  // Create a Set to track unique class IDs
  const uniqueClasses = [];
  const seenIds = new Set();

  // Filter unique classes by `id`
  ClassList?.coursefiles?.forEach((item) => {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id);
      uniqueClasses?.push(item);
    }
  });

  const scrollRef = useRef(null);

  // Scroll to the bottom whenever the text updates
  useEffect(() => {
    if (!isAnimationComplete && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isAnimationComplete, plainText]);

  const courseNotes =
    ClassDataList[0]?.course_notes || "<p>No notes available</p>";

  useEffect(() => {
    // Check if it's the first time the component is rendered
    const firstTime = localStorage.getItem("isFirstRender");

    if (!firstTime) {
      localStorage.setItem("isFirstRender", "true"); // Set flag in localStorage
      window.location.reload(); // Refresh the page
    } else {
      // Attempt to get active class index from localStorage
      const GetActiveClassIndex = localStorage.getItem("ActiveClassIndex");

      if (GetActiveClassIndex !== null) {
        // If found in localStorage, use it
        setActiveClassIndex(parseInt(GetActiveClassIndex));
      } else if (ClassList?.coursefiles?.length > 0) {
        // If not found in localStorage, find the first incomplete class
        const firstIncompleteIndex = ClassList?.coursefiles?.findIndex(
          (item) => item?.isClassComplete === false
        );

        // If a first incomplete class exists, use that index
        const defaultIndex =
          firstIncompleteIndex !== -1 ? firstIncompleteIndex : 0;
        setActiveClassIndex(defaultIndex);
        localStorage.setItem("ActiveClassIndex", defaultIndex);
      }

      // Trigger relevant functions based on activeClassIndex
      if (ClassList?.coursefiles?.length > 0 && activeClassIndex !== null) {
        const activeClass = ClassList?.coursefiles[activeClassIndex];
        if (activeClass) {
          set_is_current_work("0");
          HandleStudentClassessData(activeClass.id);
          HandleGetStudentStep(activeClass.id);
          HandleGetStudentClassMcq(activeClass.id);
        }
      }
    }
  }, [ClassList?.coursefiles]);

  // Handle accordion item click to set active index and store it
  const handleAccordionClick = (index) => {
    setActiveClassIndex(index);
    localStorage.setItem("ActiveClassIndex", index);

    const activeClass = ClassList?.coursefiles[index];
    if (activeClass) {
      // Fetch the relevant data for this class
      HandleStudentClassessData(activeClass.id);
      HandleGetStudentStep(activeClass.id);
      HandleGetStudentClassMcq(activeClass.id);
    }
  };

  // Assuming completeVideosArray is an array of numbers, find the maximum index
  const maxVideoIndex = completeVideosArray
    ? Math.max(...completeVideosArray)
    : 0;
  console.log("maxVideoIndex", maxVideoIndex);

  const MaxYouTubeIndex = completeYouTubeVideosArray
    ? Math.max(...completeYouTubeVideosArray)
    : 0;
  console.log("MaxYouTubeIndex", MaxYouTubeIndex);

  return (
    <div>
      <Student_Header />
      <div className="header_space"></div>
      <div className="courses-area py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="inner-banner inner-banner-bg">
                <div className="inner-title p-3">
                  <h4 className="text-white">{CourseList[0]?.title}</h4>
                </div>
              </div>
              <div className="bg_gradient p-3 rounded mb-3 gap-3 align-items-center">
                <div className="instructors-details-contents w-100">
                  <ToastContainer style={{ marginTop: "120px" }} />
                  <hr />
                  <ul
                    className="hireprocessview customeaccordions coursestrash p-0"
                    data-steps="5"
                  >
                    <Accordion defaultActiveKey={activeClassIndex?.toString()}>
                      {ClassList?.coursefiles?.map((ClassListResult, index) => {
                        const isDisabled =
                          index > 0 &&
                          !ClassList?.coursefiles[index - 1]?.isClassComplete;
                        return (
                          <Accordion.Item
                            eventKey={index.toString()}
                            key={ClassListResult?.id}
                            className={`accordion-item-custom ${
                              isDisabled ? "disabled" : ""
                            }`}
                          >
                            <Accordion.Header
                              className={`accordion-header-custom ${
                                isDisabled ? "disabled" : ""
                              }`}
                              onClick={async () => {
                                if (!isDisabled) {
                                  const success =
                                    await HandleStudentClassessData(
                                      ClassListResult?.id,
                                      index
                                    );

                                  if (success) {
                                    setActiveClassIndex(index);
                                    set_is_current_work("0");
                                    HandleGetStudentStep(ClassListResult?.id);
                                    HandleGetStudentClassMcq(
                                      ClassListResult?.id
                                    );
                                    localStorage.setItem(
                                      "ActiveClassIndex",
                                      index
                                    );
                                  }
                                }
                              }}
                            >
                              {ClassListResult?.isClassComplete ? (
                                <div>
                                  <h6 className="text-success">
                                    ✅ Day-{index + 1} ({ClassListResult?.title}
                                    )
                                  </h6>
                                </div>
                              ) : (
                                <div>
                                  <h6>
                                    Day-{index + 1} ({ClassListResult?.title})
                                  </h6>
                                </div>
                              )}
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body-custom">
                              {errorMessages[index] ? (
                                <div className="error-message text-danger">
                                  {errorMessages[index]}
                                </div>
                              ) : (
                                <>
                                  <div className="sub_he111">
                                    {StudentStepList?.is_vedio == 1 ? (
                                      <>
                                        <div className="sub_he">
                                          <img
                                            src={CommonImageTransport?.checked}
                                            style={{ width: "20px" }}
                                          />{" "}
                                          Videos
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="sub_he">
                                          <img
                                            src={CommonImageTransport?.clock}
                                            style={{ width: "20px" }}
                                          />{" "}
                                          Videos
                                        </div>
                                      </>
                                    )}
                                    {YouTubeLinkData?.map(
                                      (YouTubeTitleResult, ytIndex) => {
                                        const youTubeVideoNumber = ytIndex + 1;

                                        // Check if the current video is completed by comparing with the completeYouTubeVideosArray
                                        const isYouTubeVideosCompletedChecked =
                                          completeYouTubeVideosArray?.some(
                                            (completedIndex) =>
                                              completedIndex >=
                                              youTubeVideoNumber
                                          );
                                        console.log(
                                          "isYouTubeVideosCompletedChecked",
                                          completeYouTubeVideosArray
                                        );
                                        return (
                                          <div key={ytIndex}>
                                            <h6 className="sub__title">
                                              {isYouTubeVideosCompletedChecked ? (
                                                <div>
                                                  <span className="text-success">
                                                    ✅ Video {index + 1}.
                                                    {ytIndex + 1}-
                                                    {
                                                      YouTubeTitleResult?.yt_title
                                                    }
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span className="text-primary">
                                                    <img
                                                      src={
                                                        CommonImageTransport?.expired_new
                                                      }
                                                      style={{ width: "20px" }}
                                                    />{" "}
                                                    Video {index + 1}.
                                                    {ytIndex + 1}-
                                                    {
                                                      YouTubeTitleResult?.yt_title
                                                    }
                                                  </span>
                                                </div>
                                              )}
                                            </h6>
                                          </div>
                                        );
                                      }
                                    )}

                                    {/*<------------ Get the list of the Videos checked and unChecked ---------> */}
                                    {/* {ClassDataList?.length >= 0 &&
                                      ClassDataList[0]?.coursevedioslist[0]?.vedio_file?.map(
                                        (ClassTitleResult, videoIndex) => {
                                          const videoTitle =
                                            JSON.parse(ClassTitleResult);
                                          console.log("videoTitle");
                                          const VideoNumber = videoIndex + 1;
                                          const isVideoCompletedChecked =
                                            completeVideosArray?.includes(
                                              VideoNumber
                                            );
                                          return (
                                            <div key={videoIndex}>
                                              <h6 className="sub__title">
                                                {isVideoCompletedChecked ? (
                                                  <>
                                                    <span className="text-success">
                                                      ✅ Video{index + 1}.
                                                      {YouTubeLinkData?.length +
                                                        (videoIndex + 1)}{" "}
                                                      -{" "}
                                                    </span>
                                                    {videoTitle?.title}
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="text-primary">
                                                      <img
                                                        src={
                                                          CommonImageTransport?.expired_new
                                                        }
                                                        style={{
                                                          width: "20px",
                                                        }}
                                                      />{" "}
                                                      Video {index + 1}.
                                                      {YouTubeLinkData?.length +
                                                        (videoIndex + 1)}{" "}
                                                      -{" "}
                                                    </span>
                                                    {videoTitle?.title}
                                                  </>
                                                )}
                                              </h6>
                                            </div>
                                          );
                                        }
                                      )} */}
                                    {ClassDataList?.length > 0 &&
                                      ClassDataList[0]?.coursevedioslist[0]?.vedio_file?.map(
                                        (ClassTitleResult, videoIndex) => {
                                          const videoTitle =
                                            JSON.parse(ClassTitleResult);
                                          const VideoNumber = videoIndex + 1; // Video numbers typically start from 1

                                          // Get the maximum value from completeVideosArray
                                          const maxCompletedVideo =
                                            completeVideosArray?.reduce(
                                              (max, num) => Math.max(max, num),
                                              0
                                            );

                                          // Check if the current video is marked as completed (in completeVideosArray)
                                          const isVideoCompletedChecked =
                                            VideoNumber <= maxCompletedVideo; // If VideoNumber <= maxCompletedVideo, mark it as completed

                                          return (
                                            <div key={videoIndex}>
                                              <h6 className="sub__title">
                                                {isVideoCompletedChecked ? (
                                                  <>
                                                    {/* Show checked mark for completed video */}
                                                    <span className="text-success">
                                                      ✅ Video {index + 1}.
                                                      {YouTubeLinkData?.length +
                                                        (videoIndex + 1)}{" "}
                                                      - {videoTitle?.title}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    {/* Show icon and video title for incomplete video */}
                                                    <span className="text-primary">
                                                      <img
                                                        src={
                                                          CommonImageTransport?.expired_new
                                                        }
                                                        style={{
                                                          width: "20px",
                                                        }}
                                                        alt="Incomplete video"
                                                      />{" "}
                                                      Video {index + 1}.
                                                      {YouTubeLinkData?.length +
                                                        (videoIndex + 1)}{" "}
                                                      - {videoTitle?.title}
                                                    </span>
                                                  </>
                                                )}
                                              </h6>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  {StudentStepList?.is_notes == 1 ? (
                                    <>
                                      <div className="sub_he">
                                        <img
                                          src={CommonImageTransport?.checked}
                                          style={{ width: "20px" }}
                                        />{" "}
                                        Notes
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="sub_he">
                                        <img
                                          src={CommonImageTransport?.clock}
                                          style={{ width: "20px" }}
                                        />{" "}
                                        Notes
                                      </div>
                                    </>
                                  )}
                                  {StudentStepList?.is_mcq == 1 ? (
                                    <>
                                      <div className="sub_he">
                                        <img
                                          src={CommonImageTransport?.checked}
                                          style={{ width: "20px" }}
                                        />{" "}
                                        Quiz
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="sub_he">
                                        <img
                                          src={CommonImageTransport?.clock}
                                          style={{ width: "20px" }}
                                        />{" "}
                                        Quiz
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </ul>
                </div>
              </div>
            </div>
            {ClassDataList?.length > 0 ? (
              <>
                {is_Loading ? (
                  <>
                    <div className="chat-window text-center">
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          margin: "auto",
                        }}
                        className="spinner-border"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {rightSideErrorMessage ? (
                      <div className="col-md-8 col-lg-9">
                        <h3 className="text-danger mt-5">
                          You have completed a class for this course today, so
                          next class will be open tomorrow.
                        </h3>
                      </div>
                    ) : (
                      <div className="col-md-8 col-lg-9">
                        <div className="courses-details-area pb-70">
                          <div className="courses-details-contact">
                            <div className="tabs_item panel panel-default">
                              <div className="courses-details-tab-content position-relative">
                                <div className="courses-details-accordion">
                                  <div className="gallery-boxes m-0">
                                    {is_current_work === "0" && (
                                      <div className="gal-box galcurr">
                                        <div className="gal-descrip">
                                          <h5 className="border-bottom pb-3">
                                            <span className="ri-play-circle-line"></span>
                                            {
                                              ClassDataList[0]
                                                ?.coursevedioslist[0]?.title
                                            }
                                          </h5>
                                          <div className="courses-items p-2">
                                            {ClassDataList[0]
                                              ?.coursevedioslist[0]
                                              ?.youtube_link &&
                                            !showNextYouTube &&
                                            currentYouTubeVideoId != null ? (
                                              <>
                                                <div
                                                  style={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <YouTubePlayer
                                                    videoId={
                                                      currentYouTubeVideoId
                                                    }
                                                    key={currentYouTubeVideoId}
                                                    onVideoFullyPlayed={
                                                      handleVideoFullyPlayed
                                                    }
                                                    onError={handleError}
                                                    onreplay={
                                                      currentYouTubeVideoId
                                                    }
                                                  />
                                                </div>
                                                {/* <------------ This is a Youtube video title section's --------------> */}
                                                <div className="contend pt-2">
                                                  <h6>
                                                    {currentYouTubeLinkTitle}
                                                  </h6>
                                                </div>
                                                {/* <------------ Next and Prev for the Youtube videos ------------------> */}
                                                <div className="gal-tabs">
                                                  {currentYoTubeIndex > 0 && (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={
                                                        handlePrevYouTube
                                                      }
                                                      className="prv"
                                                    >
                                                      Prev
                                                    </a>
                                                  )}
                                                  <span className="galcount">
                                                    {currentYoTubeIndex + 1}/
                                                    {
                                                      youtubeLinkDataArray.length
                                                    }
                                                  </span>

                                                  {/* <-------- Alrady Seen the YouTube Video's Then Show
                                                   this Button and This not the last youtube video of the  */}
                                                  {currentYoTubeIndex <
                                                    MaxYouTubeIndex &&
                                                    currentYoTubeIndex !=
                                                      youtubeLinkDataArray?.length -
                                                        1 && (
                                                      <>
                                                        {StudentStepList?.syoutubestatus &&
                                                          StudentStepList
                                                            ?.syoutubestatus[0] && (
                                                            <>
                                                              <a
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={async () => {
                                                                  if (
                                                                    StudentStepList?.is_vedio !=
                                                                    1
                                                                  ) {
                                                                    // HandleGetCourseList();
                                                                    HandleStudentClassessData(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                    HandleGetStudentStep(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                  }
                                                                  handleNextYouTubeLink();
                                                                  setShowNext(
                                                                    false
                                                                  );
                                                                }}
                                                                className="nxt"
                                                              >
                                                                Next
                                                              </a>
                                                            </>
                                                          )}
                                                      </>
                                                    )}

                                                  {/* <-------- Alrady Seen the YouTube Video's Then Show
                                                   this Button and This the last youtube video of the  */}
                                                  {currentYoTubeIndex <
                                                    MaxYouTubeIndex &&
                                                    currentYoTubeIndex ==
                                                      youtubeLinkDataArray?.length -
                                                        1 && (
                                                      <>
                                                        {StudentStepList?.syoutubestatus &&
                                                          StudentStepList
                                                            ?.syoutubestatus[0] && (
                                                            <>
                                                              <a
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={async () => {
                                                                  if (
                                                                    StudentStepList?.is_vedio !=
                                                                    1
                                                                  ) {
                                                                    HandleStudentClassessData(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                    HandleGetStudentStep(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                  }
                                                                  setShowNextYouTube(
                                                                    true
                                                                  );

                                                                  if (
                                                                    videoFileLength >
                                                                    0
                                                                  ) {
                                                                    setShowNextYouTube(
                                                                      true
                                                                    );
                                                                  } else {
                                                                    HandleStudentPostStep(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                  }
                                                                }}
                                                                className="nxt"
                                                              >
                                                                Next
                                                              </a>
                                                            </>
                                                          )}
                                                      </>
                                                    )}

                                                  {MaxYouTubeIndex <=
                                                    currentYoTubeIndex && (
                                                    <>
                                                      {(showNext ||
                                                        StudentStepList?.is_vedio ==
                                                          1) &&
                                                        (currentYoTubeIndex ===
                                                        youtubeLinkDataArray?.length -
                                                          1 ? (
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={async () => {
                                                              if (
                                                                StudentStepList?.is_vedio !=
                                                                1
                                                              ) {
                                                                await HandleStudentMultipleYouTubeVideosStep(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                                HandleStudentClassessData(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                                HandleGetStudentStep(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                              }
                                                              setShowNextYouTube(
                                                                true
                                                              );

                                                              if (videoData) {
                                                                setShowNextYouTube(
                                                                  true
                                                                );
                                                              } else {
                                                                HandleStudentPostStep(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                              }
                                                            }}
                                                            className="nxt"
                                                          >
                                                            Next
                                                          </a>
                                                        ) : (
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={async () => {
                                                              if (
                                                                StudentStepList?.is_vedio !=
                                                                1
                                                              ) {
                                                                await HandleStudentMultipleYouTubeVideosStep(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                                // HandleGetCourseList();
                                                                HandleStudentClassessData(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                                HandleGetStudentStep(
                                                                  ClassDataList[0]
                                                                    ?.id
                                                                );
                                                              }
                                                              handleNextYouTubeLink();
                                                              setShowNext(
                                                                false
                                                              );
                                                            }}
                                                            className="nxt"
                                                          >
                                                            Next
                                                          </a>
                                                        ))}
                                                    </>
                                                  )}
                                                </div>
                                              </>
                                            ) : currentVideoUrl ? (
                                              <div
                                                style={{ marginBottom: "20px" }}
                                              >
                                                <video
                                                  ref={videoRef}
                                                  style={{
                                                    width: "100%",
                                                    maxHeight: "500px",
                                                    height: "auto",
                                                    objectFit: "contain",
                                                    display: "block",
                                                    margin: "0 auto",
                                                  }}
                                                  // autoPlay
                                                  controls
                                                  key={currentVideoUrl}
                                                  className="photo-item__video NewsDetailsVideos"
                                                  loop={false}
                                                  muted
                                                  preload="auto"
                                                  onTimeUpdate={
                                                    handleTimeUpdate
                                                  }
                                                  onEnded={handleVideoEnded}
                                                >
                                                  <source
                                                    src={currentVideoUrl}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                                <div className="contend pt-2">
                                                  <h6>{currentVideoTitle}</h6>
                                                </div>
                                              </div>
                                            ) : (
                                              // No YouTube or regular video, trigger the next step
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  HandleStudentClassessData(
                                                    ClassDataList[0]?.id
                                                  );
                                                  HandleGetStudentStep(
                                                    ClassDataList[0]?.id
                                                  );
                                                  HandleStudentPostStep(
                                                    ClassDataList[0]?.id
                                                  );
                                                }}
                                                className="nxt"
                                              >
                                                Next
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                        {/* <---------- If youtube youtube video Available Before the Video's the
                                         Previous Button for the Previous Youtube section's -----------> */}
                                        {showNextYouTube === true &&
                                          youtubeLinkDataArray?.length > 0 &&
                                          currentVideoIndex == 0 && (
                                            <div className="gal-tabs">
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handleYoutubeVideos();
                                                  // setShowNextYouTubeVideoSection(true)
                                                  setShowNextYouTube(false);
                                                }}
                                                className="prv"
                                              >
                                                Prev
                                              </a>
                                            </div>
                                          )}
                                        {/* <---------- Next and prev for the Videos --------------> */}
                                        <div className="gal-tabs">
                                          {currentVideoIndex > 0 &&
                                            showNextYouTube == true && (
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handlePrevVideo();
                                                  // setShowNextYouTube(false)
                                                }}
                                                className="prv"
                                              >
                                                Prev
                                              </a>
                                            )}

                                          {showNextYouTube === true && (
                                            <span className="galcount">
                                              {currentVideoIndex + 1}/
                                              {
                                                ClassDataList[0]
                                                  ?.coursevedioslist[0]
                                                  ?.vedio_file?.length
                                              }
                                            </span>
                                          )}

                                          {/* <-------- Already Seen the Video's Then Show this Button and This not the last video of the ------------------> */}

                                          {currentVideoIndex < maxVideoIndex &&
                                            showNextYouTube &&
                                            currentVideoIndex + 1 !=
                                              ClassDataList[0]
                                                ?.coursevedioslist[0]
                                                ?.vedio_file?.length && (
                                              <>
                                                {StudentStepList?.svideostatus &&
                                                  StudentStepList
                                                    ?.svideostatus[0] && (
                                                    <>
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={async () => {
                                                          if (
                                                            StudentStepList?.is_vedio !=
                                                            1
                                                          ) {
                                                            // HandleGetCourseList();
                                                            HandleStudentClassessData(
                                                              ClassDataList[0]
                                                                ?.id
                                                            );
                                                            HandleGetStudentStep(
                                                              ClassDataList[0]
                                                                ?.id
                                                            );
                                                          }
                                                          handleNextVideo();
                                                        }}
                                                        className="nxt"
                                                      >
                                                        Next
                                                      </a>
                                                    </>
                                                  )}
                                              </>
                                            )}

                                          {/* <--------  If Alraedy seen the videos then show the next button and also with is last videos here ----------> */}
                                          {currentVideoIndex < maxVideoIndex &&
                                            showNextYouTube &&
                                            currentVideoIndex ===
                                              ClassDataList[0]
                                                ?.coursevedioslist[0]
                                                ?.vedio_file?.length -
                                                1 && (
                                              <>
                                                {StudentStepList?.svideostatus &&
                                                  StudentStepList
                                                    ?.svideostatus[0] && (
                                                    <>
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={async () => {
                                                          if (
                                                            StudentStepList?.is_vedio !=
                                                            1
                                                          ) {
                                                            // HandleGetCourseList();
                                                            HandleStudentClassessData(
                                                              ClassDataList[0]
                                                                ?.id
                                                            );
                                                            HandleGetStudentStep(
                                                              ClassDataList[0]
                                                                ?.id
                                                            );
                                                          }
                                                          HandleStudentPostStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                        }}
                                                        className="nxt"
                                                      >
                                                        Next
                                                      </a>
                                                    </>
                                                  )}
                                              </>
                                            )}

                                          {currentVideoIndex >=
                                            maxVideoIndex && (
                                            <>
                                              {(isVideoCompleted ||
                                                (showNextYouTube == true &&
                                                  StudentStepList?.is_vedio ==
                                                    1)) && (
                                                <>
                                                  {currentVideoIndex ===
                                                  ClassDataList[0]
                                                    ?.coursevedioslist[0]
                                                    ?.vedio_file?.length -
                                                    1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      // onClick={() => {
                                                      //     HandleStudentPostStep(ClassDataList[0]?.id);
                                                      // }}
                                                      onClick={async () => {
                                                        if (
                                                          StudentStepList?.is_vedio !=
                                                          1
                                                        ) {
                                                          await HandleStudentMultipleVideosStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                          // HandleGetCourseList();
                                                          HandleStudentClassessData(
                                                            ClassDataList[0]?.id
                                                          );
                                                          HandleGetStudentStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                        }
                                                        HandleStudentPostStep(
                                                          ClassDataList[0]?.id
                                                        );
                                                      }}
                                                      className="nxt"
                                                    >
                                                      Next
                                                    </a>
                                                  ) : (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={async () => {
                                                        if (
                                                          StudentStepList?.is_vedio !=
                                                          1
                                                        ) {
                                                          await HandleStudentMultipleVideosStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                          // HandleGetCourseList();
                                                          HandleStudentClassessData(
                                                            ClassDataList[0]?.id
                                                          );
                                                          HandleGetStudentStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                        }
                                                        handleNextVideo();
                                                      }}
                                                      className="nxt"
                                                    >
                                                      Next
                                                    </a>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {is_current_work === "1" && (
                                      <div
                                        className={`gal-box galcurr ${
                                          isFullscreen ? "fullscreen" : ""
                                        }`}
                                      >
                                        <a
                                          href="#"
                                          role="button"
                                          title="Toggle fullscreen"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            toggleFullscreen();
                                          }}
                                          className="right_fullscreen panel-fullscreen"
                                        >
                                          <i
                                            className={`ri-${
                                              isFullscreen
                                                ? "fullscreen"
                                                : "fullscreen"
                                            }-line`}
                                          ></i>
                                        </a>
                                        <div
                                          className="gal-descrip"
                                          ref={scrollRef}
                                          style={{
                                            maxHeight: "100vh",
                                            overflowY: isAnimationComplete
                                              ? "scroll"
                                              : "auto",
                                          }}
                                        >
                                          <h5 className="border-bottom pb-3">
                                            <span className="ri-shield-user-line"></span>
                                            {ClassDataList[0]?.title}
                                          </h5>
                                          <div className="student-traning-notes-section card-instrutors screen print p-4 pt-0">
                                            <p>
                                              <div>
                                                <div>
                                                  {isAnimationComplete ||
                                                  StudentStepList?.is_notes ==
                                                    1 ? (
                                                    <>
                                                      {ReactHtmlParser(
                                                        courseNotes
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TypewriterHtmlAnimation
                                                        htmlContent={
                                                          courseNotes
                                                        }
                                                        onAnimationComplete={
                                                          handleAnimationComplete
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="gal-tabs">
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              handlePrevious();
                                              if (
                                                videoFileArray?.length == "0"
                                              ) {
                                                setShowNextYouTube(false);
                                              } else {
                                                setShowNextYouTube(true);
                                                setCurrentVideoIndex(0);
                                              }
                                            }}
                                            className="prv"
                                          >
                                            Prev
                                          </a>
                                          <span className="galcount"></span>2/3
                                          <span className="galtotal"></span>
                                          {(isAnimationComplete ||
                                            StudentStepList?.is_notes == 1) && (
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={async () => {
                                                if (
                                                  StudentStepList?.is_notes != 1
                                                ) {
                                                  await HandleStudentPostPDFStep(
                                                    ClassDataList[0]?.id
                                                  );
                                                  // HandleGetCourseList();
                                                  HandleStudentClassessData(
                                                    ClassDataList[0]?.id
                                                  );
                                                  HandleGetStudentStep(
                                                    ClassDataList[0]?.id
                                                  );
                                                } else {
                                                  HandleStudentPostStep(
                                                    ClassDataList[0]?.id
                                                  );
                                                }
                                              }}
                                              className="nxt"
                                            >
                                              Next
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {is_current_work === "2" && (
                                      <>
                                        {McqsList[0]?.id != null ? (
                                          <div className="gal-box">
                                            {showQuizSction === true &&
                                            StudentStepList?.is_mcq != 1 &&
                                            (TotalOverAllPerformance < 70 ||
                                              isNaN(
                                                TotalOverAllPerformance
                                              )) ? (
                                              <>
                                                <div className="gal-descrip overflow-scroll panel-body">
                                                  <h5 class="border-bottom pb-3">
                                                    <span class="ri-shield-user-line"></span>
                                                    Play MCQ Quiz!
                                                  </h5>
                                                  {showQuestionView ===
                                                    false && (
                                                    <div
                                                      className="card-instrutors bg-white p-3 p-md-4 rounded"
                                                      id="hide_box"
                                                    >
                                                      <p>
                                                        So, what are you waiting
                                                        for? Click the "Start
                                                        Quiz" button and get
                                                        started!
                                                      </p>
                                                      <p>
                                                        <b>Remember:</b>{" "}
                                                        Learning is a continuous
                                                        process, and every
                                                        question is an
                                                        opportunity to expand
                                                        your knowledge. Best of
                                                        luck, and may the HTML
                                                        be with you! 💡
                                                      </p>

                                                      <a
                                                        href="#"
                                                        className="btn text-white btn-primary"
                                                        onClick={
                                                          handleStartTest
                                                        }
                                                        id="start-btn"
                                                      >
                                                        Start Test
                                                      </a>
                                                    </div>
                                                  )}

                                                  {showQuestionView ===
                                                    true && (
                                                    <>
                                                      <div
                                                        className="card-instrutors bg-white p-3 p-md-4 rounded"
                                                        id="hide_box"
                                                      >
                                                        {currentQuestion ? (
                                                          <div className="quiz-card">
                                                            {
                                                              <div className="d-md-flex justify-content-between border-bottom pb-2 mb-2 align-items-center">
                                                                <div>
                                                                  {currentQustionIndex >
                                                                    0 && (
                                                                    <button
                                                                      className="btn btn-sm btn-outline-secondary py-2 m-2"
                                                                      onClick={
                                                                        handlePrevQustion
                                                                      }
                                                                    >
                                                                      Prev
                                                                    </button>
                                                                  )}
                                                                  {currentQustionIndex <
                                                                  McqsList?.length -
                                                                    1 ? (
                                                                    <button
                                                                      className="btn btn-sm btn-primary text-white py-2 m-2"
                                                                      onClick={
                                                                        handleNextQustion
                                                                      }
                                                                    >
                                                                      Next
                                                                    </button>
                                                                  ) : (
                                                                    <button
                                                                      className="btn btn-sm btn-primary text-white py-2 m-2"
                                                                      onClick={() => {
                                                                        handleStudentPostMcqs(
                                                                          ClassDataList[0]
                                                                            ?.id
                                                                        );
                                                                        handleResultSection(
                                                                          ClassDataList[0]
                                                                            ?.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      Submit
                                                                      Test
                                                                    </button>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            }

                                                            <div className="cardviewqa cls1">
                                                              {showresultSection ==
                                                                false ||
                                                              showQuestionView ? (
                                                                <>
                                                                  <h5>
                                                                    (
                                                                    {currentQustionIndex +
                                                                      1}
                                                                    ).{" "}
                                                                    {
                                                                      currentQuestion?.question
                                                                    }
                                                                  </h5>
                                                                  <div className="row">
                                                                    {Object.entries(
                                                                      options
                                                                    ).map(
                                                                      ([
                                                                        key,
                                                                        value,
                                                                      ]) => (
                                                                        <div
                                                                          key={
                                                                            key
                                                                          }
                                                                          className="col-lg-12 mb-3 mb-lg-12"
                                                                        >
                                                                          <div className="quiz_option position-relative">
                                                                            <input
                                                                              type="radio"
                                                                              id={`${currentQuestion?.id}_${key}`}
                                                                              name={`answer_${currentQuestion?.id}`}
                                                                              value={
                                                                                key
                                                                              }
                                                                              checked={
                                                                                selectedOptions[
                                                                                  currentQuestion
                                                                                    .id
                                                                                ]
                                                                                  ?.value ===
                                                                                key
                                                                              }
                                                                              onChange={() =>
                                                                                handleOptionChange(
                                                                                  currentQuestion?.id,
                                                                                  key
                                                                                )
                                                                              }
                                                                            />
                                                                            <label
                                                                              htmlFor={`${currentQuestion?.id}_${key}`}
                                                                            >
                                                                              <span className="quiz_check">
                                                                                (
                                                                                {key.toUpperCase()}
                                                                                )
                                                                              </span>
                                                                              <span>
                                                                                {
                                                                                  value
                                                                                }
                                                                              </span>
                                                                            </label>
                                                                          </div>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="col-lg-12 mb-3 mb-lg-12">
                                                                    <p>
                                                                      Best Of
                                                                      luck for
                                                                      the best
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <p>
                                                            No questions
                                                            available.
                                                          </p>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {(showresultSection == true ||
                                                  StudentStepList?.is_mcq ==
                                                    1 ||
                                                  ClassList?.coursefiles[0]
                                                    ?.isClassComplete == true ||
                                                  TotalOverAllPerformance >
                                                    70) && (
                                                  <>
                                                    <div className="card-instrutors bg-white p-3 p-md-4 rounded">
                                                      <div className="training-crs-details p-3 p-lg-4 mb-3 mb-lg-4">
                                                        <div>
                                                          <h5 className="label-color-2">
                                                            Online Test: Result{" "}
                                                          </h5>
                                                          <ul className="list-unstyled my-3">
                                                            {TotalOverAllPerformance <
                                                              70 && (
                                                              <li className="d-inline-block fs-15 label-color-1 fw-500 me-3 mb-2">
                                                                <a
                                                                  href="#"
                                                                  className="btn text-white btn-primary"
                                                                  onClick={() => {
                                                                    handleRetakeTest();
                                                                    // HandleGetCourseList()
                                                                    HandleStudentClassessData(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                    HandleGetStudentStep(
                                                                      ClassDataList[0]
                                                                        ?.id
                                                                    );
                                                                  }}
                                                                  id="start-btn"
                                                                >
                                                                  ReTake
                                                                </a>
                                                              </li>
                                                            )}
                                                          </ul>
                                                        </div>
                                                        <div className="text-center">
                                                          <h6 className="label-color-2 mb-3">
                                                            Your Result
                                                          </h6>
                                                          <img
                                                            src={
                                                              CommonImageTransport?.award
                                                            }
                                                            className="img-fluid my-3"
                                                            alt="Result"
                                                          />
                                                          <ul className="list-unstyled d-flex justify-content-center qz-ans-count">
                                                            <li className="label-color-1 px-3 px-lg-4 py-1 d-flex align-items-center flex-column numbers-border-right">
                                                              Total Questions{" "}
                                                              <p className="label-color-2 fw-600 mt-1 mb-0">
                                                                {
                                                                  McqsList?.length
                                                                }
                                                              </p>
                                                            </li>
                                                            <li className="label-color-1 px-3 px-lg-4 py-1 d-flex align-items-center flex-column numbers-border-right">
                                                              Attempt Questions{" "}
                                                              <p className="label-color-2 fw-600 mt-1 mb-0">
                                                                {
                                                                  GetMcqsResultList?.total_answer
                                                                }
                                                              </p>
                                                            </li>
                                                            <li className="label-color-1 px-3 px-lg-4 py-1 d-flex align-items-center flex-column numbers-border-right">
                                                              Right Answered{" "}
                                                              <p className="label-color-2 mt-1 mb-0">
                                                                {
                                                                  GetMcqsResultList?.right_answer
                                                                }
                                                              </p>
                                                            </li>
                                                            <li className="label-color-1 px-3 px-lg-4 py-1 d-flex align-items-center flex-column">
                                                              Wrong Answered
                                                              <p className="label-color-2 mt-1 mb-0">
                                                                {
                                                                  GetMcqsResultList?.wrong_answer
                                                                }
                                                              </p>
                                                            </li>
                                                            <li className="label-color-1 px-3 px-lg-4 py-1 d-flex align-items-center flex-column">
                                                              OverAll
                                                              Performance
                                                              <p className="label-color-2 mt-1 mb-0">
                                                                {TotalOverAllPerformance.toFixed(
                                                                  2
                                                                )}{" "}
                                                                %
                                                              </p>
                                                              {TotalOverAllPerformance <
                                                              70 ? (
                                                                <div className="text-center">
                                                                  <p className="text-danger">
                                                                    You are fail
                                                                    in this Quiz
                                                                  </p>
                                                                </div>
                                                              ) : (
                                                                <div className="text-center">
                                                                  <p className="text-success">
                                                                    You are Pass
                                                                    in this Quiz
                                                                  </p>
                                                                </div>
                                                              )}
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}

                                            <div className="gal-tabs">
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={handlePrevious}
                                                className="prv"
                                              >
                                                Prev
                                              </a>
                                              <span className="galcount"></span>
                                              3/3
                                              <span className="galtotal"></span>
                                              {TotalOverAllPerformance > 69 &&
                                                StudentStepList?.is_mcq !=
                                                  1 && (
                                                  <>
                                                    {ClassDataList[0]?.id ===
                                                    ClassList?.coursefiles[
                                                      ClassList?.coursefiles
                                                        ?.length - 1
                                                    ]?.id ? (
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          HandleStudentCourseComplete(
                                                            ClassDataList[0]?.id
                                                          );
                                                        }}
                                                        className="nxt"
                                                      >
                                                        Complete Course
                                                      </a>
                                                    ) : (
                                                      <a
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          HandleStudentPostMCQStep(
                                                            ClassDataList[0]?.id
                                                          );
                                                        }}
                                                        className="nxt"
                                                      >
                                                        Continue
                                                      </a>
                                                    )}
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="text-center mt-5">
                                            <h3>Not Any Mcqs Available Here</h3>
                                            <div className="gal-tabs">
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={handlePrevious}
                                                className="prv"
                                              >
                                                Prev
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="text-center">
                <h4>Not Any Classes Available Here</h4>
              </div>
            )}
          </div>
        </div>
      </div>

      <Student_Footer />
    </div>
  );
};

export default Student_Coaching_Session_Complete;
